import React, { Component } from 'react'; 
import {Link} from 'react-router-dom';

import {connect} from 'react-redux'

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment'; 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText'; 
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";   

import MomentUtils from "@date-io/moment";
import moment from "moment";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';

import Autocomplete from '@material-ui/lab/Autocomplete';

import AttachedCompanies  from "./AttachedCompanies";
import AttachedClients  from "./AttachedClients";
 

import {fetchSingleContact, editContact, createContact, fetchContactAutoComplete} from '../../../_actions/contactsActions'
import {fetchSingleClient} from '../../../_actions/clientsActions'


class EditContact extends Component {
    state={
        data:{
            id:'',
            egn:'',
            name:'',
            address:'',
            email:'',
            phone:'',
            idCardNumber:'',
            idCardDate:null,
            idCardPublishedBy:'',
            drivingLicense:'',
            drivingLicenseDate:'',
            createdBy:localStorage.getItem('name'), 
            fromClient:'',
            title:'',
            model:'',
            chanel:[],
            useContactCompany:0,
            contacts:[{'relatedContactId':'','relationType':'','relatedContact':''}] 
        },
        drivingLicenseType:'',
        editMode:false,  
        errors:[],
        refresh:false,
        roleId: JSON.parse(localStorage.getItem('roleId')),
    }

    componentDidMount(){  
        
        const query = new URLSearchParams(this.props.location.search);
        const client_Id = query.get('client_Id')
        if (client_Id) { 
            this.setState ({
                data:{
                    ...this.state.data,
                    fromClient:client_Id
                }
            }) 
            this.props.fetchSingleClient(client_Id);   
        }
         
        if (this.props.match.params._id) {
            this.setState({editMode:true})
            this.props.fetchSingleContact(this.props.match.params._id);
        }

        this.unlisten = this.props.history.listen((location, action) => { 
            const query = new URLSearchParams(location.search);
            const refresh = query.get('refresh');
            if(refresh){  
                // this.props.fetchSingleClient(this.props.match.params._id);   
                this.setState({editMode:true}) 
            }  
        });
    }
    componentWillUnmount() {
        this.unlisten();
    }

    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.contact !== this.props.contact){
            const {id,useContactCompany,name,address,email,phone,egn,clientCompany,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseType,drivingLicenseDate,createdBy,contacts, title, contactCompany, model, chanel} = this.props.contact
            if (contacts.length<1) {
                this.setState ({
                    data:{
                        ...this.state.data, 
                        id,useContactCompany,name,address,email,phone,egn,clientCompany,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseType,drivingLicenseDate,createdBy,title,contactCompany,model, 
                        chanel:chanel.length>0 ?chanel:[],
                        contacts:[{'relatedContactId':'','relationType':'','relatedContact':''}]
                    } 
                })    
            }else{
                this.setState ({
                    data:{
                        ...this.state.data, 
                        title,
                        id,useContactCompany,name,email,phone,egn,clientCompany,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseType,drivingLicenseDate,createdBy,contacts,contactCompany, model,   
                        chanel:chanel.length>0 ?chanel:[],
                    } 
                })   
            }  
        }
        if(previousProps.client !== this.props.client){
            const {name, companyName} = this.props.client
            if (companyName) {
                this.setState ({clientName:companyName})   
            }
            if (name) {
                this.setState ({clientName:name})   
            } 
             
        }
    } 

    onChange =(event)=>{
        const {name, value} = event.target;
        this.setState({ 
            data:{
                ...this.state.data,
                [name]:value  
            }
        })
    }
    
    createEntry =(id)=>{
        this.props.history.push("/dashboard/create-client/?contactId="+id);
    }

    createOffer =(id)=>{
        this.props.history.push("/dashboard/create-offer/?contactId="+id);
    }

    handleRemove = (i) => {
        const list = [...this.state.data.contacts];
        list.splice(i, 1);
        this.setState({
            data:{
                ...this.state.data,
                contacts:list
            } 
        })
    }

    onChangeDinamic = (e, index) => { 
        const { name, value } = e.target; 
        const list = [...this.state.data.contacts];
        list[index][name] = value; 
        this.setState({
            data:{
                ...this.state.data,
                contacts:list
            } 
        }) 
    };
    
    onChangeDinamicRelatedId = (index, event, value, reason) =>{     
        if(value){  
            const list = [...this.state.data.contacts];
            list[index].relatedContactId = value.id
            this.setState({
                data:{
                    ...this.state.data,
                    contacts:list
                } 
            })  
        } 
    }
   
    onChangeDinamicRelated =(index, event, value, reason) =>{    
        if (value.length>3) { 
            this.props.fetchContactAutoComplete(value);
        }

        if(event || value){ 
            const list = [...this.state.data.contacts];
            list[index].relatedContact = value; 
            this.setState({
                data:{
                    ...this.state.data,
                    contacts:list
                } 
            })  
        } 
    }
 

    onChangeDinamicRelatedId = (index, event, value, reason) =>{     
        if(value){  
            const list = [...this.state.data.contacts];
            list[index].relatedContactId = value.id
            this.setState({
                data:{
                    ...this.state.data,
                    contacts:list
                } 
            })  
        } 
    }
 

    handleAdd = ()=>{
        this.setState({ 
            data:{
                ...this.state.data,
                contacts:[
                    ...this.state.data.contacts, 
                    {'relatedContactId':'','relationType':'','relatedContact':''}
                ] 
            }
           
        })
    }

    onChangeDate = (input, date, value) => {   
        this.setState({
            data:{
                ...this.state.data, 
                [input]:value 
            }  
        })  
    }

    createTestDrive =(id)=>{
        this.props.history.push("/dashboard/create-testDrive?contactId="+id);
    }

    handleCheckbox = (event) =>{
        const{name, checked } = event.target;  
        this.setState({ 
            data:{
                ...this.state.data,
                [name]:checked?1:0 
            }
        }) 
    }
    
    onSubmit = (event) =>{
        event.preventDefault();
        const {editMode} = this.state;
        if (editMode) {
            this.props.editContact(this.state.data).then(data => {
            
                
                if(data.status==="success"){
                    this.setState({errors:[]}) 
                    this.props.history.push('/dashboard/contacts')
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }   
            }) 
        }else{
            this.props.createContact(this.state.data).then(data => { 
                if(data.status==="success"){ 
                    this.setState({ 
                        errors:[]
                    })
               
                    const {model,chanel,id,useContactCompany,name,address,email,phone,egn,clientCompany,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseType,drivingLicenseDate,createdBy,contacts, title} = data.data
                    this.props.history.push("/dashboard/contacts/"+id+"/?refresh=true");
                    if (contacts.length<1) {
                        this.setState ({
                            data:{
                                ...this.state.data,
                                model,
                                chanel:chanel.length>0 ?chanel:[],
                                id,useContactCompany,name,address,email,phone,egn,clientCompany,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseType,drivingLicenseDate,createdBy,title,contacts:[{'relatedContactId':'','relationType':'','relatedContact':''}]
                            } 
                        })    
                    }else{
                        this.setState ({
                            data:{
                                ...this.state.data,title,
                                model,
                                chanel:chanel.length>0 ?chanel:[],
                                id,useContactCompany,name,address,email,phone,egn,clientCompany,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseType,drivingLicenseDate,createdBy,contacts
                            } 
                        })   
                    }  
                    if(this.state.clientName){
                        this.props.history.push('/dashboard/contacts')
                    } 
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }  
            }) 
        }
    }
 
    handleChangeChanels = (event)=>{
        this.setState({
            data:{
                ...this.state.data,
                chanel:event.target.value
            }
        })
    }
    render() { 
        const chanelList = [
            {id:1, name:'Социални мрежи'},
            {id:2, name:'Google'},
            {id:3, name:'Радио'},
            {id:4, name:'Print'},
            {id:5, name:'Бордове'},
            {id:6, name:'От приятел'}
        ]

        const {errors, editMode, clientName, roleId}= this.state;
        const {title, model, chanel, fromClient, id, egn, name, address, email, phone, createdBy, contactCompany, idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseDate, contacts, useContactCompany}= this.state.data; 
        const {contactsAutoComplete}=this.props; 
         
        const pageTitle = editMode ? 'Редактиране на кoнтакт' : 'Създаване на кoнтакт'; 
        return (  
            <>  
                <Card >
                    <Grid container spacing={3} className="section-title-wrapper padding"> 
                        <Grid container item md={3} xs={8} alignItems="center" className="m-order-1 mb-20-m"> 
                            <h3 className="section-title">
                                <i className="flaticon2-gear"></i> {pageTitle}
                                {fromClient.length>0 ? 
                                    <span> за клиент "{clientName}"</span>
                                :':'}
                            </h3>
                        </Grid>  
                        <Grid container item md={3} xs={12} className="m-order-3">    
                            {(roleId!==19&&editMode)&&<div className="btn btn-focus full-width " onClick={()=>{this.createOffer(id)}}>
                                <i className="flaticon2-add-square"></i>Създай оферта за този контакт
                            </div>}    
                        </Grid>   
                        <Grid container item md={3} xs={12} className="m-order-3">    
                            {(roleId!==19&&editMode)&&<div className="btn btn-focus full-width" onClick={()=>{this.createTestDrive(this.props.match.params._id)}}>
                                <i className="flaticon2-add-square"></i>Създай тестдрайв
                            </div>} 
                        </Grid>   
                        <Grid container item md={3} xs={4} alignItems="center" justify="flex-end" className="m-order-2 mb-20-m">  
                            <Link  
                                className="btn btn-secondary"
                                to="/dashboard/contacts" 
                                ><i className="flaticon2-circular-arrow"/>
                                <span>Назад</span> 
                            </Link>   
                        </Grid>
                    </Grid>  
                    <form noValidate onSubmit={this.onSubmit} className="form padding">
                        <Grid container spacing={3}> 
                            <Grid item md={4} xs={12}>  
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-user"></i>  
                                            </InputAdornment>
                                        ), 
                                        type:"password",
                                        autoComplete: 'new-password'   
                                    }} 
                                    
                                    label="ЕГН:"
                                    name="egn"  
                                    value={egn}
                                    error ={errors.egn?true:false} 
                                    helperText={errors.egn}
                                    onChange={this.onChange}  
                                />   
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    margin="normal" 
                                    required 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-user"></i>  
                                            </InputAdornment>
                                        )
                                    }} 
                                    label="Име:"
                                    name="name"
                                    value={name}
                                    error ={errors.name?true:false} 
                                    helperText={errors.name}
                                    onChange={this.onChange}  
                                />   
                                
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-phone"></i>  
                                            </InputAdornment>
                                        ),
                                    }} 
                                    required 
                                    label="Телефон:"
                                    name="phone"   
                                    value={phone}
                                    error ={errors.phone?true:false} 
                                    helperText={errors.phone}
                                    onChange={this.onChange} 
                                />  
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-envelope"></i>  
                                            </InputAdornment>
                                        ), 
                                    }}
                                    
                                    label="E-mail:"
                                    name="email" 
                                    value={email}
                                    error ={errors.email?true:false} 
                                    helperText={errors.email}
                                    onChange={this.onChange} 
                                />  
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}}  
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fas fa-home"></i> 
                                            </InputAdornment>
                                        ), 
                                    }}
                                    
                                    label="Адрес:"
                                    name="address" 
                                    value={address}
                                    error ={errors.address?true:false} 
                                    helperText={errors.address}
                                    onChange={this.onChange} 
                                />  
                            </Grid> 
                            <Grid item md={4} xs={12}>  
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-browser"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="ЛК/номер:"
                                    name="idCardNumber" 
                                    value={idCardNumber}
                                    error ={errors.idCardNumber?true:false} 
                                    helperText={errors.idCardNumber}
                                    onChange={this.onChange} 
                                />   
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}}
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-browser"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Издадена от:"
                                    name="idCardPublishedBy" 
                                    value={idCardPublishedBy}
                                    error ={errors.idCardPublishedBy?true:false}  
                                    helperText={errors.idCardPublishedBy} 
                                    onChange={this.onChange} 
                                /> 
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                                    <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                                        autoOk={true}
                                        showTodayButton={true} 
                                        InputLabelProps={{shrink: true}} 
                                        className="full-width date mb-20"  
                                        inputVariant="outlined" 
                                        margin="normal"
                                        label="ЛК/дата на издаване:" 
                                        format="DD.MM.YYYY"
                                        value=""
                                        inputValue={idCardDate} 
                                        onChange={(e,d)=>this.onChangeDate('idCardDate', e ,d)}
                                        rifmFormatter={this.dateFormatter} 
                                        error ={errors.idCardDate?true:false}  
                                        helperText={errors.idCardDate} 
                                    />
                                </MuiPickersUtilsProvider>
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}}
                                    margin="normal" 
                                    disabled
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-user"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Търговец"
                                    name="createdBy"
                                    value={createdBy}
                                    error={errors.createdBy?true:false} 
                                    helperText={errors.createdBy} 
                                    onChange={this.onChange}  
                                /> 
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}}
                                    margin="normal"  
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fas fa-car"></i> 
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Модел"
                                    name="model"
                                    value={model}
                                    error={errors.model?true:false} 
                                    helperText={errors.model} 
                                    onChange={this.onChange}  
                                /> 
                            </Grid>         
                            <Grid item md={4} xs={12}>  
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20"
                                    InputLabelProps={{shrink: true}}
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-browser"></i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Шофьорска книжка номер"
                                    name="drivingLicense"
                                    value={drivingLicense}
                                    error ={errors.drivingLicense?true:false}
                                    helperText={errors.drivingLicense}
                                    onChange={this.onChange}
                                />
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                                    <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                                        autoOk={true}
                                        showTodayButton={true} 
                                        InputLabelProps={{shrink: true}} 
                                        className="full-width date mb-20"  
                                        inputVariant="outlined" 
                                        margin="normal"
                                        label="Книжка/дата на издаване:" 
                                        format="DD.MM.YYYY"
                                        value=""
                                        inputValue={drivingLicenseDate} 
                                        onChange={(e,d)=>this.onChangeDate('drivingLicenseDate', e ,d)}
                                        rifmFormatter={this.dateFormatter} 
                                        error ={errors.drivingLicenseDate?true:false}  
                                        helperText={errors.drivingLicenseDate} 
                                    />
                                </MuiPickersUtilsProvider>
                                <FormControl variant="outlined" error={errors.title?true:false} margin="normal" className='mb-20 full-width select-styles'>
                                    <InputLabel id="title">
                                        Oбръщение *
                                    </InputLabel>
                                    <Select  
                                        MenuProps={{
                                            anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "right"
                                            },
                                            transformOrigin: {
                                            vertical: "top",
                                            horizontal: "right"
                                            },
                                            getContentAnchorEl: null
                                        }} 
                                        labelId="title"
                                        required={true}  
                                        inputProps={{
                                            name: 'title',   
                                        }}  
                                        value={title} 
                                        onChange={this.onChange}
                                        labelWidth={100}  >    
                                        <MenuItem value='г-н'>г-н</MenuItem>  
                                        <MenuItem value='г-жо'>г-жо</MenuItem>   
                                    </Select>
                                    {errors.title&&<FormHelperText>{errors.title}</FormHelperText>}
                                </FormControl>
                            
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fas fa-city"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Представител на Компания:"
                                    name="contactCompany" 
                                    value={contactCompany}
                                    error ={errors.contactCompany?true:false} 
                                    helperText={errors.contactCompany}
                                    onChange={this.onChange} 
                                />   
                                <FormControlLabel control={
                                <Checkbox
                                        checked={useContactCompany}
                                        onChange={this.handleCheckbox}
                                        name="useContactCompany"
                                        color="primary"
                                    />
                                    }
                                    label='Използвай "Представител на Компания" при издаване на Оферта.'
                                />  
                            </Grid> 
                        </Grid> 
                        <Grid container spacing={2}>   
                            <Grid item md={8} xs={12} >  
                                <h4>Канали:</h4>
                                <Select
                                    className='full-width'
                                    labelId="demo-mutiple-chip-label"
                                    id="demo-mutiple-chip"
                                    multiple
                                    value={chanel}
                                    onChange={this.handleChangeChanels}
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue={(selected) => (

                                        <div className="chips">
                                        {selected.map((item) =>{   
                                            return  ( 
                                                chanelList.length>0&&<Chip key={item} label={chanelList.find(x => x.id == item).name} className="chip"/>
                                    
                                            )})}
                                        </div>
                                    )}
                                    >
                                    {chanelList.map((item) => {
                                        return (
                                            <MenuItem key={item.id} value={item.id} className="chip-options">
                                                {item.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid> 
                        </Grid> 
                        <Grid container spacing={2}>   
                            <Grid item md={4} xs={12} >  
                            {contacts.length===0&&<button style={{'margin':'25px 0 30px'}} onClick={this.handleAdd} type="button" className='btn btn-primary full-width'>
                                Добави свързан контакт
                            </button>} 
                            </Grid> 
                        </Grid> 
                        {contacts.map((item, i) =>{
                        return(  
                            <Grid container spacing={2} key={i}>   
                                <Grid item md={4} xs={12} >  
                                    <Autocomplete 
                                        options={contactsAutoComplete} 
                                        inputValue={item.relatedContact} 
                                        onChange={(event, value, reason)=>this.onChangeDinamicRelatedId(i,event, value, reason)} 
                                        onInputChange={(event, value, reason)=>this.onChangeDinamicRelated(i,event, value, reason)}
                                        getOptionLabel={option => option.name}
                                        renderInput={params => (
                                            <TextField {...params}
                                            className="full-width mb-20"
                                            InputLabelProps={{shrink: true}} 
                                            margin="normal"
                                            variant="outlined"
                                            label="Свързан със:"
                                        
                                            />
                                        )} 
                                    />  
                                </Grid> 
                                <Grid item md={4} xs={12} >  
                                    <FormControl variant="outlined" error={errors.relationType?true:false} margin="normal" className='mb-20 full-width select-styles'>
                                        <InputLabel id="relationType" shrink>
                                            Вид взаймоотношения: 
                                        </InputLabel>
                                        <Select  
                                            MenuProps={{
                                                anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "right"
                                                },
                                                transformOrigin: {
                                                vertical: "top",
                                                horizontal: "right"
                                                },
                                                getContentAnchorEl: null
                                            }} 
                                            labelId="relationType"
                                            notched
                                            inputProps={{
                                                name: 'relationType', 
                                            }} 
                                            
                                            value={item.relationType} 
                                            onChange={e=>this.onChangeDinamic(e, i)}
                                            labelWidth={180}  >    
                                            <MenuItem value='1'>Съпруг/Съпруга</MenuItem>  
                                            <MenuItem value='2'>Брат/Сестра</MenuItem>  
                                            <MenuItem value='3'>Роднина</MenuItem>  
                                            <MenuItem value='4'>Колега</MenuItem>  
                                            <MenuItem value='5'>Приятел</MenuItem>  
                                        </Select>
                                    </FormControl>  
                                </Grid> 
                                <Grid item md={4} xs={12} >   
                                            <br/>
                                    <div className="btn-box">
                                        {/* {contacts.length !== 1 && */}
                                        <div className='delete' onClick={()=>this.handleRemove(i)}>
                                            <i className="flaticon2-delete"></i>  
                                        </div>
                                        {/* } */}
                                        {contacts.length - 1 === i && 
                                        <div className='add' onClick={this.handleAdd}>
                                            +
                                        </div>}
                                    </div>
                                </Grid> 
                            </Grid> 
                        )})}
                        <Grid container spacing={2} >   
                            <Grid container item md={4} xs={12} >    
                                {roleId!==19&&<button type="submit" className="btn btn-info full-width">
                                    <i className="flaticon2-add-square"></i>{editMode ? 'Запази' : 'Създай'}
                                </button>}   
                            </Grid> 
                            <Grid container item md={4} xs={12} >    
                            </Grid> 
                            <Grid container item md={4} xs={12} >    
                                {(roleId!==19&&editMode)&&<div className="btn btn-primary full-width" onClick={()=>{this.createEntry(id)}}>
                                    <i className="flaticon2-add-square"></i>Създай клиент за този контакт
                                </div>}  
                            </Grid> 
                                
                        </Grid> 
                    </form> 
                    <br/>
                    <br/>
                    {editMode&&<Grid container spacing={3} className="padding"> 
                        <Grid item md={6} xs={12}>  
                            <AttachedCompanies id={this.props.match.params._id}/>
                        </Grid>  
                        <Grid item md={6} xs={12}>  
                            <AttachedClients id={this.props.match.params._id}/>
                        </Grid> 
                    </Grid>} 
                </Card> 
                
            </>
         );
    }
}
 
const mapStateToProps = (state, props) => {  
        return{ 
            contact:state.contacts.singleContact, 
            contactsAutoComplete:state.contacts.contactsAutoComplete, 
            client:state.clients.singleClient,
        } 
 }
export default connect(mapStateToProps, {fetchSingleContact, editContact, createContact, fetchContactAutoComplete,fetchSingleClient })(EditContact);
