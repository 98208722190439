import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { withRouter } from "react-router-dom";
import {connect} from 'react-redux'

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";  

import {fetchLizingCompanies} from '../../../_actions/clientsActions'

class LizingCompanies extends Component {
    state = {  }

    componentDidMount(){    
        if (this.props.match.params._id) {
            this.props.fetchLizingCompanies(this.props.match.params._id);
        }
    }

    openClientEntry=(id)=>{
        this.props.history.push("/dashboard/clients/"+id);
    }

    

    render() { 
        const{lizingCompanies}=this.props; 
        return (  
            <> 
                <Grid container className="section-title-wrapper padding"> 
                    <Grid container item xs={8} alignItems="center"> 
                        <h3 className="section-title">
                            Лизингови компании:
                        </h3>
                    </Grid>  
                </Grid>  
                <Grid container spacing={3}> 
                    <Grid item md={12} xs={12}>  
                            <table className="regular-table">
                                <thead>                         
                                <tr>
                                    <th>id</th>
                                    <th>Име на Фирмата:</th>
                                    <th>ЕИК:</th> 
                                    <th>МОЛ:</th> 
                                    <th>EГН:</th> 
                                </tr>
                            </thead>   
                            <tbody>
                                {lizingCompanies.map( item =>{ 
                                        return(
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>{item.companyName}</td> 
                                                <td>{item.companyEik}</td> 
                                                <td>{item.companyMol}</td> 
                                                <td>{item.egn}</td>  
                                            </tr> 
                                        ) 
                                      
                                    }
                                )} 
                                {lizingCompanies.length <1&& 
                                    <tr>
                                        <td colspan="10"><h3>Няма резултати</h3></td> 
                                    </tr>  
                                }
                                
                            </tbody> 
                        </table>       
                    </Grid>    
                </Grid>    
            </>
        );
    }
}
const mapStateToProps = (state, props) => {   
    return{ 
       lizingCompanies:state.clients.lizingCompanies,  
    } 
}
export default withRouter(connect(mapStateToProps, {fetchLizingCompanies })(LizingCompanies)); 
 
 