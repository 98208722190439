import React, { Component } from 'react';
 
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  
import {columns, options} from "../../helpers/viewHistory";   
import {storeColumnsFilters, getStoredColumnsFilters} from "../../helpers/storeColumns"; 

import {fetchHistory} from '../../_actions/historyActions'


class ViewHistory extends Component {
    state={
        options:{},
        columns:[]
     }

    componentDidMount (){
        this.setState({
            options: storeColumnsFilters('historyTable', options),
            columns: getStoredColumnsFilters('historyTable', columns)
        }) 
        this.props.fetchHistory()
    }  

    onRowClick=(rowData , rowMeta, e) => {  
        this.props.history.push("/dashboard/drive-list/"+rowData[0]);
    }
    render() {  
        const {logData} = this.props; 
        const {options, columns} = this.state; 
        return (  
            <React.Fragment> 
                <div className="mb-20"> 
                </div>
                   
                <Card className='static scroll-x'>
                    <MUIDataTable
                        title={"Лог на дейностите:"}
                        className='viewHistoryTable'
                        data={logData}
                        columns={columns} 
                        options={{...options, onRowClick: this.onRowClick}}  
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
 
const mapStateToProps = state => {  
   return({logData:state.history.items})
}

export default withRouter(connect(mapStateToProps, { fetchHistory })(ViewHistory));
// export default ViewUsers;