import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";   
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';  
import {permissions} from '../../../../helpers/permissions'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'; 

import {createRole, editRole, fetchPermitionsList, fetchRolePermitions} from '../../../../_actions/rolesActions'

class EditRole extends Component {
    state = { 
        name:"",
        id:'', 
        permissions:permissions,
        editMode:false,  
        errors:[]
    }

    componentDidMount(){   
        this.setState({
            permissions:permissions
        },()=>{
            this.callPermissions();
        }) 
    }

    callPermissions = () =>{
        this.props.fetchPermitionsList().then(data => { 
            if(data.status==="success"){ 
                const perms = data.data.reduce(
                    (obj, item) => Object.assign(obj, {[item.name]:{ value: false, id:item.id }}), 
                {});
                this.setState({
                    permissions:perms
                },()=>{
                    this.callUserPermissions()
                })   
            }
        }) 
    }

    callUserPermissions = ()=>{
        if (this.props.match.params._id) {
            this.setState({editMode:true}) 
            this.props.fetchRolePermitions(this.props.match.params._id).then(data => {
                if(data.status==="success"){
                    const defPerms = this.state.permissions; 
                    const perms = data.data.permissions.reduce(
                        (obj, item) => Object.assign(obj, {[item.name]:{ value: true, id:item.id }}), 
                    {});
                 
                    this.setState({ 
                        id:data.data.id,
                        name:data.data.name, 
                        permissions:{
                            ...defPerms,
                            ...perms
                        }
                    })  
                }
            })
        } 
    }
    onChangePermitions = (event)=>{
        const {name,checked} = event.target;  
        this.setState({ 
            permissions:{
                ...this.state.permissions,
                [name]:{
                    ...this.state.permissions[name],
                    value:checked
                }
            }
        }) 
    }
    
    onChange =(event)=>{
        const {name, value} = event.target;
        this.setState({  
            [name]:value    
        })
    }
 

    onSubmit = (event) =>{
        event.preventDefault();
        const {editMode, permissions, name, id} = this.state;

        let moderated = Object.keys(permissions).map(i => { 
            return {name:i, id:permissions[i].id, value:permissions[i].value }
         })
         moderated = moderated.filter(item => item.value == true)
    
         moderated = moderated.map(item =>{
             return {name:item.name, id:item.id}
         })
    
        const obj= {
            name,
            permissions:moderated

        }
        if (editMode) {
            this.props.editRole(obj,id).then(data => {  
                if(data.status==="success"){
                    this.setState({errors:[]}) 
                    this.props.history.push('/dashboard/roles')
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }   
            }) 
        }else{
            this.props.createRole(obj).then(data => { 
                if(data.status==="success"){
                    this.setState({errors:[]}) 
                    this.props.history.push('/dashboard/roles')
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                } 
            }) 
        }
    }
    render() { 

        const {editMode, errors }=this.state; 
        const {permissions,name} = this.state;  
    
        const pageTitle = editMode ? 'Редактиране на Роля' : 'Създаване на роля';
        return ( 
            <>
             <Card>
                <Grid container className="section-title-wrapper padding"> 
                    <Grid container item md={4} xs={8} alignItems="center" className="m-order-1"> 
                        <h3 className="section-title">
                            <i className="flaticon2-gear"></i> {pageTitle} 
                        </h3>
                    </Grid>   
                    <Grid container item md={4} xs={12} className="m-order-3">    
                    </Grid>
                    <Grid container item md={4} xs={4} alignItems="center" justify="flex-end" className="m-order-2">  
                        <Link  
                            className="btn btn-secondary"
                            to="/dashboard/roles" 
                            ><i className="flaticon2-circular-arrow"/>
                            <span>Назад</span> 
                        </Link>   
                    </Grid>
                </Grid>  
               <form  className='form' noValidate onSubmit={this.onSubmit} className="padding">
                    <Grid container spacing={2} >   
                        <Grid container item md={4} xs={12} >  
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                InputLabelProps={{shrink: true}} 
                                required 
                                margin="normal" 
                                variant="outlined"  
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                                <i className="flaticon2-user"></i>  
                                    </InputAdornment>
                                    ),
                                }}
                                label="Име на ролята"
                                name="name"
                                value={name} 
                                error={errors.name?true:false}  
                                helperText={errors.name} 
                                onChange={this.onChange}  
                            />   
                        </Grid> 
                    </Grid> 
                    <Grid container spacing={2} >   
                        <Grid container item md={12} xs={12} className="permitions-table-wrapper">  
                            <table className="permitions-table full-width">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Преглед списък:</th>
                                        <th>Създаване:</th>
                                        <th>Редактиране:</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    <tr>
                                        <td> тестдрайв</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='test-drives-show'
                                                checked={permissions['test-drives-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='test-drives-store'
                                                checked={permissions['test-drives-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='test-drives-update'
                                                checked={permissions['test-drives-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> тестови автомобили</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='cars-show'
                                                checked={permissions['cars-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='cars-store'
                                                checked={permissions['cars-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='cars-update'
                                                checked={permissions['cars-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td>автомобили за продажба</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='sales-cars-show'
                                                checked={permissions['sales-cars-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='sales-cars-store'
                                                checked={permissions['sales-cars-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='sales-cars-update'
                                                checked={permissions['sales-cars-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> клиенти</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='clients-show'
                                                checked={permissions['clients-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='clients-store'
                                                checked={permissions['clients-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='clients-update'
                                                checked={permissions['clients-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> контакти</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='contacts-show'
                                                checked={permissions['contacts-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='contacts-store'
                                                checked={permissions['contacts-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='contacts-update'
                                                checked={permissions['contacts-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td>потребители</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='users-show'
                                                checked={permissions['users-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='users-store'
                                                checked={permissions['users-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='users-update'
                                                checked={permissions['users-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> компании</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='companies-show'
                                                checked={permissions['companies-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='companies-store'
                                                checked={permissions['companies-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='companies-update'
                                                checked={permissions['companies-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> Марки</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='brands-show'
                                                checked={permissions['brands-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='brands-store'
                                                checked={permissions['brands-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='brands-update'
                                                checked={permissions['brands-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> Модели</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='models-show'
                                                checked={permissions['models-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='models-store'
                                                checked={permissions['models-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='models-update'
                                                checked={permissions['models-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> Оферти:</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='offers-show'
                                                checked={permissions['offers-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='offers-store'
                                                checked={permissions['offers-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='offers-update'
                                                checked={permissions['offers-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> Поръчки:</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='orders-show'
                                                checked={permissions['orders-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='orders-store'
                                                checked={permissions['orders-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='orders-update'
                                                checked={permissions['orders-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> Договори:</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='contracts-show'
                                                checked={permissions['contracts-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='contracts-store'
                                                checked={permissions['contracts-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='contracts-update'
                                                checked={permissions['contracts-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> ППП :</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='acceptance-act-show'
                                                checked={permissions['acceptance-act-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='acceptance-act-store'
                                                checked={permissions['acceptance-act-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='acceptance-act-update'
                                                checked={permissions['acceptance-act-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> Роли:</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='roles-show'
                                                checked={permissions['roles-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='roles-store'
                                                checked={permissions['roles-store'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='roles-update'
                                                checked={permissions['roles-update'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> Синхронизиране на автомобили:</td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='sales-cars-sync'
                                                checked={permissions['sales-cars-sync'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> Импорт на автомобили:</td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='sales-cars-import'
                                                checked={permissions['sales-cars-import'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    {/* <tr>
                                        <td> Импорт на автомобили Nissan:</td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='sales-cars-nisan-import'
                                                checked={permissions['sales-cars-nisan-import'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr>  */}
                                    <tr>
                                        <td> Смяна на статус на автомобили:</td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='sales-cars-update-status'
                                                checked={permissions['sales-cars-update-status'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td> документи на Автомобили за продажба:</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='sales-cars-documents-show'
                                                checked={permissions['sales-cars-documents-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />   
                                        </td>
                                        <td></td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='sales-cars-documents-upload'
                                                checked={permissions['sales-cars-documents-upload'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />    
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td> История:</td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='log-activity-show'
                                                checked={permissions['log-activity-show'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />   
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>  
                                    <tr>
                                        <td> Oдобряване на оферта Мениджър Продажби:</td> 
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='sales-manager-allow-offer'
                                                checked={permissions['sales-manager-allow-offer'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />   
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td> Oдобряване на оферта Мениджър:</td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='manager-allow-offer'
                                                checked={permissions['manager-allow-offer'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />   
                                        </td> 
                                    </tr>  
                                    <tr>
                                        <td> Oдобряване на оферта като Дилър Мениджър:</td> 
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='dealer-manager-allow-offer'
                                                checked={permissions['dealer-manager-allow-offer'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />   
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td>Oдобряване на оферта като Админ:</td> 
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <FormControlLabel control={ 
                                                <Checkbox name='admin-allow-offer'
                                                checked={permissions['admin-allow-offer'].value}
                                                onChange={this.onChangePermitions} color="primary"/>}
                                            />   
                                        </td>
                                    </tr>  
                                </tbody>
                            </table> 
                        
                        </Grid>  
                    </Grid>  
                    <Grid container spacing={2} >   
                        <Grid container item md={4} xs={12} >  
                        
                            <button type="submit" className="btn btn-info full-width">
                                <i className="flaticon2-add-square"></i>{editMode ? 'Запази' : 'Създай'}
                            </button>   
                        </Grid>  
                    </Grid> 
                </form>
                   
            </Card> 
          
            </>
        );
    }
}

const mapStateToProps = (state, props) => {  
    return{ 
        brand:state.brands.singleBrand,  
    } 
}
export default connect(mapStateToProps, {createRole, editRole, fetchPermitionsList, fetchRolePermitions  })(EditRole);
