import React, { Component } from 'react';
import ReactDOM from "react-dom";

import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  
import {columns} from "../../../helpers/viewUsers";  
import {options} from "../../../helpers/viewUsers";  
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns";  

import {fetchUsers} from '../../../_actions/userActions'


class ViewUsers extends Component {
    state={
        permissions: JSON.parse(localStorage.getItem('permissions')),
        roleId: JSON.parse(localStorage.getItem('roleId')),
        options:{},
        columns:[]
     }

    componentWillMount (){
        this.setState({
            options: storeColumnsFilters('usersTable', options),
            columns: getStoredColumnsFilters('usersTable', columns)
        }) 
        this.props.fetchUsers()
    }  

    onDownload = (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
    
    // onRowClick=(rowData , rowMeta, e) => {  
    //     this.props.history.push("/dashboard/users/"+rowData[0]);
    // }
    render() {  
        const {users} = this.props; 
        const {options, columns, permissions= {}, roleId} = this.state;  
        
        return (  
            <React.Fragment> 
                <div className="mb-20">
                    {(roleId!==19&&permissions.usersStore)&&<Link  
                        className="btn btn-info"
                        to="/dashboard/create-user" 
                       ><i className="flaticon-add-circular-button"/>
                        Добави нов потребител
                    </Link>} 
                </div>
                   
                <Card>
                    <MUIDataTable 
                        title={"Потребители:"}
                        data={users}
                        columns={columns} 
                        options={{...options, onDownload:this.onDownload}}   
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
 
const mapStateToProps = state => {  
   return({users:state.users.items})
}

export default withRouter(connect(mapStateToProps, { fetchUsers })(ViewUsers));
// export default ViewUsers;