import React, { Component } from 'react';
import {connect} from 'react-redux'
import Grid from '@material-ui/core/Grid'; 
import TextField from '@material-ui/core/TextField'; 

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

import {updateSingleSaleCarsState} from '../../../_actions/carsSaleActions'
import {salesCarsNames} from '../../../helpers/formsPermissions'


class Vehicle extends Component {
    
    state={
        data:this.props.data ? this.props.data :{},  
        roleId: JSON.parse(localStorage.getItem('roleId'))
    }  

    onChange = (event, index) =>{ 
        const {name,value} = event.target;  
        this.setState({
            data:{
                ...this.state.data,
                [name]:value
            }
        })   
    }
   
    componentWillUnmount(){
        this.props.updateSingleSaleCarsState('vehicle', this.state.data)
    }

    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.data !== this.props.data){
            this.setState({
                data:this.props.data
            })
        }

        if(previousProps.pushToState !== this.props.pushToState){
            if (this.props.pushToState) {
                this.props.updateSingleSaleCarsState('vehicle', this.state.data)
            } 
        }
    } 


   

    render() {  
        const {data, roleId} = this.state; 
        const {company, formUserPermissions={},errors={}}=this.props; 
        const {vehicle={}} = errors;
        const {name : companyName} = company || {}
 
        return (  
            
            <>    
              <Grid container spacing={3}> 
                    <Grid item md={6} xs={12}>   
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            disabled
                            margin="normal" 
                            variant="outlined"   
                            label='Компания'
                            name='companyName'
                            value={companyName}
                            error ={vehicle[companyName]?true:false} 
                            helperText={vehicle[companyName]}  
                        />
                    </Grid>  
                </Grid>  
                <Grid container spacing={3}>  
                    {Object.keys(data).map((key, i) => {  
                        if (formUserPermissions['vehicle.'+key]) { 
                            if (key=='idEmotive' || key=='inventoryNumber') { 
                                return(
                                    <Grid item md={3} xs={12} key={i}>   
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                            InputLabelProps={{shrink: true}} 
                                            disabled={roleId!==1}
                                            margin="normal" 
                                            variant="outlined"   
                                            label={salesCarsNames['vehicle.'+key]}
                                            name={key}
                                            value={data[key]}
                                            error ={vehicle[key]?true:false} 
                                            helperText={vehicle[key]} 
                                            onChange={(e)=>this.onChange(e, i)}  
                                        />
                                    </Grid>   
                                ) 
                            }
                            if (key=='bodyType') { 
                                return( 
                                    <Grid item md={3} xs={12} key={i}>  
                                         <FormControl variant="outlined" error={vehicle[key]?true:false} margin="normal" className='mb-20 full-width select-styles'>
                                            <InputLabel id={key}>
                                                {salesCarsNames['vehicle.'+key]}
                                            </InputLabel>
                                            <Select  
                                                MenuProps={{anchorOrigin: {vertical: "bottom",horizontal:"right"},transformOrigin: { vertical: "top",horizontal: "right"},getContentAnchorEl: null}} 
                                                labelId={key}
                                                required   
                                                inputProps={{ name: key}}  
                                                value={data[key]} 
                                                onChange={(e)=>this.onChange(e, i)}  
                                                labelWidth={70}  >    
                                                <MenuItem value='1'>Седан</MenuItem>   
                                                <MenuItem value='2'> Хечбек</MenuItem>   
                                                <MenuItem value='3'> Лифтбек</MenuItem>   
                                                <MenuItem value='4'> Комби</MenuItem>   
                                                <MenuItem value='5'> Купе</MenuItem>   
                                                <MenuItem value='6'> Кабриолет</MenuItem>   
                                                <MenuItem value='7'> Лимузина</MenuItem>   
                                                <MenuItem value='8'> SUV</MenuItem>   
                                                <MenuItem value='9'> Кросоувър(CUV)</MenuItem>   
                                                <MenuItem value='10'> Ван</MenuItem>   
                                                <MenuItem value='11'> Миниван</MenuItem>   
                                                <MenuItem value='12'> Шаси Кабина</MenuItem>   
                                                <MenuItem value='13'> Фургон</MenuItem>   
                                            </Select>
                                            {vehicle[key]&&<FormHelperText>{vehicle[key]}</FormHelperText>}
                                        </FormControl>   
                                    </Grid>    
                                ) 
                            }else{
                                return(
                                    <Grid item md={3} xs={12} key={i}>   
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                            InputLabelProps={{shrink: true}} 
                                            disabled
                                            margin="normal" 
                                            variant="outlined"   
                                            label={salesCarsNames['vehicle.'+key]}
                                            name={key}
                                            value={data[key]}
                                            error ={vehicle[key]?true:false} 
                                            helperText={vehicle[key]} 
                                            onChange={(e)=>this.onChange(e, i)}  
                                        />
                                    </Grid>   
                                ) 
                            }
                            
                        } 
                    })} 
                </Grid>  
            </>
         );
    }
}

const mapStateToProps = (state, props) => {  
    return{ 
        formUserPermissions:state.roles.formUserPermissions,  
    } 
}
export default connect(mapStateToProps, {updateSingleSaleCarsState })(Vehicle);
 
 

 