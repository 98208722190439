import { apiUrl} from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions"; 


export const fetchUsers = () => dispatch => { 
   
    return fetch(apiUrl+'users', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {   
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_USERS',
                payload:data
            })
        } 
        return response
    });
}

export const createUser = (user) => dispatch => {  
    return fetch(apiUrl+'users', {
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(user)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status,data,message} = response;
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'CREATE_USER',
                payload:data
            })
        } 
        return response
    });  
}

export const editUser = (user) => dispatch => {  
    const {id}=user;
    return fetch(apiUrl+'users/'+id, {
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(user)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status,message} = response;
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'EDIT_USER',
                payload:user
            })
        } 
        return response
    });  
}
 