import React from 'react'; 
import{Provider} from 'react-redux' 
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import PrivateRoute from './components/routing/PrivateRoute';    


// import {store} from './_store' 
import store from './_store' 
import Dashboard from './components/dashboard';

import LogIn from './components/LogIn';

import './App.scss'

function App() { 
    
  return (
    <Provider store={store}> 
        <BrowserRouter>
            <div className="App">  
                <Switch>
                    <Route exact path="/" component={LogIn} /> 
                    <PrivateRoute path="/dashboard" component={Dashboard} />   
                    <Route render={() => <h1>Not found!</h1>} />
                </Switch>   
            </div>
        </BrowserRouter> 
    </Provider>
  );
}

export default App;
