import React from 'react';  

export const options = {
    sortOrder: {
        name: 'id',
        direction: 'desc'
    },
    confirmFilters: true,  
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => { 
        return (
            <div style={{ marginTop: '40px' }}>
                <button className="btn btn-info small" onClick={()=>applyNewFilters()}>Приложи филтрите</button>
            </div>
        );
    },
    print: false,
    download: true,
    downloadOptions: {
        filename: 'TestdriveCars.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
        } 
    },
    viewColumns: true,
    filter:false, 
    selectableRows: "none", 
    customSort: (data, colIndex, order) => {  
        return data.sort((a, b) => {
            // return (a.data[colIndex].length < b.data[colIndex].length ? -1: 1 ) * (order === 'desc' ? 1 : -1);
            a = a.data[colIndex] || '';
            b = b.data[colIndex] || '';
            if (order === 'asc') {
                return a.toString().localeCompare(b, undefined, { numeric: true });
            } else if (order === 'desc') {
                return b.toString().localeCompare(a, undefined, { numeric: true });
            }
        })
    },
    setRowProps: (row) => { 
        if (row[1]==="Свободен" && row[2]==="Не") {
            return {  
                style: {background: '#e3fff1',opacity: '0.5'}
            }
        }else if(row[1]==="Свободен" && row[2]==="Да"){
            return {  
                style: {background: '#e3fff1',opacity: '1'}
            }
        }else if(row[2]==="Не"){
            return {  
                style: {background: '#ffe1e1',opacity: '0.5'}
            }
        }else{
            return {  
                style: {background: '#ffe1e1'}
            }
        }
    },    
    textLabels: {
        body: {
            noMatch: "Няма намерени резултати",
            toolTip: "Сортирай",
            columnHeaderTooltip: column => `Сортирай по ${column.label}`
        },
        pagination: {
            next: "Следваща страница",
            previous: "Предишна страница",
            rowsPerPage: "Брой записи на страница:",
            displayRows: "от",
        },
        toolbar: {
            search: "Търси",
            downloadCsv: "Download CSV",
            print: "Принтирай",
            viewColumns: "Колони",
            filterTable: "Филтри",
        },
        filter: {
            all: "Всички",
            title: "Филтри",
            reset: "Откажи",
        },
        viewColumns: {
            title: "Покажи Колони",
            titleAria: "Покажи/Скрии Колони",
        },
        selectedRows: {
            text: "запис/и са селектирани за изтриване",
            delete: "Изтриване",
            deleteAria: "Изтриите избраните записи",
        },
    }
}; 

export const columns = [ 
    {
        label: "ID:",
        name: "id",
        options: {
            filter: false,
            sort: true, 
           }
    },
    {
        label: "Статус:",
        name: "status",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                if (value===0) {
                    return 'Свободен' 
                }else if (value===1) {
                    return 'Зает' 
                }else{
                    return 'Неактивен'
                }
            }
        }
    },
    {
        label: "Видими:",
        name: "active",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                if (value===0) {
                    return 'Не'  
                }else{
                    return 'Да'
                }
            }
        }
    },
    {
        label: "Марка:",
        name: "brand",
        options: {
            filter: false,
            sort: true,
           }
    },
    {
        label: "Модел:",
        name: "model",
        options: {
            filter: false,
            sort: true,
           }
    }, 
    {
        label: "Регистрационнен номер:",
        name: "registerNumber",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        label: "Общ пробег:",
        name: "runTime",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        label: "Пробег GPS период:",
        name: "runTimeGPS",
        options: {
            filter: false, 
            sort: true
        }
    },
    {
        label: "Пробег система период:",
        name: "runTimeSystem",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        label: "Разлика пробег:",
        name: "differenceRunTime",
        options: {
            filter: false,
            sort: true, 
        }
    },
    {
        label: "Активен TestDrive:",
        name: "activeTestDrives",
        options: {
            filter: false,
            sort: true, 
        }
    },
    {
        label: "Общ брой събития:",
        name: "allTestDrives",
        options: {
            filter: false,
            sort: true, 
        }
    },
    {
        label: "Заместващ Автомобил:",
        name: "publishingType2",
        options: {
            filter: false,
            sort: true, 
        }
    },
    {
        label: "Служебно Ползване:",
        name: "publishingType3",
        options: {
            filter: false,
            sort: true, 
        }
    },
    {
        label: "Репатриране:",
        name: "publishingType4",
        options: {
            filter: false,
            sort: true, 
        }
    },
    {
        label: "Корекция:",
        name: "publishingType5",
        options: {
            filter: false,
            sort: true, 
        }
    },
    {
        label: "Рекламно Шофиране:",
        name: "publishingType6",
        options: {
            filter: false,
            sort: true, 
        }
    },
];
