const initialState = { 
    singleModel:{}
}

export default function (state = initialState, action){  
    const {type, payload} = action; 
    switch(type){ 
        case 'FETCH_SINGLE_MODEL':   
            return{
                ...state,
                singleModel:payload 
            }  
        default:
            return state;
    }
}