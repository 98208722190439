import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid'; 
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import MomentUtils from "@date-io/moment";
import moment from "moment";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'; 
 
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select'; 

const chanelList = [
    {id:1, name:'Социални мрежи'},
    {id:2, name:'Google'},
    {id:3, name:'Радио'},
    {id:4, name:'Print'},
    {id:5, name:'Бордове'},
    {id:6, name:'От приятел'}
]

class CompanyForm extends Component {
    
    state={
        data:{
            id:'',
            companyName:'',
            companyEik:'',
            companyVat:'',
            companyCity:'',
            companyAddress:'',
            companyMol:'',
            phone:'',
            email:'',
            companyCountry:'България',
            companyContact:'',
            egn:'',
            contactCompany:'',
            idCardNumber:'',
            idCardPublishedBy:'',
            idCardDate:'',
            correspondenceAddress:'', 
            proxyNumber:'',
            proxyAuthor:'',
            attorneyNumber:'', 
            branches:[], 
            companyContactEgn:"",
            companyContactIdCardDate:"",
            companyContactIdCardNumber:"",
            companyContactIdCardPublishedBy:"",
            companyContactCity:"",
            hasContact:"",
            model:'',
            chanel:[],
        }, 
        errors:[],   
        open:false,
        isContract:false,
    }

    componentDidMount(){  
        if(this.props.match.path=="/dashboard/orders/:_id" || this.props.match.path=="/dashboard/create-order/" || this.props.match.path=="/dashboard/create-order"){
            this.setState({
                isContract:true
            })
        }
        this.getPassedData()
        if (this.props.errors) { 
            this.setState({errors:this.props.errors })   
        } 
    }
 
  
    componentDidUpdate(previousProps, previousState){  
        if(previousProps.errors !== this.props.errors){   
            if (this.props.errors) {
                this.setState({errors:this.props.errors })   
            } 
        } 
        if(previousProps.userData !== this.props.userData){  
            this.getPassedData()
        }
    }

    getPassedData = () =>{
        const{userData={}, type}=this.props;   
        const{branches=[] }=userData
        if (userData) {   
            const {chanel=[]}=userData; 
            if (userData.type==2) {  
                if (branches.length>0) { 
                    this.setState({ 
                        data:{
                            ...userData,  
                            type:type, 
                            chanel:chanel.length>0 ?chanel :[],
                            incomeId:userData.id,
                            branches:[
                               ...userData.branches, 
                            ]
                        },
                    }) 
                }else{
                    this.setState({ 
                        data:{
                            ...userData,  
                            type:type, 
                            chanel:chanel.length>0 ?chanel :[],
                            incomeId:userData.id,
                            branches:[{'name':''}], 
                        },
                    }) 
                }
                
            }else{  
                this.setState({ 
                    data:{
                        ...this.state.data, 
                        type:type, 
                        incomeId:userData.id,
                        branches:[{'name':''}], 
                    }
                }) 
            }  
        } 
    }

    handleRemove = (i) => {
        const list = [...this.state.data.branches];
        list.splice(i, 1);
        this.setState({
            data:{
                ...this.state.data,
                branches:list
            } 
        }, () => {
            this.props.handleData(this.state.data);
        })
    }

    onChangeDinamic = (e, index) => { 
        const { name, value } = e.target;
        const list = [...this.state.data.branches];
        list[index][name] = value; 
        this.setState({
            data:{
                ...this.state.data,
                branches:list
            } 
        }, () => {
            this.props.handleData(this.state.data);
        })
    };

    handleAdd = ()=>{
        this.setState({ 
            data:{
                ...this.state.data,
                branches:[
                    ...this.state.data.branches, 
                    {'name':''}
                ] 
            }
           
        }, () => {
            this.props.handleData(this.state.data);
        })
    }

     

    onChange = (event) =>{
        const {name,value} = event.target;
        this.setState({
            data:{
                ...this.state.data,
                [name]:value
            },
            errors:{
                ...this.state.errors,
                [name]:''
            }
        }, () => {
            this.props.handleData(this.state.data);
        }) 
    }
    handleCheckbox = (event) =>{
        const{name, checked } = event.target;   
        if (checked) {
            this.setState({
                data:{
                    ...this.state.data,
                    [name]:checked 
                }
            }, () => {
                this.props.handleData(this.state.data);
            })  
        }else{
            this.setState({
                data:{
                    ...this.state.data,
                    attorneyNumber:'',proxyAuthor:'',proxyNumber:'', companyContactIdCardDate:'',companyContactIdCardPublishedBy:'',companyContactIdCardNumber:'',companyContactCity:'',companyContactEgn:'',companyContact:'',
                    [name]:checked 
                }
            }, () => {
                this.props.handleData(this.state.data);
            })  
        }
       
    }
    
    onChangeDate = (input, date, value) => {   
        this.setState({
            data:{
                ...this.state.data, 
                [input]:value 
            },
            errors:{
                ...this.state.errors,
                [input]:''
            } 
        }, () => {
            this.props.handleData(this.state.data);
        })  
    }
    handleChangeChanels = (event)=>{
        this.setState({
            data:{
                ...this.state.data, 
                chanel:event.target.value
            }
        })
    }
    render() { 
        const {errors, isContract}= this.state;
        const {model,chanel, hasContact=false,companyContactEgn='', companyContactCity='',correspondenceAddress='', companyContactIdCardDate='', companyContactIdCardNumber='', companyContactIdCardPublishedBy='', branches='', companyName='',email='', companyEik='',companyCountry='', companyCity='', companyAddress='', companyVat='',companyMol='',phone='', egn='', idCardNumber='',idCardPublishedBy='', idCardDate='' ,proxyAuthor='', proxyNumber='', attorneyNumber='', companyContact='', contactCompany=''}= this.state.data;
        return (  
            <>    
                <Grid container spacing={3} > 
                    <Grid item md={4} xs={12}> 
                        <h3 className="section-title"> Данни за фирмата:</h3>
                    </Grid>
                </Grid>
                <Grid container spacing={3} > 
                    <Grid item md={4} xs={12}>  
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-user"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="МОЛ:"
                            name="companyMol"
                            value={companyMol}
                            onChange={this.onChange}
                            error ={errors.companyMol?true:false}  
                            helperText={errors.companyMol}  
                        />    
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-rotate"></i>  
                                    </InputAdornment>
                                ),
                                type:"password",
                                autoComplete: 'new-password'
                            }}
                            label="ЕГН:" 
                            name="egn"   
                            value={egn}
                            error ={errors.egn?true:false}  
                            helperText={errors.egn}  
                            onChange={this.onChange} 
                        />  
                         <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-browser"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="ЛК/номер:"
                            name="idCardNumber" 
                            value={idCardNumber}
                            error ={errors.idCardNumber?true:false} 
                            helperText={errors.idCardNumber} 
                            onChange={this.onChange} 
                        />  
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-browser"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Издадена от:"
                            name="idCardPublishedBy" 
                            value={idCardPublishedBy}
                            error ={errors.idCardPublishedBy?true:false}  
                            helperText={errors.idCardPublishedBy} 
                            onChange={this.onChange} 
                        />   
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                            <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                                autoOk={true}
                                showTodayButton={true} 
                                InputLabelProps={{shrink: true}} 
                                className="full-width date mb-20"  
                                inputVariant="outlined" 
                                margin="normal"
                                label="ЛК/дата на издаване:" 
                                format="DD.MM.YYYY"
                                value=''
                                inputValue={idCardDate} 
                                onChange={(e,d)=>this.onChangeDate('idCardDate', e ,d)}
                                // rifmFormatter={this.dateFormatter} 
                                error ={errors.idCardDate?true:false}  
                                helperText={errors.idCardDate} 
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>    
                    <Grid item md={4} xs={12}>    
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="far fa-building"></i> 
                                    </InputAdornment>
                                ),
                            }}
                            label="Име на фирмата:"
                            name="companyName"
                            value={companyName}
                            onChange={this.onChange}
                            error ={errors.companyName?true:false}  
                            helperText={errors.companyName}  
                        />  
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-rotate"></i>  
                                    </InputAdornment>
                                ),
                            }}label="ЕИК на фирмата:"
                            name="companyEik"  
                            value={companyEik}
                            error ={errors.companyEik?true:false}  
                            helperText={errors.companyEik}  
                            onChange={this.onChange} 
                        />   
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-rotate"></i>  
                                    </InputAdornment>
                                ),
                            }}label="ДДС номер:"
                            name="companyVat"  
                            value={companyVat}
                            error ={errors.companyVat?true:false}  
                            helperText={errors.companyVat}  
                            onChange={this.onChange} 
                        />    
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-user"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Телефонен номер:"
                            name="phone"
                            value={phone}
                            error ={errors.phone?true:false}  
                            helperText={errors.phone}  
                            onChange={this.onChange} 
                        />   
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-envelope"></i>  
                                    </InputAdornment>
                                ),
                            }}label="Email:"
                            name="email"  
                            value={email}
                            error ={errors.email?true:false}  
                            helperText={errors.email}  
                            onChange={this.onChange} 
                        />    
                    </Grid>   
                    <Grid item md={4} xs={12}>  
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-browser"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Държава:"
                            name="companyCountry" 
                            value={companyCountry}
                            error ={errors.companyCountry?true:false} 
                            helperText={errors.companyCountry} 
                            onChange={this.onChange} 
                        />  
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-browser"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Град:"
                            name="companyCity" 
                            value={companyCity}
                            error ={errors.companyCity?true:false} 
                            helperText={errors.companyCity} 
                            onChange={this.onChange} 
                        />  
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-home-1"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Адрес :"
                            name="companyAddress" 
                            value={companyAddress}
                            error ={errors.companyAddress?true:false}  
                            helperText={errors.companyAddress} 
                            onChange={this.onChange} 
                        /> 
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-home-1"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Адрес за кореспонденция:"
                            name="correspondenceAddress" 
                            value={correspondenceAddress}
                            error ={errors.correspondenceAddress?true:false}  
                            helperText={errors.correspondenceAddress} 
                            onChange={this.onChange} 
                        /> 
                        {/* <TextField 
                            inputProps={{'className':'data-hj-allow'}}           
                            className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="fas fa-city"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Представител на Компания:"
                            name="contactCompany" 
                            value={contactCompany}
                            error ={errors.contactCompany?true:false} 
                            helperText={errors.contactCompany}
                            onChange={this.onChange} 
                        />  */}
                         <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}}
                            margin="normal"  
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="fas fa-car"></i> 
                                    </InputAdornment>
                                ),
                            }}
                            label="Модел"
                            name="model"
                            value={model}
                            error={errors.model?true:false} 
                            helperText={errors.model} 
                            onChange={this.onChange}  
                        /> 
                    </Grid> 
                </Grid> 
                <Grid container spacing={3} >  
                    <Grid item md={8} xs={12}> 
                        <h4>Канали:</h4>
                        <Select
                            className='full-width'
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            value={chanel}
                            onChange={this.handleChangeChanels}
                            onClose={() =>{this.props.handleData(this.state.data)}}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (

                                <div className="chips">
                                {selected.length>0&& selected.map((item) =>{    
                                    const chanel =chanelList.find(x => x.id == item) || {}
                                    return  ( 
                                        chanelList.length>0&&<Chip key={item} label={chanel.name} className="chip"/>
                            
                                    )})}
                                </div>
                            )}
                            >
                            {chanelList.map((item) => {
                                return (
                                    <MenuItem key={item.id} value={item.id} className="chip-options">
                                        {item.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                </Grid>
                {!isContract&&<><Grid container spacing={3} > 
                    <Grid item md={6} xs={12}> 
                        <h3 className="section-title"> Клонове:</h3>
                        {errors.branches&&<p style={{'color':'red'}}>Името на бранча е задължително.</p>}
                        {branches.map((item, i) =>{
                            return(  
                            <div className="aditional-accessory" key={i}>
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    name="name"
                                    margin="normal" 
                                    variant="outlined"    
                                    label="Име на клона:"
                                    value={item.name}
                                    onChange={e=>this.onChangeDinamic(e, i)}  
                                />   
                                <div className="btn-box">
                                    {branches.length !== 1 &&
                                    <div className='delete' onClick={()=>this.handleRemove(i)}>
                                        <i className="flaticon2-delete"></i>  
                                    </div>}
                                    {branches.length - 1 === i && 
                                    <div className='add' onClick={this.handleAdd}>
                                        +
                                    </div>}
                                </div>
                            
                            </div>
                            )
                        })}
                    </Grid>
                    </Grid>
                <br/>
                <br/></>} 
                
                <Grid container spacing={3} > 
                    <Grid item md={4} xs={12}> 
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={Boolean(hasContact)}
                                onChange={this.handleCheckbox}
                                name="hasContact"
                                color="primary"
                            />
                            }
                            label="Добави Представител"
                        /> 
                    </Grid>
                </Grid>
                {hasContact&&<>
                <Grid container spacing={3} > 
                    <Grid item md={4} xs={12}> 
                        <h3 className="section-title"> Данни за Представител:</h3>
                    </Grid>
                </Grid>   
                
                <Grid container spacing={3} > 
                    <Grid item md={4} xs={12}> 
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-user"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Лице за контакт:"
                            name="companyContact"
                            value={companyContact}
                            onChange={this.onChange}
                            error ={errors.companyContact?true:false}  
                            helperText={errors.companyContact}  
                        />     
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-rotate"></i>  
                                    </InputAdornment>
                                ),
                            }}label="ЕГН:"
                            name="companyContactEgn"  
                            value={companyContactEgn}
                            error ={errors.companyContactEgn?true:false}  
                            helperText={errors.companyContactEgn}  
                            onChange={this.onChange} 
                        />  
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-home-1"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="С постоянен адрес Град"
                            name="companyContactCity"  
                            value={companyContactCity}
                            error ={errors.companyContactCity?true:false}  
                            helperText={errors.companyContactCity} 
                            onChange={this.onChange} 
                        />  
                    </Grid>   

                    <Grid item md={4} xs={12}>    
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-browser"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="ЛК/номер:"
                            name="companyContactIdCardNumber" 
                            value={companyContactIdCardNumber}
                            error ={errors.companyContactIdCardNumber?true:false} 
                            helperText={errors.companyContactIdCardNumber} 
                            onChange={this.onChange} 
                        />  
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-browser"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Издадена от:"
                            name="companyContactIdCardPublishedBy" 
                            value={companyContactIdCardPublishedBy}
                            error ={errors.companyContactIdCardPublishedBy?true:false}  
                            helperText={errors.companyContactIdCardPublishedBy} 
                            onChange={this.onChange} 
                        />  
                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                            <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                                autoOk={true}
                                showTodayButton={true} 
                                InputLabelProps={{shrink: true}} 
                                className="full-width date mb-20"  
                                inputVariant="outlined" 
                                margin="normal"
                                label="ЛК/дата на издаване:" 
                                format="DD.MM.YYYY"
                                value=''
                                inputValue={companyContactIdCardDate} 
                                onChange={(e,d)=>this.onChangeDate('companyContactIdCardDate', e ,d)}
                                // rifmFormatter={this.dateFormatter} 
                                error ={errors.companyContactIdCardDate?true:false}  
                                helperText={errors.companyContactIdCardDate} 
                            />
                        </MuiPickersUtilsProvider>
                       
                    </Grid>   
                    <Grid item md={4} xs={12}>   
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-list-2"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="РЕГ. № на пълномощно"
                            name="proxyNumber"  
                            value={proxyNumber}
                            error ={errors.proxyNumber?true:false}  
                            helperText={errors.proxyNumber} 
                            onChange={this.onChange} 
                        /> 
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-list-2"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Издадено от:"
                            name="proxyAuthor"  
                            value={proxyAuthor}
                            error ={errors.proxyAuthor?true:false}  
                            helperText={errors.proxyAuthor} 
                            onChange={this.onChange} 
                        /> 
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-list-2"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Нотариус с рег. №:"
                            name="attorneyNumber"  
                            value={attorneyNumber}
                            error ={errors.attorneyNumber?true:false}  
                            helperText={errors.attorneyNumber} 
                            onChange={this.onChange} 
                        /> 
                            
                        
                    </Grid> 
                </Grid>    
                </>}            
                            
          
            </>
         );
    }
}
 
export default withRouter(CompanyForm);

 