import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { withRouter } from "react-router-dom";
import {connect} from 'react-redux'

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";  

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'; 

import {fetchConectedClients, postConectedClients} from '../../../_actions/clientsActions'
import {fetchClientsAutoComplete} from '../../../_actions/clientsActions'
class ConnectedClients extends Component {
    state = { 
        connectedClientId:'',
        connectedClientName:'',
        showBtn:false
     }

    componentWillUnmount() {
        const id= this.props.match.params._id;
        this.props.history.push("/dashboard/clients/"+id);
    }

    componentDidMount(){    
        const id= this.props.match.params._id;
        if (id) {
            this.props.fetchConectedClients(id);
        } 
    }

    openClientEntry=(id)=>{
        this.props.history.push("/dashboard/clients/"+id);
    }

    changeClientData = (event, value, reason) => {
        if (value || event) {   
          
            this.setState({
                connectedClientId:value.id,
                connectedClientName:value.name,
                showBtn:true
            })     
        }
    }

    addClient = () =>{
        const id= this.props.match.params._id;
        const data = {
            connectedClientId:this.state.connectedClientId
        }
        this.props.postConectedClients(id, data).then(data => { 
            if(data.status==="success"){ 
                this.props.history.push("/dashboard/clients/"+id+"/?refreshClients=true");
                this.setState({
                    connectedClientId:'',
                    connectedClientName:'',
                    showBtn:false
                })
                this.props.fetchConectedClients(id);
            }
        })
    }
  

    render() { 
        const{relatedClients, clients=[]}=this.props;  
        const {showBtn, connectedClientName}=this.state;
        return (  
            <> 
                <Grid container className="section-title-wrapper padding"> 
                    <Grid container item xs={8} alignItems="center"> 
                        <h3 className="section-title">
                            Свързани Клиенти:
                        </h3>
                    </Grid>  
                </Grid>  
                <Grid container spacing={3}> 
                    <Grid item md={5} xs={12}>
                        <Autocomplete 
                            options={clients}
                            inputValue={connectedClientName}
                            onChange={this.changeClientData}  
                            getOptionLabel={option => {
                                return option.name? 
                                option.name+" - " + (option.type==1 ? "ФЗ":"ЮЛ") :
                                option.companyName+" - "(+option.type==1 ? "ФЗ":"ЮЛ")
                            }}
                            renderInput={params => (
                                <TextField {...params}
                                className="full-width mb-20"
                                InputLabelProps={{shrink: true}} 
                                margin="normal"
                                variant="outlined"
                                label="Търси клиент:" 
                                />
                            )} 
                        />  
                    </Grid>  
                    {showBtn&&<Grid item md={3} xs={12}>
                       <button style={{"marginTop": "17px"}} onClick={this.addClient}className='btn btn-info full-width'>Добави клиент</button>
                    </Grid>}  
                </Grid>  
                <Grid container spacing={3}>  
                    <Grid item md={12} xs={12}>  
                            <table className="regular-table">
                                <thead>                         
                                <tr>
                                    <th>id</th>
                                    <th>Име:</th>
                                    <th>ЕИК / EГН:</th> 
                                </tr>
                            </thead>   
                            <tbody>
                                {relatedClients.map( item =>{
                                    if (item.type===2) {
                                        return(
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.companyName}</td> 
                                                <td>{item.companyEik}</td> 
                                      
                                            
                                            </tr> 
                                        )
                                    }else if (item.type===1) {
                                        return(
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td> 
                                                <td>{item.egn}</td> 
                                            </tr> 
                                        )
                                    } else{
                                        return(
                                            <tr>
                                                <td colSpan="5"><h3>Няма резултати</h3></td> 
                                            </tr> 
                                        )
                                    }
                                })} 
                                 {relatedClients.length <1&& 
                                    <tr>
                                        <td colSpan="3"><h3>Няма резултати</h3></td> 
                                    </tr>  
                                }
                            </tbody> 
                        </table>       
                    </Grid>    
                </Grid>    
            </>
        );
    }
}
const mapStateToProps = (state, props) => {   
    
    return{ 
        relatedClients:state.clients.relatedClients,  
        clients:state.clients.items
    } 
}
export default withRouter(connect(mapStateToProps, {fetchConectedClients,postConectedClients, fetchClientsAutoComplete })(ConnectedClients)); 
 
 