import React from 'react'; 

export const options = { 
    sortOrder: {
        name: 'id',
        direction: 'desc'
    },
    confirmFilters: true,  
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => { 
        return (
            <div style={{ marginTop: '40px' }}>
                <button className="btn btn-info small" onClick={()=>applyNewFilters()}>Приложи филтрите</button>
            </div>
        );
    },
    print: false,
    download: true,
    downloadOptions: {
        filename: 'HISTORY.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
        } 
    },
    viewColumns: true,
    filter:true,  
    selectableRows: "none",     
    rowsPerPage:100, 
    responsive:'scrollFullHeight',
    textLabels: {
        body: {
            noMatch: "Няма Намерени Резултати",
            toolTip: "Сортирай",
            columnHeaderTooltip: column => `Сортирай по ${column.label}`
        },
        pagination: {
            next: "Следваща страница",
            previous: "Предишна страница",
            rowsPerPage: "Брой записи на страница:",
            displayRows: "от",
        },
        toolbar: {
            search: "Търси",
            downloadCsv: "Download CSV",
            print: "Принтирай",
            viewColumns: "Колони",
            filterTable: "Филтри",
        },
        filter: {
            all: "Всички",
            title: "Филтри",
            reset: "Откажи",
        },
        viewColumns: {
            title: "Покажи Колони",
            titleAria: "Покажи/Скрии Колони",
        },
        selectedRows: {
            text: "запис/и са селектирани за изтриване",
            delete: "Изтриване",
            deleteAria: "Изтриите избраните записи",
        },
    }
}; 

export const columns = [ 
    
    {
        label: "ID:",
        name: "id",
        options: {
            filter: true,
            sort: true,  
           }
    },
    {
        label: "Потребител:",
        name: "user",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        label: "Дата:",
        name: "created",
        options: {
            filter: false,
            sort: false,
           }
    },   
    {
        label: "Стари стойности:",
        name: "changes",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => { 
                const {old=[]}=value || {}
                if (old) {
                    return(
                        old.map(el=>{  
                            let customValue='';  
                            if (el.key==='status'){ 
                                if (el.value===0) {
                                    customValue = "Свободен"
                                }else if (el.value===1) {
                                    customValue = 'Зает' 
                                }else if (el.value===2) {
                                    customValue = 'Неактивен'
                                }else{
                                    customValue = el.value
                                }
                            }  
                            if (el.key==='fuelType'){ 
                                if (el.value===1) {
                                    customValue = "Бензин"
                                }else if (el.value===2) {
                                    customValue = 'Дизел' 
                                }else if (el.value===3) {
                                    customValue = 'Метан'
                                }else if (el.value===4) {
                                    customValue = 'Газ'
                                }else if (el.value===5) {
                                    customValue = 'Електрически'
                                }else{
                                    customValue = el.value
                                }
                            } 
                            return( 
                                <p key={el.key}>{el.translation}: 
                                    <strong> {customValue}</strong>
                                </p>  
                            )
                        })  
                    )
                } 
            }
        }
    },
    {
        label: "Нови стойности:",
        name: "changes",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                const {new : newChanges =[]}=value || {}
                if (newChanges) {
                    return(
                        newChanges.map(el=>{ 
                            let customValue=''; 
                            
                            if (el.key==='status'){ 
                                if (el.value===0) {
                                    customValue = "Свободен"
                                }else if (el.value===1) {
                                    customValue = 'Зает' 
                                }else if (el.value===2) {
                                    customValue = 'Неактивен'
                                }else{
                                    customValue = el.value
                                }
                            }  
                            if (el.key==='fuelType'){ 
                                if (el.value===1) {
                                    customValue = "Бензин"
                                }else if (el.value===2) {
                                    customValue = 'Дизел' 
                                }else if (el.value===3) {
                                    customValue = 'Метан'
                                }else if (el.value===4) {
                                    customValue = 'Газ'
                                }else if (el.value===5) {
                                    customValue = 'Електрически'
                                }else{
                                    customValue = el.value
                                }
                            }  
                            return( 
                                <p key={el.key}>{el.translation}: 
                                    <strong> {customValue}</strong>
                                </p>  
                            )
                        })  
                    )
                } 
            }
        }
    }
];
