const initialState = {
    items:[]
}

export default function (state = initialState, action){
    const {type, payload} = action;  
    switch(type){
        case 'FETCH_USERS':  
            return{
                ...state,
                items:payload 
            }
        case 'CREATE_USER':
            return { 
                ...state,
                items: [...state.items, payload]
            } 
        case 'EDIT_USER': 
            return{ ...state, 
                items:state.items.map(user => ( user.id === payload.id ? payload : user ))
            } 
        default:
            return state;
    }
}