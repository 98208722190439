import React,{Component} from 'react';
import { Switch } from "react-router-dom";
import {Redirect} from 'react-router'; 
import {connect} from 'react-redux'   
import PrivateRoute from '../routing/PrivateRoute';


import SideBar from '../layout/SideBar'
import TopBar from '../layout/TopBar'
import Alert from '../layout/Alert'
 
import DriveList from './drive/DriveList' 
import CreateTestDrive from './drive/NotUsed_CreateTestDrive' 
import EditTestDrive from './drive/EditTestDrive' 

import ViewUsers from'./users/ViewUsers'
import CreateUser from'./users/CreateUser' 
import EditUser from'./users/EditUser' 

import CarsList from'./cars/CarsList'  
import EditCar from'./cars/Edit_Create_Car' 

import CarsSalesList from'./carsSales/CarsSalesList'  
import Example from'./carsSales/Example'  
import CarsSalesListForOffer from'./carsSales/CarsSalesListForOffer'  
import Edit_Create_CarSales from'./carsSales/Edit_Create_CarSales' 

import ClientsList from'./clients/ClientsList' 
import EditClient from'./clients'  

import ViewHistory from'./ViewHistory' 

import ContactsList from'./contacts/ContactsList' 
import EditContact from'./contacts/Edit_Create_Contact'  

import ListOffers from'./offers/ListOffers'  
import EditOffer from'./offers/Edit_Create_Offer'  
import OffersForApproval from'./offers/OffersForApproval'  
import ModifyOfferEdit from'./ModifyOffer/Edut'  
import ModifyOfferList from'./ModifyOffer/List'   

import ListCompanies from'./companies/ListCompanies'  
import EditCompany from'./companies/Edit_Create_Company'  

import BrandsList from'./brands/BrandsList'  
import EditBrand from'./brands/Edit_Create_Brand'   
import EditModel from'./models/Edit_Create_Model'   

import AgreementsList from'./agreements/AgreementsList' 

import OrdersList from'./orders/OrdersList'
import EditOrder from'./orders/Edit_Create_order'

import RolesList from'./permissions/roles/RolesList'  
import EditRole from'./permissions/roles/Create_Edit_Role'   

import FormsPermitionList from'./permissions/forms/FormsPermitionList'  
import EditFormsPermition from'./permissions/forms/EditFormsPermition'   

import {forceLogout} from '../../_actions/authActions'



class Dashboard extends Component { 
    state ={
        permissions: JSON.parse(localStorage.getItem('permissions'))? JSON.parse(localStorage.getItem('permissions')) :{},
    }

    componentDidUpdate (){
        const cookie = ("; "+document.cookie).split("; accessToken=").pop().split(";").shift();
        if (localStorage.getItem('isAuthenticated') === null || cookie === null || localStorage.getItem('roleId') === null ) {
          
            this.props.forceLogout();
        } 
    }

    

    render() {   
        const {permissions}= this.state; 
        return (   
        <React.Fragment> 
            <Alert/>
            <SideBar/>  
            <div className="main-container">
                <TopBar/>
                <div className="wrapper">   
                    <Switch>

                        <PrivateRoute exact path="/dashboard/example" component={Example} />
                        {permissions.logActivityShow&&<PrivateRoute exact path="/dashboard/history" component={ViewHistory} />} 
                        {permissions.usersShow&&<PrivateRoute exact path="/dashboard/users" component={ViewUsers} />}   
                        {permissions.usersUpdate&&<PrivateRoute path="/dashboard/users/:_id" component={EditUser} /> }  
                        {permissions.usersStore&&<PrivateRoute path="/dashboard/create-user" component={CreateUser} />}   

                        {permissions.carsShow&&<PrivateRoute exact path="/dashboard/cars" component={CarsList} />} 
                        {permissions.carsUpdate&&<PrivateRoute path="/dashboard/cars/:_id" component={EditCar} />}
                        {permissions.carsStore&&<PrivateRoute path="/dashboard/create-car" component={EditCar} /> }  
                        
            
                        <PrivateRoute exact path="/dashboard/saleCarsList" component={CarsSalesListForOffer} />
                        {permissions.salesCarsShow&&<PrivateRoute exact path="/dashboard/sale_cars" component={CarsSalesList} />}
                        {permissions.salesCarsUpdate&&<PrivateRoute path="/dashboard/sale_cars/:_id" component={Edit_Create_CarSales} />}
                        {/* <PrivateRoute path="/dashboard/create-sale-car" component={Edit_Create_CarSales} />  */}
                
                        {permissions.clientsShow&&<PrivateRoute exact path="/dashboard/clients" component={ClientsList} />}   
                        {permissions.clientsUpdate&&<PrivateRoute path="/dashboard/clients/:_id" component={EditClient} />}   
                        {permissions.clientsStore&&<PrivateRoute path="/dashboard/create-client" component={EditClient} />}   
                        
                        {permissions.contractsShow&&<PrivateRoute exact path="/dashboard/agreements" component={AgreementsList} />} 
                        {/* {permissions.contractsUpdate&&<PrivateRoute exact path="/dashboard/agreements/:_id" component={EditAgreement} />} 
                        {permissions.contractsStore&&<PrivateRoute exact path="/dashboard/create-agreement" component={EditAgreement} />}  */}
                        
                        {permissions.ordersShow&&<PrivateRoute exact path="/dashboard/orders" component={OrdersList} />} 
                        {permissions.ordersUpdate&&<PrivateRoute exact path="/dashboard/orders/:_id" component={EditOrder} />} 
                        {permissions.ordersStore&&<PrivateRoute exact path="/dashboard/create-order/" component={EditOrder} />} 

                        {permissions.offersShow&&<PrivateRoute exact path="/dashboard/offers" component={ListOffers} />}   
                        <PrivateRoute exact path="/dashboard/offersForApproval" component={OffersForApproval} />  
                        {permissions.offersUpdate&&<PrivateRoute path="/dashboard/offers/:_id" component={EditOffer} />}   
                        {permissions.offersStore&&<PrivateRoute path="/dashboard/create-offer" component={EditOffer} />}   

                        {permissions.contactsShow&&<PrivateRoute exact path="/dashboard/contacts" component={ContactsList} />} 
                        {permissions.contactsUpdate&&<PrivateRoute path="/dashboard/contacts/:_id" component={EditContact} />}
                        {permissions.contactsStore&&<PrivateRoute path="/dashboard/create-contact" component={EditContact} />}  
                        
                        {permissions.testDrivesShow&&<PrivateRoute exact path="/dashboard/drive-list" component={DriveList} />}
                        {permissions.testDrivesUpdate&&<PrivateRoute path="/dashboard/drive-list/:_id" component={EditTestDrive} />}
                        {permissions.testDrivesStore&&<PrivateRoute path="/dashboard/create-testDrive" component={EditTestDrive} />}
              
                        
                        {permissions.companiesShow&&<PrivateRoute exact path="/dashboard/companies" component={ListCompanies} />}
                        {permissions.companiesUpdate&&<PrivateRoute path="/dashboard/companies/:_id" component={EditCompany} />}
                        {/* <PrivateRoute path="/dashboard/create-company" component={EditCompany} /> */}
                        
                        {permissions.brandsShow&&<PrivateRoute exact path="/dashboard/brands" component={BrandsList} />}
                        {permissions.brandsUpdate&&<PrivateRoute path="/dashboard/brands/:_id" component={EditBrand} />}
                        {permissions.brandsStore&&<PrivateRoute path="/dashboard/create-brand" component={EditBrand} />}
                        
                   
                        {permissions.modelsStore&&<PrivateRoute path="/dashboard/model/:_id" component={EditModel} />}
                        {permissions.modelsUpdate&&<PrivateRoute path="/dashboard/create-model" component={EditModel} />}
                        
                        {permissions.rolesShow&&<PrivateRoute exact path="/dashboard/roles" component={RolesList} />}
                        {permissions.rolesUpdate&&<PrivateRoute path="/dashboard/roles/:_id" component={EditRole} />}
                        {permissions.rolesStore&&<PrivateRoute path="/dashboard/create-role" component={EditRole} />  }
                
                        {permissions.rolesShow&&<PrivateRoute exact path="/dashboard/formsPermissions" component={FormsPermitionList} />}
                        {permissions.rolesUpdate&&<PrivateRoute path="/dashboard/formsPermissions/:_id/role/:_roleId" component={EditFormsPermition} />} 

                        <PrivateRoute path="/dashboard/modify-offer" exact component={ModifyOfferList} />
                        <PrivateRoute path="/dashboard/modify-offer/:_id" component={ModifyOfferEdit} />
                        <PrivateRoute path="/dashboard/create-modified-offer" component={ModifyOfferEdit} />
                
                        <Redirect exact to="/dashboard/drive-list" /> 
                    </Switch>  
                </div> 
            </div> 
        </React.Fragment> 
        );
    }
}
 

export default connect(null, {forceLogout})(Dashboard);

  
 