import React, { Component } from 'react';
 
import {Link} from 'react-router-dom';

import {connect} from 'react-redux'

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";  

import FormHolder from "./FormHolder";
import ClientHistory from './Client_History'
 
import ClientContacts from './Client_Contacts' 
import LizingCompanies from './LizingCompanies' 
import ConnectedClients from './ConnectedClients' 

 

import {fetchSingleClient,fetchClients, editClient, createClient,fetchClientsAutoComplete, emptyClient} from '../../../_actions/clientsActions'
import {fetchSingleContact} from '../../../_actions/contactsActions'

class EditClient extends Component {
    state={
        editMode:false,
        viewAditional:0,
        fromContact:'',
        clientId:'',
        navIds:'',
        clientData:{
            type:1
        },
        errors:[],
        contactName:'',
        roleId: JSON.parse(localStorage.getItem('roleId')),

    }

    componentWillUnmount() {
        this.unlisten();
    }
   
    componentDidMount = () =>{  
        this.props.fetchClients();
        if (this.props.match.params._id) {
            this.setState({
                editMode:true,
                clientId:this.props.match.params._id
            })
            this.props.fetchSingleClient(this.props.match.params._id);    
        }

        this.unlisten = this.props.history.listen((location, action) => {
        
            const query = new URLSearchParams(location.search);
            const refresh = query.get('refresh');
            if(refresh){  
                this.props.fetchSingleClient(this.props.match.params._id);   
                this.setState({editMode:true}) 
            }  
            const refreshClients = query.get('refreshClients');
            if(refreshClients){  
                this.props.fetchSingleClient(this.props.match.params._id);   
                this.setState({
                    editMode:true,
                    viewAditional:4
                }) 
            }  
        });

        const query = new URLSearchParams(this.props.location.search);
        const contactId = query.get('contactId');
        if(contactId){
            this.props.fetchSingleContact(contactId).then(data => { 
                if(data.status==="success"){ 
                    this.setState({
                        clientData:{ 
                            fromContact:contactId,
                            ...data.data
                        }
                    }) 
                } 
            }) 
        }   
    }

    componentDidUpdate(previousProps, previousState){ 
     
        if(previousProps.contact !== this.props.contact){
            const {name, companyName} = this.props.contact
            if (companyName) {
                this.setState ({contactName:companyName})   
            }
            if (name) {
                this.setState ({contactName:name})   
            }  
        }
        if(previousProps.client !== this.props.client){ 
            const {client}=this.props
  
            this.setState ({
                clientData:client,
                navIds:client.navIds
            })   
        }  
    } 

    handleData=(data)=>{ 
        const {fromContact}=this.state.clientData;
        if (fromContact) {
            this.setState({
                clientData:{fromContact,...data}
            })
        }else{
            this.setState({
                clientData:{...data}
            })
        }
    }

    createContract = (id) => {
        this.props.history.push("/dashboard/create-agreement/?clientId="+this.state.clientId);
    }

    getClientAutocomplete = (name, type) => {
        this.props.fetchClientsAutoComplete(name, type);   
    }

    onSubmit =(event)=>{
        event.preventDefault();
        const {editMode} = this.state;
        let payload = {}; 
        if (this.state.clientData.type) {
            payload = this.state.clientData;
        }else{
            payload = {
                type:1,
                ...this.state.clientData
            }
        }
        if (editMode) {
            this.props.editClient(payload).then(data => { 
                if(data.status==="success"){
                    this.setState({errors:[]}) 
                    this.props.history.push('/dashboard/clients')
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }   
            }) 
        }else{
            this.props.createClient(payload).then(data => { 
                if(data.status==="success"){ 
                    this.setState({ 
                        clientData:{},
                        errors:[]
                    })
                    this.props.history.push("/dashboard/clients/"+data.data.id+"/?refresh=true"); 
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }  
            }) 
        }
    }
 

    render() { 
        const {editMode, viewAditional, clientData, navIds, fromContact,errors, contactName, roleId}=this.state;
 
        const pageTitle = editMode ? 'Редактиране на клиент' : 'Създаване на Клиент';  
        const {clients}=this.props;   
        return (  
            <React.Fragment>  
                <Card >
                    {viewAditional===0&&
                        <Grid container className="section-title-wrapper padding"> 
                            <Grid container item xs={7} md={4} alignItems="center"> 
                                <h3 className="section-title">
                                    <i className="flaticon2-gear"></i> {pageTitle}
                                    {fromContact.length>0 ? 
                                    <span> за контакт "{contactName}"</span>
                                :':'}
                                </h3> 
                            </Grid>  
                            
                            <Grid container item md={4} xs={1} alignItems="center" justify="flex-end">  
                            {/* {(editMode && viewAditional===0) &&<div className="btn btn-primary full-width " onClick={()=>{this.createContract(id)}}>
                                        <i className="flaticon2-add-square"></i>Създай договор
                                    </div> }  */}
                            </Grid>
                            <Grid container item md={4} xs={4} alignItems="center" justify="flex-end">  
                                <Link  
                                    onClick={this.props.emptyClient}
                                    className="btn btn-secondary"
                                    to="/dashboard/clients" 
                                ><i className="flaticon2-circular-arrow"/>
                                   <span>Назад</span> 
                                </Link>  
                            </Grid>  
                        </Grid>  
                    }   
                    <Grid container className="padding" alignItems="flex-start">  
                        <Grid container item md={6} xs={12} >  
                            {viewAditional!==0&&
                                <Grid container item md={12} xs={12} alignItems="flex-start"> 
                                    <button className="btn"
                                    onClick={()=>{this.setState({viewAditional:0})}}>
                                        <i className="flaticon2-circular-arrow"></i><span>Назад</span> 
                                    </button> 
                                </Grid>  
                            }
                            {(editMode && viewAditional===0) &&<>  
                            
                                <Grid container item md={12} xs={12} alignItems="flex-start">  
                                    <div className="btn btn-warning mr-10 nav-wrapper mb-20-m"> 
                                        {navIds.length>0&&navIds.map(item=>{
                                            return <p>{item.company}: Nav ID <strong>{item.navId}</strong></p>  
                                        })} 
                                      
                                        {navIds.length===0&&<span>Nav ID: <strong>няма намерени резултати!</strong></span>} 
                                    </div>   
                                </Grid>  
                            </>}
                        </Grid>    
                        
                        {editMode&&<>
                            <Grid container item md={1} xs={2} ></Grid>   
                            <Grid container item  xs={12} md={5} alignItems="center" justify="flex-end">
                                <div className='mb-10 header-section-inner'>
                                    <button className={"btn mr-10 "+(viewAditional===1 ? 'btn-focus':'' )}
                                    onClick={()=>{this.setState({viewAditional:1})}}>
                                        <i className="flaticon-users"></i> Контакти
                                    </button>
                                    <button className={"btn  "+(viewAditional===2 ? 'btn-primary':'' )}
                                        onClick={()=>{this.setState({viewAditional:2})}}>
                                        <i className="flaticon2-crisp-icons-1"></i> Иcтория
                                    </button> 
                                    {/* <button className={"btn  "+(viewAditional===5 ? 'btn-primary':'' )}
                                        onClick={()=>{this.setState({viewAditional:5})}}>
                                        <i className="fas fa-paste"></i> Оферти
                                    </button>  */}
                                </div>  
                                <div className='mb-10 header-section-inner'> 
                                    <button className={"btn mr-10 "+(viewAditional===3 ? 'btn-primary':'' )}
                                        onClick={()=>{this.setState({viewAditional:3})}}>
                                        <i className="fas fa-city"></i> Лизинг
                                    </button>   
                                    <button className={"btn "+(viewAditional===4 ? 'btn-primary':'' )}
                                        onClick={()=>{this.setState({viewAditional:4})}}>
                                        <i className="flaticon-users"></i> Свързани клиенти
                                    </button>  
                                </div>
                            </Grid> 
                        </>}   
                    </Grid>    
                    {viewAditional===0&&
                    <>
                    <form  className='form' noValidate onSubmit={this.onSubmit} className="padding">
                        <FormHolder   
                            userData={clientData}
                            clients={clients}
                            editMode={editMode}
                            handleData={this.handleData}
                            getClientAutocomplete={this.getClientAutocomplete}
                            errors={errors}
                        /> 
                        <Grid container spacing={2}>   
                            <Grid container item md={4} xs={12} >    
                                {roleId!==19&&<button type="submit" className="btn btn-info full-width">
                                    <i className="flaticon2-add-square"></i>Запази
                                </button>}   
                            </Grid>  
                        </Grid>  
                    </form>
                    </>  
                    }
                          
                    {viewAditional===1&&
                        <Grid container className="padding">  
                            <ClientContacts clientId={this.props.match.params._id}  /> 
                        </Grid>
                    }  
                    {viewAditional===2&&
                        <Grid container className="padding">  
                            <ClientHistory clientId={this.props.match.params._id}  /> 
                        </Grid>
                    }  
                    {viewAditional===3&&
                        <Grid container className="padding">  
                            <LizingCompanies clientId={this.props.match.params._id}  /> 
                        </Grid>
                    }  
                    {viewAditional===4&&
                        <Grid container className="padding">  
                            <ConnectedClients clientId={this.props.match.params._id}  /> 
                        </Grid>
                    }  
                    {/* {viewAditional===5&&
                        <Grid container className="padding">  
                            <ClientOffers clientId={this.props.match.params._id}  /> 
                        </Grid>
                    }   */}
                </Card> 
            </React.Fragment>
         );
    }
}

const mapStateToProps = (state, props) => {   
    return{
        contact:state.contacts.singleContact, 
        client:state.clients.singleClient,
        clients:state.clients.clientsAutoComplete,
        
    }
}

export default connect(mapStateToProps, {emptyClient,fetchClients, fetchSingleContact, fetchSingleContact, fetchSingleClient,fetchClientsAutoComplete,editClient,createClient })(EditClient);  
 