import { apiUrl} from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions"; 


export const fetchRoles = () => dispatch => {    
    return fetch(apiUrl+'roles', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {  
        const {status,data, message} = response;    
        if (status === "success") { 
            dispatch({
                type:'FETCH_ROLES',
                payload:data
            })
        } 
        return response
    });  
}
export const editRole = (role,id) => dispatch => {  
    
    return fetch(apiUrl+'roles/'+id, { 
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(role)
     }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
     })
    .then(response => {    
        const {status, data, message} = response; 
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'EDIT_ROLE',
                payload:data
            })
        } 
        return response
    });  
}

export const createRole = (role) => dispatch => {  
    
    return fetch(apiUrl+'roles', { 
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(role)
     }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
     })
    .then(response => {    
        const {status, data, message} = response; 
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'CREATE_ROLE',
                payload:data
            })
        } 
        return response
    });  
}
export const editFormPermitions = (role, formId) => dispatch => {  
    
    return fetch(apiUrl+'roles/form-fields/'+ formId, { 
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(role)
     }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
     })
    .then(response => {    
        const {status, data, message} = response; 
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'CREATE_ROLE',
                payload:data
            })
        } 
        return response
    });  
}

export const fetchRolePermitions = (id) => dispatch => {   
    return fetch(apiUrl+'roles/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        return response
    });  
}

export const fetchPermitionsList = () => dispatch => {   
    return fetch(apiUrl+'permissions', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        return response
    });  
}

export const fetchFormFilelds = (id) => dispatch => {   
    return fetch(apiUrl+'form-fields/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        return response
    });  
} 

export const fetchFormUserPermissions = () => dispatch => { 
    return fetch(apiUrl+'users/form-fields/1', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_FORM_USER_PERMISSIONS',
                payload:data
            })
        } 
        return response
    }); 
}

export const fetchFormFileldsForRole = (id, userId) => dispatch => {   
    return fetch(apiUrl+'roles/form-fields/'+id+'/'+userId, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        return response
    });  
}