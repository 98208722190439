import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";   
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';  


import {fetchSingleBrand , createBrand, editBrand} from '../../../_actions/brandsActions'

class EditBrand extends Component {
    state = { 
        data:{
            id:'',
            name:'',
            image:'',
            base64:'',
            accessories:[{'name':'','price':''}],
            links:[{'name':''}], 
        },
        roleId: JSON.parse(localStorage.getItem('roleId')),
        editMode:false,  
        errors:[]
    }

    componentDidMount(){   
        if (this.props.match.params._id) {
            this.setState({editMode:true})
            this.props.fetchSingleBrand(this.props.match.params._id);
        } 
    }

    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.brand !== this.props.brand){
            const {id,name,image, accessories,links, base64} = this.props.brand
            if (accessories.length<1) {
                if (links.length<1) { 
                    this.setState ({
                        data:{
                            ...this.state.data, 
                            id,name, image, links:[{'name':''}], accessories:[{'name':'','price':''}], base64
                        } 
                    }) 
                }else{ 
                    this.setState ({
                        data:{
                            ...this.state.data, 
                            id,name, image, links, accessories:[{'name':'','price':''}], base64
                        } 
                    }) 
                }
            }else{
                if (links.length<1) { 
                    this.setState ({
                        data:{
                            ...this.state.data, 
                            id,name, image, links:[{'name':''}], accessories, base64
                        } 
                    }) 
                }else{ 
                    this.setState ({
                        data:{
                            ...this.state.data, 
                            id,name, image, links, accessories, base64
                        } 
                    }) 
                }
                this.setState ({
                    data:{
                        ...this.state.data, 
                        id,name, image,links, accessories, base64
                    } 
                })   
            }  
        }
    }
    
    onChange =(event)=>{
        const {name, value} = event.target;
        this.setState({ 
            data:{
                ...this.state.data,
                [name]:value  
            }

        })
    }

    handleRemove = (i) => {
        const list = [...this.state.data.accessories];
        list.splice(i, 1);
        this.setState({
            data:{
                ...this.state.data,
                accessories:list
            } 
        })
    }

    onChangeDinamic = (e, index) => { 
        const { name, value } = e.target;
        const list = [...this.state.data.accessories];
        list[index][name] = value; 
        this.setState({
            data:{
                ...this.state.data,
                accessories:list
            } 
        })
    };

    handleAdd = ()=>{
        this.setState({ 
            data:{
                ...this.state.data,
                accessories:[
                    ...this.state.data.accessories, 
                    {'name':'','price':''}
                ] 
            }
           
        })
    }
    handleRemoveLink = (i) => {
        const list = [...this.state.data.links];
        list.splice(i, 1);
        this.setState({
            data:{
                ...this.state.data,
                links:list
            } 
        })
    }

    onChangeDinamicLink = (e, index) => { 
        const { name, value } = e.target;
        const list = [...this.state.data.links];
        list[index][name] = value; 
        this.setState({
            data:{
                ...this.state.data,
                links:list
            } 
        })
    };

    handleAddLink = ()=>{
        this.setState({ 
            data:{
                ...this.state.data,
                links:[
                    ...this.state.data.links, 
                    {'name':''}
                ] 
            }
           
        })
    }

    handlePhotoChange = (event) => { 
        const {name}= event.target;
        let reader = new FileReader();
        let file = event.target.files[0]; 
       
        reader.readAsDataURL( file);
        reader.onload = () => { 
            this.setState({
                data:{
                    ...this.state.data,
                    [name]: reader.result
                }
            })   
        };
    }

    removePhoto = (name) =>{ 
        this.setState({
            data:{
                ...this.state.data,
                [name]: ''
            }
        })  
    }

    onSubmit = (event) =>{
        event.preventDefault();
        const {editMode} = this.state;
        if (editMode) {
            this.props.editBrand(this.state.data).then(data => {
            
                
                if(data.status==="success"){
                    this.setState({errors:[]}) 
                    this.props.history.push('/dashboard/brands')
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }   
            }) 
        }else{
            this.props.createBrand(this.state.data).then(data => { 
                if(data.status==="success"){ 
                    this.setState({
                        data:{
                            ...this.state.data,
                            id:'',name:'', image:'',base64:'',accessories:[{'name':'','price':''}]
                        },
                        errors:[]
                    })
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }  
            }) 
        }
    }
    render() { 

        const {editMode, errors, roleId}=this.state;
        const {name, accessories, base64, image, links}=this.state.data;
  
        const pageTitle = editMode ? 'Редактиране на Марка' : 'Създаване на марка';
        return ( 
            <>
             <Card>
                <Grid container className="section-title-wrapper padding"> 
                    <Grid container item md={8} xs={8} alignItems="center"> 
                        <h3 className="section-title">
                            <i className="flaticon2-gear"></i> {pageTitle} 
                        </h3>
                    </Grid>    
                    <Grid container item md={4} xs={4} alignItems="center" justify="flex-end">  
                        <Link  
                            className="btn btn-secondary"
                            to="/dashboard/brands" 
                            ><i className="flaticon2-circular-arrow"/>
                            <span>Назад</span> 
                        </Link>   
                    </Grid>
                </Grid>  
                    <form  className='form' noValidate onSubmit={this.onSubmit} className="padding">
                        <Grid container spacing={3}>  
                            <Grid item md={4} xs={12}>    
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fas fa-copyright"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Име на Марката"
                                    name="name"
                                    value={name}
                                    error ={errors.name?true:false} 
                                    helperText={errors.name} 
                                    onChange={this.onChange}  
                                />  
                            </Grid>   
                        </Grid>   
                        <Grid container spacing={3}>  
                            <Grid item md={6} xs={12}> 
                                <h3 className="section-title">
                                    Аксесоари за марката:
                                </h3>
                                <br/> 
                                {accessories.map((item, i) =>{
                                    return(  
                                    <div className="aditional-accessory" key={i}>
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                                            InputLabelProps={{shrink: true}} 
                                            required 
                                            name="name"
                                            margin="normal" 
                                            variant="outlined"    
                                            label="Име на аксесоара:"
                                            value={item.name}
                                            onChange={e=>this.onChangeDinamic(e, i)}  
                                        />  
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small number" 
                                            InputLabelProps={{shrink: true}} 
                                            label="Цена лв."
                                            type="number"
                                            margin="normal" 
                                            variant="outlined"    
                                            name="price"
                                            value={item.price}
                                            onChange={e=>this.onChangeDinamic(e, i)}  
                                        />  
                                        <div className="btn-box">
                                            {accessories.length !== 1 &&
                                            <div className='delete' onClick={()=>this.handleRemove(i)}>
                                                <i className="flaticon2-delete"></i>  
                                            </div>}
                                            {accessories.length - 1 === i && 
                                            <div className='add' onClick={this.handleAdd}>
                                                +
                                            </div>}
                                        </div>
                                    
                                    </div>
                                    )
                                })}
                                <br/><br/><br/> 
                                <h3 className="section-title">
                                    Линкове:
                                </h3>
                                <br/> 
                                {links.map((item, i) =>{
                                    return(  
                                    <div className="aditional-accessory" key={i}>
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                                            InputLabelProps={{shrink: true}} 
                                            required 
                                            name="name"
                                            margin="normal" 
                                            variant="outlined"    
                                            label="Линк:"
                                            value={item.name}
                                            onChange={e=>this.onChangeDinamicLink(e, i)}  
                                        />   
                                        <div className="btn-box">
                                            {links.length !== 1 &&
                                            <div className='delete' onClick={()=>this.handleRemoveLink(i)}>
                                                <i className="flaticon2-delete"></i>  
                                            </div>}
                                            {links.length - 1 === i && 
                                            <div className='add' onClick={this.handleAddLink}>
                                                +
                                            </div>}
                                        </div>
                                    
                                    </div>
                                    )
                                })}
                                
                            </Grid>   
                            <Grid item md={1} xs={12}> 
                            </Grid>   

                        {image&&<Grid item md={5} xs={12}> 
                            <h3 className="section-title">
                                Снимка на аксесоари на марката:
                            </h3>
                            <br/> 
                            <div className="image-wrapper">
                                <div onClick={() => this.removePhoto('image')}  className="delete"><i className="flaticon2-delete"></i></div>
                                <img className='full-width' src={image} alt=""/>
                            </div>
                            {!image &&<div className="image-holder">
                                <p>Качете снимка</p>
                                <input 
                                    accept="image/*" 
                                    type="file"
                                    id="image" 
                                    name="image" 
                                    onChange = {this.handlePhotoChange}
                                    />
                            </div>} 
                            </Grid>  }    
                        {image.length<1&&<Grid item md={5} xs={12}> 
                            <h3 className="section-title">
                                Снимка на аксесоари на марката:
                            </h3>
                            <br/> 
                            {base64&&<div className="image-wrapper">
                                <div onClick={() => this.removePhoto('base64')}  className="delete"><i className="flaticon2-delete"></i></div>
                                <img className='full-width' src={base64} alt=""/>
                            </div>}
                            {!base64 &&<div className="image-holder">
                                <p>Качете снимка </p>
                                <i> 1300px на 1000px и не по-големи от 1 mb</i>
                                <input 
                                    accept="image/*" 
                                    type="file"
                                    id="image" 
                                    name="base64" 
                                    onChange = {this.handlePhotoChange}
                                    />
                            </div>} 
                            </Grid> }   
                        </Grid>
                        <Grid container spacing={2} >   
                            <Grid container item md={4} xs={12} >    
                                {roleId!==19&&<button type="submit" className="btn btn-info full-width">
                                    <i className="flaticon2-add-square"></i>{editMode ? 'Запази' : 'Създай'}
                                </button>}  
                            </Grid>  
                        </Grid> 
                    </form> 
                </Card> 
            </>
        );
    }
}

const mapStateToProps = (state, props) => {  
    return{ 
        brand:state.brands.singleBrand,  
    } 
}
export default connect(mapStateToProps, {fetchSingleBrand, createBrand, editBrand })(EditBrand);
