const initialState = {
    items:[],
    singleContact:{},
    contactsAutoComplete:[],
    relatedContactCompanies:[]
}

export default function (state = initialState, action){  
    const {type, payload} = action;  
    switch(type){
        case 'FETCH_CONTACTS':  
            return{
                ...state,
                items:payload 
            }  
        case 'FETCH_SINGLE_CONTACT':   
            return{
                ...state,
                singleContact:payload 
            } 
        case 'FETCH_CONTACTS_AUTOCOMPLETE':  
            return{
                ...state,
                contactsAutoComplete:payload 
            } 
        case 'FETCH_RELATED_CONTACTS_COMPANIES':  
            return{
                ...state,
                relatedContactCompanies:payload 
            } 
        case 'CREATE_CONTACT':
            return { 
                ...state,
                items: [...state.items, payload]
            } 
        case 'EDIT_CONTACT': 
            return{ ...state, 
                items:state.items.map(contact => ( contact.id === payload.id ? payload : contact ))
            }  
        default:
            return state;
    }
}