import React from 'react'; 
import{Link} from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip';

export const options = { 
    sortOrder: {
        name: 'id',
        direction: 'desc'
    },
    confirmFilters: true,  
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => { 
        return (
            <div style={{ marginTop: '40px' }}>
                <button className="btn btn-info small" onClick={()=>applyNewFilters()}>Приложи филтрите</button>
            </div>
        );
    },
    print: false,
    download: true,
    downloadOptions: {
        filename: 'CarsForSale.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
        } 
    }, 
    setRowProps: (row) => {    
        if(row[2]==="Промоционален"){
            return {  
                style: {
                    background: 'red',
                    color:'#fff',
                }
            }
        }
    },
    viewColumns: true,
    filter:false, 
    selectableRows: "none",   
    textLabels: {
        body: {
            noMatch: "Няма намерени резултати",
            toolTip: "Сортирай",
            columnHeaderTooltip: column => `Сортирай по ${column.label}`
        },
        pagination: {
            next: "Следваща страница",
            previous: "Предишна страница",
            rowsPerPage: "Брой записи на страница:",
            displayRows: "от",
        },
        toolbar: {
            search: "Търси",
            downloadCsv: "Download CSV",
            print: "Принтирай",
            viewColumns: "Колони",
            filterTable: "Филтри",
        },
        filter: {
            all: "Всички",
            title: "Филтри",
            reset: "Откажи",
        },
        viewColumns: {
            title: "Покажи Колони",
            titleAria: "Покажи/Скрии Колони",
        },
        selectedRows: {
            text: "запис/и са селектирани за изтриване",
            delete: "Изтриване",
            deleteAria: "Изтриите избраните записи",
        },
    }
}; 
export const fileds = [ 
    {label:"ID",name:'id'},
    {label:"ТИП",name:'type'},
    {label:"Статус",name:'status'},
    {label:"Марка",name:'brand'},
    {label:"Модел",name:'model'},
    {label:"Инв. номер",name:'inventoryNumber'},
    {label:"Шаси номер",name:'vin'},
    {label:"Двигател",name:'engine'},
    {label:"Тип двигател",name:'engineType'},
    {label:"Цвят",name:'externalColorDescription'},
    {label:"Ниво на оборудване",name:'commercialGrade'},
    // {label:"Код",name:'keyCode'},
    {label:"Двигател Мощност",name:'kw'},
    {label:"Цена",name:'listPrice'},
    {label:"Гориво",name:'fuelType'},
    {label:"Скоростна кутия",name:'transmission'}, 
    {label:"Модел ID",name:'modelId'},
    {label:"Марка ID",name:'brandId'},
    {label:"DLVRD cur. Year",name:'dlvrd'},
    {label:"Ord. request",name:'ordRequest'},
    {label:"Factory Code Model / Engine MTY1", name:'mtyOne'},
    {label:"Factory Code Model / Engine MTY2", name:'mtyTwo'},
    {label:"NOT DLVRD",name:'notDlvrd'},
    {label:"equipmentCode",name:'equipmentCode'},
    {label:"Месец за доставка в Антверпен, Белгия",name:'monthDelivery'},
    {label:"order",name:'order'},
    {label:"External color code",name:'externalColor'},
    {label:"Vehicle stay in SM stock",name:'vehicleStayInStock'},
    {label:"idEmotive",name:'idEmotive'},
    {label:"Factory Grade",name:'factoryGrade'},
    {label:"Пристигнал в София",name:'comeInSofia'},
    {label:"Internal color",name:'internalColor'},
    {label:"Location",name:'location'},
    {label:"body",name:'body'},
    {label:"body Type",name:'bodyType'},
    // {label:"optional Equipment",name:'vehicle_optionalEquipment'},
    {label:"оriginal status",name:'originalStatus'},
    {label:"mixed Name",name:'mixedName'},
    // {label:"Status Name",name:'statusName'},
    // {label:"Type Name",name:'typeName'},
    // {label:"technical salesCarId",name:'technical_salesCarId'},
    {label:"driving Wheels",name:'drivingWheels'},
    {label:"Максимална мощност",name:'hp'},
    {label:"Работен обем",name:'displacement'},
    {label:"цилиндри",name:'cylinders'},
    {label:"брой седалки",name:'seats'},
    {label:"дължина",name:'lenght'},
    {label:"ширина",name:'width'},
    {label:"височина",name:'height'},
    {label:"живо тегло/полезен товар",name:'liveWeight'},
    {label:"колесна база",name:'wheelBase'},
    {label:"Максимална Мощност (Документ)",name:'maxPower'},
    {label:"Максимална Мощност",name:'maxPowerRpm'},
    {label:"Максимален въртящ момент (Документ)",name:'maxTorgue'},
    {label:"макс. въртящ момент",name:'maxTorqueRpm'},
    {label:"макс. въртящ момент",name:'maxTorqueNm'},
    {label:"ускорение",name:'acceleration'},
    {label:"Комбиниран разход",name:'fuelConsumption'},
    {label:"обем на резервоара (Л.)",name:'tankCapacity'},
    {label:"emission/Ниво на вредни емисии CO2 (комбиниран цикъл)",name:'emission'},
    {label:"Екологична норма",name:'euStandard'},
    {label:"Обем на багажника, литри",name:'luggageCapacity'},
    {label:"максимална скорост",name:'maxSpeed'},
    // {label:"salesCarId Клиент",name:'customer_salesCarId'},
    // {label:"Клиент",name:'client'},
    // {label:"E-mail",name:'email'},
    // {label:"Населено място",name:'address'},
    // {label:"Телефон за контакт",name:'phone'},
    // {label:"Адрес",name:'populatedLocation'},
    // {label:"Забележка относно типа на работа",name:'customer_note'},
    // {label:"logistic Type Name",name:'logistic_typeName'},
    // {label:"logistic salesCarId",name:'logistic_salesCarId'},
    // {label:"Начин на плащане/ лизингова компания",name:'priceType'},
    // {label:"Contract",name:'contractNumber'}, 
    // {label:"Фактура към клиента",name:'invoiceNumberToClient'},
    // {label:"Дата на фактура към клиента",name:'invoiceDateToClient'},
    // {label:"Капаро за поръчка на автомобил",name:'depositForCarOrder'},
    // {label:"Дата на поръчка на автомобила",name:'dateOfOrderOfTheCar'},
    // {label:"Номер на заявка на автомобила",name:'numberOfOrderOfTheCar'},
    // {label:"Delivered to the customer",name:'deliveredToTheCustomer'},
    // {label:"Reg. Number",name:'regNumber'},
    // {label:"Road assistant card",name:'roarAssistantCard'},
    // {label:"Застрахователна компания",name:'insuranceCompany'},
    // {label:"Customer payment",name:'customerPayment'},
    // {label:"Dealer",name:'dealer'},
    // {label:"Доставна фактура EUR",name:'invoiceMagEur'},
    // {label:"Дата на Доставна фактура",name:'invoiceMagDate'},
    // {label:"Номер на Доставна фактура",name:'invoiceMagNumber'},
    // {label:"Дни след фактура",name:'daysAfterInvoice'},
    // {label:"deliveryPriceEur",name:'deliveryPriceEur'},
    // {label:"ДДС %",name:'vat'},
    // {label:"Доставна цена с ДДС лв",name:'deliveryPriceWithVat'},
    // {label:"Margin by list price w/o VAT EUR in %",name:'marginByListPriceEur'},
    // {label:"Margin by retail price w/o VAT EUR in %",name:'marginByRetailPriceEur'},
    // {label:"Margin by retail price w/o VAT BGN",name:'marginByRetailPriceBgn'},
    // {label:"Марж след max отстъпка (без ддс) BGN",name:'marginAfterMaxDiscountBgn'},
    // {label:"Марж след max отстъпка %",name:'marginAfterMaxDiscount'},
    // {label:"Фактура стойност към дилъра с ДДС BGN",name:'invoicePriceToDealerWithVatBgn'},
    // {label:"Дилърска комисионна с ДДС BGN",name:'dealerCommissionWithVatBgn'},
    // {label:"Dealer bonus w/o VAT BGN",name:'dealerBonusWithVatBgn'},
    // {label:"Dealer bonus with VAT EUR",name:'dealerBonusEur'},
    // {label:"Promotion w/o VAT",name:'promotionBgn'},
    // {label:"Paid advance",name:'paidAdvice'},
    // {label:"Дата на отчитане към MAG",name:'creditNoteMagDate'},
    // {label:"Stay in stock-sold cars days",name:'stayInStockSoldCars'},
    // {label:"logistic type",name:'logistic_type'},
    // {label:"administration salesCarId",name:'administration_salesCarId'},
    // {label:"Responsible salesman",name:'responsibleSalesman'}, 
    // {label:"All inclusive card/CAR CORNER",name:'allInclusiveCard'},
    // {label:"Дата на заявка СОС",name:'dateSosRequires'},
    // {label:"registrationTypeNote",name:'registrationTypeNote'},
    // {label:"Продажна цена с ДДС",name:'salesPriceContractWithVat'},
    // {label:"Mаксимална отстъпка",name:'maxDiscount'},
    // {label:"Mаксимално възможна отстъпка с ддс",name:'maxDiscountWithVat'},
    // {label:"Mаксимална цена след отстъпка с ддс",name:'maxPriceAfterDiscountWithVat'},
    // {label:"Забележка",name:'administration_note'},
    // {label:"pricePerPriceListWithVat",name:'pricePerPriceListWithVat'},
    // {label:"Гаранция",name:'guarantee'},
    // {label:"editedAt",name:'editedAt'},
    // {label:"Тип гаранция",name:'guaranteeType'},
    // {label:"price salesCarId",name:'price_salesCarId'},
    {label:"Препоръчителна цена към клиент",name:'priceB2c'},
    {label:"Минимална цена",name:'bottomPrice'},
    {label:"Цена за дилър",name:'pricePartner'},
    {label:"Цена за собствен склад",name:'priceB2b'},
    {label:"Промоционална цена",name:'promotion'},
    {label:"Подаръци към клиента с ДДС, доставна цена",name:'giftsForClient'},
    {label:"Oтстъпка",name:'discountBgn'},
    {label:"Oтстъпка с ДДС",name:'discountWithVatBgn'},
    {label:"Персонален бонус за търговеца",name:'personalBonusForTrader'},
    // {label:"optionalEquipment",name:'optionalEquipment'},
    // {label:"standardEquipment",name:'standardEquipment'}, 
]
const filedsColumns = fileds.map(item=>{
   
    return ({
        label:item.label, 
        name:item.name, 
        options: {
            filter: false, 
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {  
                if (value) {
                    if (item.name==='vin') { 
                        return (
                            <Tooltip  
                                PopperProps={{
                                    disablePortal: true,
                                }} 
                                placement="top"
                                title={
                                    <React.Fragment>
                                    <p>{value}</p> 
                                    </React.Fragment>
                                }
                            >
                            <p>{value.substr(0, 10)}...</p>
                            </Tooltip >
                          )
                    }else{
                        return value
                    } 
                }else{
                    return 'няма инфо'
                }
                
            }
        }
    })
})
export const columns =filedsColumns;

// export const columns = [ 
//     {
//         label: "ID:",
//         name: "id",
//         options: {
//             filter: false,
//             sort: true, 
//            }
//     },  
//     {
//         label: "ТИП:",
//         name: "type",
//         options: {
//             filter: true,
//             sort: true 
//         }
//     },
//     {
//         label: "Статус:",
//         name: "status",
//         options: {
//             filter: true,
//             sort: true
//         }
//     }, 
//     {
//         label: "Марка:",
//         name: "brand",
//         options: {
//             filter: true,
//             sort: true,
//         }
//     },    
//     {
//         label: "Модел:",
//         name: "model",
//         options: {
//             filter: true,
//             sort: true,
//         }
//     },    
//     {
//         label: "Инв. номер:",
//         name: "inventoryNumber",
//         options: {
//             filter: true,
//             sort: true,
//         }
//     },    
//     {
//         label: "Шаси номер:",
//         name: "vin",
//         options: {
//             filter: true,
//             sort: true,
//         }
//     },    
//     {
//         label: "Код:",
//         name: "keyCode",
//         options: {
//             filter: true,
//             sort: true,
//         }
//     },    
//     {
//         label: "Двигател:",
//         name: "engine",
//         options: {
//             filter: true,
//             sort: false,
//         }
//     },   
//     {
//         label: "Тип двигател:",
//         name: "engineType",
//         options: {
//             filter: true,
//             sort: true,
//         }
//     },     
//     {
//         label: "Двигател Мощност:",
//         name: "kw",
//         options: {
//             filter: false,
//             sort: false,
//         }
//     },   
//     {
//         label: "Гориво:",
//         name: "fuelType",
//         options: {
//             filter: true,
//             sort: true,
//         }
//     },    
//     {
//         label: "Скоростна кутия:",
//         name: "transmission",
//         options: {
//             filter: true,
//             sort: true,
//         }
//     },    
//     {
//         label: "Цена:",
//         name: "listPrice",
//         options: {
//             filter: true,
//             sort: true,
//         }
//     },   
//     {
//         label: "Цвят:",
//         name: "externalColorDescription",
//         options: {
//             filter: true,
//             sort: true,
//         }
//     },    
//     {
//         label: "Ниво на оборудване:",
//         name: "commercialGrade",
//         options: {
//             filter: true,
//             sort: false,
//         }
//     },      
     
// ];

 