import React from 'react';  

export const options = { 
    sortOrder: {
        name: 'id',
        direction: 'desc'
    },
    confirmFilters: true,  
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => { 
        return (
            <div style={{ marginTop: '40px' }}>
                <button className="btn btn-info small" onClick={()=>applyNewFilters()}>Приложи филтрите</button>
            </div>
        );
    },
    print: false,
    download: true,
    downloadOptions: {
        filename: 'Companies.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
        } 
    },
    viewColumns: true,
    filter:false, 
    selectableRows: "none",     
    textLabels: {
        body: {
            noMatch: "Няма намерени резултати",
            toolTip: "Сортирай",
            columnHeaderTooltip: column => `Сортирай по ${column.label}`
        },
        pagination: {
            next: "Следваща страница",
            previous: "Предишна страница",
            rowsPerPage: "Брой записи на страница:",
            displayRows: "от",
        },
        toolbar: {
            search: "Търси",
            downloadCsv: "Download CSV",
            print: "Принтирай",
            viewColumns: "Колони",
            filterTable: "Филтри",
        },
        filter: {
            all: "Всички",
            title: "Филтри",
            reset: "Откажи",
        },
        viewColumns: {
            title: "Покажи Колони",
            titleAria: "Покажи/Скрии Колони",
        },
        selectedRows: {
            text: "запис/и са селектирани за изтриване",
            delete: "Изтриване",
            deleteAria: "Изтриите избраните записи",
        },
    }
}; 

export const columns = [ 
    {
        label: "ID:",
        name: "id",
        options: {
            filter: false,
            sort: true, 
           }
    },
    {
        label: "Име:",
        name: "name",
        options: {
            filter: false,
            sort: true,
           }
    },
    {
        label: "ЕИК:",
        name: "eik",
        options: {
            filter: false,
            sort: true,
           }
    },  
    {
        label: "МОЛ:",
        name: "mol",
        options: {
            filter: false,
            sort: true,
        }
    }, 
    {
        label: "Адрес:",
        name: "address",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        label: "ДДС номер:",
        name: "vatNumber",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        label: "Пълномощник:",
        name: "hasNotary",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                if (value) {
                    return 'Да' 
                }else{
                    return 'Не'  
                }
            }
        }
    } 
];
