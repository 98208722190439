import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {connect} from 'react-redux'

import MUIDataTable from "mui-datatables"; 
import Grid from '@material-ui/core/Grid';
import {columns, options} from "../../../helpers/viewClientHistory";   
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns";  

import {fetchClientsLogs} from '../../../_actions/clientsActions'
   
 
class ClientHistory extends Component {
    state={
        options:{},
        columns:[]
     }

    componentDidMount(){ 
        this.setState({
            options: storeColumnsFilters('historyTable', options),
            columns: getStoredColumnsFilters('historyTable', columns)
        })

        if (this.props.match.params._id) {
            this.props.fetchClientsLogs(this.props.match.params._id);
        }
    }

    render() { 
        const{clientLog}=this.props; 
        const {options, columns} = this.state;  

        return (  
            <>  
                <Grid container spacing={3}> 
                    <Grid item md={12} xs={12}>   
                        <MUIDataTable
                            title={"Иcтория:"}
                            className='viewHistoryTable'
                            data={clientLog}
                            columns={columns} 
                            options={{...options}}  
                        />  
                    </Grid>    
                </Grid>    
            </>
        );
    }
}

const mapStateToProps = (state, props) => {   
    return{ 
        clientLog:state.clients.clientLog,  
    } 
}
export default withRouter(connect(mapStateToProps, {fetchClientsLogs })(ClientHistory)); 
 