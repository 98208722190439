import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';
import {Link, Redirect} from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography'; 
import Container from '@material-ui/core/Container';

import {connect} from 'react-redux'
import {login} from '../_actions/authActions'

 
class LogIn extends Component {
    state = { 
        email:'',
        password:'',
        errors:[]
     }
   
     onChange = (event) =>{
        const {name, value}=event.target;
        this.setState({[name]:value})
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.props.login(this.state).then(data => {
            if(data.errors) this.setState({errors:data.errors}) 
        }) 
    }
    render() { 
        if (this.props.isAuthenticated) {
            return <Redirect to="/dashboard" /> 
        }

        const {email, password, errors} = this.state
        return ( 
            <div className="LogIn">
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className='form-wrapper'>
                    <Avatar className='avatar'>
                        <LockOutlinedIcon />
                    </Avatar>
                  
                    <Typography component="h1" variant="h5">
                        Вход
                    </Typography>
                    <div className="separator"/>
                    <form  className='form' noValidate onSubmit={this.onSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            className="mb-20"
                            required
                            fullWidth
                            id="email"
                            label="Е-мейл"
                            name="email"
                            type="email"
                            error ={errors.email? true:false} 
                            helperText={errors.email}
                            onChange={this.onChange}
                        />
                     
                        <TextField
                            variant="outlined"
                            margin="normal"
                            className="mb-20"
                            required
                            fullWidth
                            name="password"
                            label="Парола"
                            type="password"
                            id="password"
                            error ={errors.password ? true:false}
                            helperText={errors.password}
                            onChange={this.onChange}
                        /> 
                        <button
                            type="submit" 
                            variant="contained"
                            color="primary"
                            className='btn btn-info full-width text-center'
                        ><i className="flaticon2-telegram-logo"></i>
                            Влез
                        </button>
                        {/* <Link to='/dashboard' 
                            variant="contained"
                            color="primary"
                            className='btn btn-info full-width text-center'
                        ><i className="flaticon2-telegram-logo"></i>
                            Влез
                        </Link> */}
                    </form> 
                </div> 
            </Container>
            </div> 
         );
    }
}
const mapStateToProps = state => {  
    return({isAuthenticated:state.auth.isAuthenticated })
}


export default connect(mapStateToProps, {login})(LogIn);