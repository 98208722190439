import { apiUrl} from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions"; 


export const fetchOrders = () => dispatch => { 
    return fetch(apiUrl+'orders', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status,data} = response;
        if (status === "success") {
    
            const payload = data.map(item=>{
                return (
                    {
                        id:item.id,
                        orderDate:item.orderDate,
                        traderName:item.userInfo.name ? item.userInfo.name:'',
                        client:item.client,
                        contactName:item.contactName,
                        navId:item.navId? 'Да':'Не',
                        agreementPdf:item.agreementPdf,
                    }
                )
            })
            dispatch({
                type:'FETCH_ORDERS',
                payload:payload
            })
        } 
        return response
    });  
}

export const sendOrderIdToNav = (id) => dispatch => { 
    return fetch(apiUrl+'orders/send-nav/'+id, {
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status,data, message} = response;
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
        }
        return response
    });  
}

export const fetchSingleOrder = (id) => dispatch => { 
    return fetch(apiUrl+'orders/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_SINGLE_ORDER',
                payload:data
            })
        } 
        return response
    }); 
}

export const fetchLeasingCompanies = (name, type) => dispatch => {  
    return fetch(apiUrl+'clients/autocomplete?type=2', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") { 
            dispatch({
                type:'FETCH_ORDERS_LEASING_COMPANIES',
                payload:data
            })
        } 
        return response
    }); 
}

export const setOrderInProgress = (obj, data) => dispatch => {   
    dispatch({
        type:'ORDER_IN_PROGRESS',
        payload:{obj, data}
    }) 
}
export const clearOrderInProgress = () => dispatch => {   
    dispatch({
        type:'CLEAR_ORDER_IN_PROGRESS',
        payload:{}
    }) 
}

export const createOrder = (order) => dispatch => {  
    
    return fetch(apiUrl+'orders', { 
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(order)
     }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
     })
    .then(response => {    
        const {status, data, message} = response; 
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'CREATE_ORDER',
                payload:data
            })
        } 
        return response
    }); 
 
}

export const editOrder = (order, id) => dispatch => {   
    return fetch(apiUrl+'orders/'+id, {
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(order)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, data, message} = response; 
        if (status === "success") { 
            dispatch(setMessage(message));
            dispatch(removeMessage()); 
        } 
        return response
    });   
}

export const updateOrder = (obj, data) => dispatch => {   
    
    dispatch({
        type:'EDIT_ORDER',
        payload:{obj, data}
    }) 
}
export const clearOrder = (obj, data) => dispatch => {   
    
    dispatch({
        type:'CLEAR_ORDER',
        payload:''
    }) 
}

export const setOrderDocument = (id,val) => dispatch => {    
    let formData = new FormData(); 
    formData.append('file', val );  

    return fetch(apiUrl+'orders/upload-order/'+id, {
        method: 'POST', 
        headers:{  
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: formData
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        return response
    });   
}

export const getOrderDocument = (id) => dispatch => {     

    return fetch(apiUrl+'orders/order-download/'+id, {
        method: 'GET', 
        headers:{  
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        }, 
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        return response
    });   
}