import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";   
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';  

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {fetchBrands} from '../../../_actions/brandsActions'
import {fetchSingleModel, createModel, editModel, setModelPhoto, removeModelPhoto} from '../../../_actions/modelsActions'

class EditModel extends Component {
    state = { 
        data:{
            name:'',
            brandId:'',
            base64:'',
            image:'',
            carImages:[],
            carBase64:'',
            accessories:[{'name':'','price':''}],
            lightTruck:false, 
            luggageCapacity:'',
        },
        editMode:false,  
        roleId: JSON.parse(localStorage.getItem('roleId')),
        errors:[]
    }

    componentDidMount(){   
        this.props.fetchBrands() 
        
        if (this.props.match.params._id) {
            this.setState({editMode:true})
            this.props.fetchSingleModel(this.props.match.params._id);
        }
        const query = new URLSearchParams(this.props.location.search);
        const brandId = query.get('brand_Id')
        if (brandId) {  
            this.setState({ data:{...this.state.data, brandId} })
        }
    }

    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.model !== this.props.model){ 
            const {id, name, image, accessories, base64, brandId, carImages,lightTruck, carBase64, luggageCapacity} = this.props.model
            const carImagesNew = carImages.length===0 ? [] : carImages
    
            if (accessories.length<1) {
                this.setState ({
                    data:{
                        ...this.state.data, 
                        id, name, image, accessories:[{'name':'','price':''}], base64, brandId, 
                        carImages:carImagesNew,lightTruck, carBase64, luggageCapacity
                    } 
                }) 
                
            }else{
                this.setState ({
                    data:{
                        ...this.state.data, 
                        id,name,image, accessories, base64,lightTruck, brandId, carImages:carImagesNew, carBase64,luggageCapacity
                    } 
                })   
            } 
        }
    }

    handleRemove = (i) => {
        const list = [...this.state.data.accessories];
        list.splice(i, 1);
        this.setState({
            data:{
                ...this.state.data,
                accessories:list
            } 
        })
    }

    onChangeDinamic = (e, index) => { 
        const { name, value } = e.target;
        const list = [...this.state.data.accessories];
        list[index][name] = value; 
        this.setState({
            data:{
                ...this.state.data,
                accessories:list
            } 
        })
    };

    handleAdd = ()=>{
        this.setState({ 
            data:{
                ...this.state.data,
                accessories:[
                    ...this.state.data.accessories, 
                    {'name':'','price':''}
                ] 
            }
           
        })
    }

    handlePhotoChange = (event) => { 
        const {name}= event.target;
        let reader = new FileReader();
        let file = event.target.files[0]; 
       
        reader.readAsDataURL( file);
        reader.onload = () => { 
            this.setState({
                data:{
                    ...this.state.data,
                    [name]: reader.result
                }
            })   
        };
    }

    removePhoto = (name)=>{
        this.setState({
            data:{
                ...this.state.data,
                [name]: ''
            }
        })  
    }

    onChange = (event) =>{
        const {value, name} = event.target;
        this.setState({
            data:{
                ...this.state.data,
                [name]: value
            }
        })  
    }

    onSubmit = (event) =>{
        event.preventDefault();
        const {editMode} = this.state;
        if (editMode) {
            this.props.editModel(this.state.data).then(data => {
            
                
                if(data.status==="success"){
                    this.setState({errors:[]}) 
                    this.props.history.push('/dashboard/brands')
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }   
            }) 
        }else{
            this.props.createModel(this.state.data).then(data => { 
                if(data.status==="success"){
                    this.setState({errors:[]}) 
                    this.props.history.push('/dashboard/brands')
                    // this.setState({
                    //         data:{
                    //             ...this.state.data,
                    //             id:'',name:'', image:'',base64:'',brandId:'', accessories:[{'name':'','price':''}]
                    //         },
                    //         errors:[]
                    //     })
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }  
            }) 
        }
    }

    addModelPhoto = (event) => {  
        this.props.setModelPhoto(this.props.match.params._id, event.target.files[0]).then(data => {
            if(data.status==="success"){ 
                const newImages = data.data
                this.setState({
                    data:{
                        ...this.state.data,
                        carImages:[
                            ...this.state.data.carImages,
                            newImages
                        ]
                    }     
                })  
            }
        })
    }
    removeModelPhoto = (id) => {  
        this.props.removeModelPhoto(this.props.match.params._id, id).then(data => {
            if(data.status==="success"){ 
                const newImages = this.state.data.carImages.filter(function( obj ) {
                    return obj.id !== id;
                });
                this.setState({
                    data:{
                        ...this.state.data,
                        carImages:newImages
                    }     
                })  
            }
        })
    }

    handleCheckbox = (event) =>{
        const{name, checked } = event.target;   
        this.setState({
            data:{
                ...this.state.data,
                [name]:checked 
            }      
        })   
    }

    render() { 

        const {editMode, errors, roleId}=this.state;
        const {name, accessories, lightTruck, carImages, base64, image, brandId,luggageCapacity }=this.state.data; 
 
        const {brands} = this.props; 
        const pageTitle = editMode ? 'Редактиране на Модел' : 'Създаване на Модел';
        return ( 
            <>
             <Card>
                <Grid container className="section-title-wrapper padding"> 
                    <Grid container item md={4} xs={12} alignItems="center"> 
                        <h3 className="section-title">
                            <i className="flaticon2-gear"></i> {pageTitle} 
                        </h3>
                    </Grid>   
                    <Grid container item xs={4}>    
                    </Grid>
                    <Grid container item md={4} xs={12}alignItems="center" justify="flex-end">  
                        <Link  
                            className="btn btn-secondary"
                            to="/dashboard/brands" 
                            ><i className="flaticon2-circular-arrow"/>
                            Назад
                        </Link>   
                    </Grid>
                </Grid>  
                    <form  className='form' noValidate onSubmit={this.onSubmit} className="padding">
                        <Grid container spacing={3}>  
                            <Grid item md={6} xs={12}>  
                                <h3 className="section-title">
                                    Инфо:
                                </h3>
                                <br/>   
                                <FormControl variant="outlined" error={errors.brandId?true:false} margin="normal" className='full-width select-styles'>
                                    <InputLabel id="brandId">
                                        Марка 
                                    </InputLabel>
                                    <Select  
                                        MenuProps={{
                                            anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "right"
                                            },
                                            transformOrigin: {
                                            vertical: "top",
                                            horizontal: "right"
                                            },
                                            getContentAnchorEl: null
                                        }} 
                                        labelId="brandId"
                                        required   
                                        inputProps={{
                                            name: 'brandId', 
                                        }} 
                                        value={brandId} 
                                        onChange={this.onChange}
                                        labelWidth={60}  >  
                                        {brands.map(brand=>{
                                            return <MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>  
                                        })}   
                                    </Select>
                                    {errors.brandId&&<FormHelperText>{errors.brandId}</FormHelperText>}
                                
                                </FormControl> 
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fas fa-copyright"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Име на Модела"
                                    name="name"
                                    value={name}
                                    error ={errors.name?true:false} 
                                    helperText={errors.name} 
                                    onChange={this.onChange}  
                                />  
                                {brandId===82&&<TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fas fa-cogs"></i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Обем на багажника на Автомобилите"
                                    name="luggageCapacity"
                                    value={luggageCapacity}
                                    error ={errors.luggageCapacity?true:false} 
                                    helperText={errors.luggageCapacity} 
                                    onChange={this.onChange}  
                                /> }
                                <FormControlLabel
                                    control={
                                    <Checkbox 
                                        checked={Boolean(lightTruck)}
                                        onChange={this.handleCheckbox}
                                        name="lightTruck" 
                                        color="primary"
                                    /> 
                                    }
                                    label="Превозното средство е лекотоварен автомобил"
                                /> 
                                <br/> 
                                <br/> 
                               {editMode&&<> 
                               <h3 className="section-title">
                                    Снимки на модела:
                                </h3>
                                <br/>
                                <div className="image-section"> 
                                    {carImages.map( img => {
                                        return (
                                            <div className="image-wrapper one-third" key={img.id}>
                                                <div onClick={() => this.removeModelPhoto(img.id)}  className="delete"><i className="flaticon2-delete"></i></div>
                                                <img className='full-width' src={img.path} alt=""/>
                                            </div>
                                    
                                        )
                                    })}  
                                 </div>
                                 <div className="image-holder small">
                                    <p>Качете снимка</p>
                                    <i> 1300px на 1000px и не по-големи от 1 mb</i>
                                    <input  
                                        type="file" 
                                        name="file" 
                                        onChange = {this.addModelPhoto}
                                    /> 
                                </div>
                                </>}
                            </Grid>    
                            <Grid item md={1} xs={12}></Grid>   
                            <Grid item md={5} xs={12}> 
                                <h3 className="section-title">
                                    Аксесоари за Модела:
                                </h3>
                                <br/> 
                                {accessories.map((item, i) =>{
                                    return(  
                                    <div className="aditional-accessory" key={i}>
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                                            InputLabelProps={{shrink: true}} 
                                            required 
                                            name="name"
                                            margin="normal" 
                                            variant="outlined"    
                                            label="Име на аксесоара:"
                                            value={item.name}
                                            onChange={e=>this.onChangeDinamic(e, i)}  
                                        />  
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small number" 
                                            InputLabelProps={{shrink: true}} 
                                            label="Цена лв."
                                            type="number"
                                            margin="normal" 
                                            variant="outlined"    
                                            name="price"
                                            value={item.price}
                                            onChange={e=>this.onChangeDinamic(e, i)}  
                                        />  
                                        <div className="btn-box">
                                            {accessories.length !== 1 &&
                                            <div className='delete' onClick={()=>this.handleRemove(i)}>
                                                <i className="flaticon2-delete"></i>  
                                            </div>}
                                            {accessories.length - 1 === i && 
                                            <div className='add' onClick={this.handleAdd}>
                                                +
                                            </div>}
                                        </div>
                                    
                                    </div>
                                    )
                                })}   
                                <br/>
                                {image&&<> 
                                    <h3 className="section-title">
                                        Снимка на аксесоари на марката:
                                    </h3>
                                    <br/> 
                                    <div className="image-wrapper">
                                        <div onClick={() => this.removePhoto('image')}  className="delete"><i className="flaticon2-delete"></i></div>
                                        <img className='full-width' src={image} alt=""/>
                                    </div>
                                    {!image &&<div className="image-holder">
                                        <p>Качете снимка</p>
                                        
                                        <input 
                                            accept="image/*" 
                                            type="file"
                                            id="image" 
                                            name="image" 
                                            onChange = {this.handlePhotoChange}
                                            />
                                    </div>} 
                                    </>  }    
                                {image.length<1&&<> 
                                    <h3 className="section-title">
                                        Снимка на аксесоари на марката:
                                    </h3>
                                    <br/> 
                                    {base64&&<div className="image-wrapper">
                                        <div onClick={() => this.removePhoto('base64')}  className="delete"><i className="flaticon2-delete"></i></div>
                                        <img className='full-width' src={base64} alt=""/>
                                    </div>}
                                    {!base64 &&<div className="image-holder">
                                        <p>Качете снимка</p>
                                        <i> 1300px на 1000px и не по-големи от 1 mb</i>
                                        <input 
                                            accept="image/*" 
                                            type="file"
                                            id="image" 
                                            name="base64" 
                                            onChange = {this.handlePhotoChange}
                                            />
                                    </div>} 
                                </> }       
                            </Grid>
                        </Grid>
                        <br/><br/>
                        <Grid container spacing={2} >   
                            <Grid container item md={4} xs={12} >    
                                {roleId!==19&&<button type="submit" className="btn btn-info full-width">
                                    <i className="flaticon2-add-square"></i>{editMode ? 'Запази' : 'Създай'}
                                </button>}  
                            </Grid>  
                        </Grid> 
                    </form> 
                </Card> 
            </>
        );
    }
}

const mapStateToProps = (state, props) => {  
    return{ 
        model:state.models.singleModel,  
        brands:state.brands.items
    } 
}
export default connect(mapStateToProps, {fetchSingleModel, createModel, editModel,setModelPhoto, fetchBrands,removeModelPhoto })(EditModel);
 