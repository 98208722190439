import { apiUrl} from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions"; 

 
export const downloadDocument = (type, id) => {  
    fetch(apiUrl+'download-document/'+type+'/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") { 
            const _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/); 
         
            if (_iOSDevice) {
                window.location.assign(data.url)  
            }
            else {  
                var win = window.open(data.url, '_blank');
                win.focus();  
            } 
        } 
    }); 
}