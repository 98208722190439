import React, { Component } from 'react'; 
import {Link} from 'react-router-dom';

import {connect} from 'react-redux'


import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";   
 

import ClientInfo from './ClientInfo'
import CarInfo from './CarInfo' 
import OrderInfo from './OrderInfo' 
import UserAndLising from './UserAndLising' 
import Contract from './Contract' 

import {fetchSingleOrder, updateOrder, editOrder, createOrder, clearOrder, sendOrderIdToNav, fetchLeasingCompanies} from '../../../_actions/ordersActions'
import {fetchSingleOffer} from '../../../_actions/offersActions'
import {fetchSingleSaleCars} from '../../../_actions/carsSaleActions'
import {downloadDocument} from '../../../_actions/downloadDocument'
import {fetchClientsAutoComplete, createClient, editClient} from '../../../_actions/clientsActions'

 
import {validator} from '../../../helpers/validator'
import {setMessage, removeMessage } from "../../../_actions/msgActions";

class EditOrder extends Component { 
    state={ 
        editMode:false, 
        activeStep:1,   
        errors:{},
        disableSteps:true,
        disableSubmit:false,
        disableSubmitProform:false,
        disableErpBtn:false,
        roleId: JSON.parse(localStorage.getItem('roleId')),
    }

    componentDidMount(){  
        this.props.fetchLeasingCompanies()
        if (this.props.match.params._id) {
            this.setState({editMode:true})
            this.props.fetchSingleOrder(this.props.match.params._id).then(data => { 
                if(data.status==="success"){ 
                    this.setState({disableSteps:false})
                    this.getCarInfo(data.data.carId)
                }
            })
        }else{
            this.props.clearOrder()
            
        }

        const query = new URLSearchParams(this.props.location.search); 
        const offerId = query.get('offerId');
        if(offerId){
            this.props.fetchSingleOffer(offerId).then(data => { 
                if(data.status==="success"){ 
                    const {priceBgn,priceEur,varBgn,varEur, totalPriceIncVatBgn, totalPriceIncVatEur} = data.data.price
                    const {contact} = data.data
                    const {name , egn} = data.data.contact
                    const {bic,bank, iban} = data.data.company
                    this.props.updateOrder('none', { 
                        offerId:offerId,
                        priceBgn,priceEur,
                        vatBgn:varBgn,
                        vatEur:varEur,
                        contactName:name,
                        totalPriceIncVatBgn,totalPriceIncVatEur, 
                        bic,bank, iban
                    })
                    // if (egn.length>0) {
                    //     this.props.fetchClientsAutoComplete(egn, '1').then(data => { 
                    //         if(data.status==="success"){ 
                    //             if (data.data.length>0) {
                    //                 this.props.updateOrder('client', {
                    //                     type:1,
                    //                     ...data.data[0],
                    //                     singleClientId:data.data[0].id
                    //                 }) 
                    //                 this.props.updateOrder('none', {clientId:data.data[0].id})
                    //             }else{ 
                    //                 this.props.updateOrder('client', {type:1,...contact, id:''})
                    //                 this.props.updateOrder('none', {clientId:''})
                    //             }
                    //             this.setState({disableSteps:false})
                    //         }
                    //     })
                    // }else{
                        this.setState({disableSteps:false})
                        this.props.updateOrder('client', { type:1 }) 
                    // }
                    this.getCarInfo(data.data.car.carId)
                } 
            }) 
        }  
    } 

    getCarInfo = (carId)=>{
        this.props.fetchSingleSaleCars(carId).then(data => { 
            if(data.status==="success"){   
                const {inventoryNumber, id, brand, engineType, model ,factoryGrade, externalColorDescription, vin}=data.data.vehicle
                const {kw, engine, fuelType, seats, tankCapacity}=data.data.technical;   
                this.props.updateOrder('none', {
                    carVin:data.data.vehicle.vin,
                    inventoryNumber, id, brand, enginePower:kw, engineNumber:engine, engineType, fuelType, seats, volume:tankCapacity, model ,version:factoryGrade, externalColor:externalColorDescription, carId:id
                }) 
   
             }
        }) 
    }

    switchStep = (id) => { 
        const {disableSteps, activeStep }=this.state;  
        if (!disableSteps) {
            if (activeStep===1) {
                this.saveClientToOurBasa(id);
            }else if(activeStep===2){     
                if (
                    !this.checkForEmptyFields('additionalClient') && 
                    !this.checkForEmptyFieldsBuyBack() &&
                    !this.checkForEmptyFieldsUserLeasing()
                ) {
                    this.saveLeasingUser(id); 
                }  
            }else{
                this.setState({activeStep:id, errors:{}})
            }
        }
  
        
    } 

    goToNextStep = () => {   
        let id = this.state.activeStep+1
        const {disableSteps, activeStep }=this.state; 
        if (!disableSteps) {
            if (activeStep===1) { 
                this.saveClientToOurBasa(id);
               
            }else if(activeStep===2){     
                if (
                    !this.checkForEmptyFields('additionalClient') && 
                    !this.checkForEmptyFieldsBuyBack() &&
                    !this.checkForEmptyFieldsUserLeasing()
                    ) { 
                        this.saveLeasingUser(id);
                    }  
            }else{
                this.setState({activeStep:id, errors:{}})
            }
        }
    } 
    
    checkForEmptyFieldsUserLeasing = ()=>{
        const {leasingUser= {}, leasingCompanType, hasLeasing} = this.props.singleOrder || {} 
        if ( hasLeasing&&leasingCompanType===2) {
            const {companyContact="",companyContactEgn="",companyContactCity="",companyContactIdCardNumber="",companyContactIdCardPublishedBy="",companyContactIdCardDate="",proxyNumber="",proxyAuthor="",attorneyNumber=""}=leasingUser

            const fields = {companyContact,companyContactEgn,companyContactCity,companyContactIdCardNumber,companyContactIdCardPublishedBy,companyContactIdCardDate,proxyNumber,proxyAuthor,attorneyNumber} 

            if ( Object.keys(validator(fields)).length) { 
                this.setState({errors:{
                    ...this.state.errors,
                    leasingUserErrors:validator(fields)
                }}) 
                return true;
            }else{ 
                return false 
            } 
        }else{
            return false    
        }  
    }

    checkForEmptyFieldsBuyBack =()=>{
        if (this.props.singleOrder.hasBuyBack) {  
            const {vin='', brand='', hp='', engineNumber='', engineType='', fuelType='', number='', model='', externalColor='', bodyType='',transmission='',commercialGrade='', externalColorDescription='',internalColor='',registrationDate='',registrationNumber='',mileage='',price=''}=this.props.singleOrder.buyBack||{};
            const fields = {vin, brand, hp, engineNumber, engineType, fuelType, number, model, externalColor, bodyType,transmission,commercialGrade, externalColorDescription,internalColor,registrationDate,registrationNumber,mileage,price} 
            if ( Object.keys(validator(fields)).length) { 
                this.setState({errors:{
                    ...this.state.errors,
                    buyBack:validator(fields)
                }}) 
                return true;
            }else{ 
                return false 
            } 
        }else{
            return false    
        }
    }

    checkForEmptyFields = (obj)=>{  
        if (obj=="additionalClient") {
            if (this.props.singleOrder.hasAdditionalClient) {
               
               return this.clientEmptyFields(obj)
            }else{
                return false    
            }
        }else{ 
            return this.clientEmptyFields(obj)
        }  
    }

    clientEmptyFields = (obj)=>{
        const { type=0 }= this.props.singleOrder[obj] ||{}
        if (type===1) { 
            const { address='',city='', name='',phone='',egn='',email='',idCardNumber='',idCardDate='',idCardPublishedBy=''}=this.props.singleOrder[obj];
            const fields = {address,city, name,phone,egn,email,idCardNumber,idCardDate,idCardPublishedBy}  
            if ( Object.keys(validator(fields)).length) { 
                this.setState({errors:{
                    ...this.state.errors,
                    [obj]:validator(fields)}
                })  
                return true;
            }else{ 
                return false 
            } 
        } 
        else if (type===2) {
            const {companyContact='', companyContactEgn='', companyContactCity='', companyContactIdCardDate='', companyContactIdCardNumber='', companyContactIdCardPublishedBy='', branches='', companyName='',email='', companyEik='',companyCountry='', companyCity='', companyAddress='', companyVat='',companyMol='',phone='', hasContact=false}=this.props.singleOrder[obj];
            let fields='';
            if (hasContact) {
                 fields = {companyContact, companyContactEgn, companyContactCity, companyContactIdCardDate, companyContactIdCardNumber, companyContactIdCardPublishedBy, branches, companyName,email, companyEik,companyCountry, companyCity, companyAddress, companyVat,companyMol,phone}  
            }else{
                fields = {branches, companyName,email, companyEik,companyCountry, companyCity, companyAddress, companyVat,companyMol,phone}  
            }
      
            if ( Object.keys(validator(fields)).length) { 
                this.setState({errors:{
                    ...this.state.errors,
                    [obj]:validator(fields)}
                }) 
                return true;
            }else{ 
                return false 
            } 
        } 
        else{
            return false 
        } 
    }
    
    saveClientToOurBasa = (id)=>{ 
        if (!this.checkForEmptyFields('client') ) { 
            if (!this.props.singleOrder.client.id) { 
                const {editMode} = this.state;
                if (editMode) {
                    this.setState({  
                        activeStep:id,
                        errors:{}
                    })
                }else{
                    this.props.createClient(this.props.singleOrder.client).then(data => { 
                        if(data.status==="success"){ 
                            this.props.updateOrder('none', {clientId:data.data.id }) 
                            this.props.updateOrder('client', {...data.data }) 
                            this.setState({  
                                activeStep:id, 
                                errors:{}
                            })
                        } 
                        else if (data.errors) {
                            this.setState({
                                errors:{
                                    client:data.errors
                                }
                            })   
                        }  
                    })  
                }
            }else{
                this.setState({  
                    activeStep:id,
                    errors:{}
                })
            }
        } 
      
    }

    saveLeasingUser = (id)=>{ 
        const {hasLeasing,leasingCompanType, leasingCompanyId,leasingUser}=this.props.singleOrder
        if (hasLeasing&&leasingCompanType===2) {
            this.props.editClient({id:leasingCompanyId,...leasingUser}).then(data => { 
                if(data.status==="success"){  
                    this.setState({  
                        activeStep:id, 
                        errors:{}
                    })
                } 
                else if (data.errors) {
                    this.setState({
                        errors:{
                            leasingUserErrors:data.errors
                        }
                    })   
                }  
            })  
        }else{
            this.setState({activeStep:id, errors:{}})
        }
    }

    createAndDownload = ()=>{
        this.setState({disableSubmitProform:true})
     
        const {editMode} = this.state;
        if (editMode) {
            this.props.editOrder(this.props.singleOrder, this.props.match.params._id).then(data => { 
                if(data.status==="success"){
                    this.setState({errors:{}})  
                    downloadDocument('proforma',this.props.match.params._id)
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }   
                this.setState({disableSubmitProform:false})
            }) 
        }else{
            this.props.createOrder(this.props.singleOrder).then(data => {  
                if(data.status==="success"){ 
                    downloadDocument('proforma',data.data.id) 
                } 
                else if (data.errors) { 
                    if (data.errors.offerId) {
                        this.props.setMessage(data.errors.offerId);
                        this.props.removeMessage(); 
                    }
                    this.setState({errors:data.errors})   
                }  
                this.setState({disableSubmitProform:false})
            }) 
        } 
    }
    
    onSubmit = (event) =>{
        this.setState({disableSubmit:true}) 
        event.preventDefault();
        const {editMode} = this.state; 
        if (editMode) {
            this.props.editOrder(this.props.singleOrder, this.props.match.params._id).then(data => { 
                if(data.status==="success"){
                    this.setState({errors:{}}) 
                    this.props.history.push('/dashboard/orders')
                } 
                else if (data.errors) { 
                    this.setState({errors:data.errors})   
                }   
                this.setState({disableSubmit:false})
            }) 
        }else{
            this.props.createOrder(this.props.singleOrder).then(data => { 
                if(data.status==="success"){ 
                    // this.props.sendOrderIdToNav(data.data.id)
                    this.props.history.push('/dashboard/orders')
                } 
                else if (data.errors) {
                    if (data.errors.offerId) {
                        this.props.setMessage(data.errors.offerId);
                        this.props.removeMessage(); 
                    }
                    this.setState({errors:data.errors})   
                }  
                this.setState({disableSubmit:false})
            }) 
        }
    }

    sendToERP = ()=>{  
        this.setState({disableErpBtn:true})
        this.props.sendOrderIdToNav(this.props.match.params._id).then(data => {
            if(data.status==="success"){
                this.setState({disableErpBtn:false}) 
            } 
        })
    }

    render() { 
        const {editMode, activeStep, errors, disableSubmit, disableSubmitProform, disableErpBtn, roleId}= this.state;  
        const errorStep1 = errors.client ? true : false
        const errorStep2 = (errors.additionalClient || errors.leasingCompanyId || errors.buyBack || errors.leasingUserErrors) ? true : false
        const errorStep3 = (errors.carId) ? true : false
        const errorStep4 = (errors.bank || errors.bic || errors.carId || errors.companyId || errors.orderDatedepositBgn || errors.depositEur || errors.iban || errors.priceBgn || errors.priceEur || errors.status || errors.totalPriceIncVatBgn || errors.totalPriceIncVatEur ||errors.tradeFeeBgn || errors.tradeFeeEur || errors.vatBgn || errors.vatEur) ? true : false
        const errorStep5 = false  
        const pageTitle = editMode ? 'Редактиране на Поръчка:' : 'Създаване на Поръчка:'; 
        return (  
            <>  
                <Card >
                    <Grid container className="section-title-wrapper padding"> 
                        <Grid container item xs={6} alignItems="center"> 
                            <h3 className="section-title">
                                <i className="flaticon2-gear"></i> {pageTitle}
                                
                            </h3>
                        </Grid>  
                       <Grid container item xs={6} alignItems="center" justify="flex-end">  
                            <Link  
                                className="btn btn-secondary"
                                to="/dashboard/orders" 
                                ><i className="flaticon2-circular-arrow"/>
                               <span>Назад</span> 
                            </Link>   
                        </Grid>
                    </Grid>  
                    <form  className='form' noValidate onSubmit={this.onSubmit} className="padding">
                        <Grid container spacing={3}> 
                            <Grid item md={12} xs={12} className="step-wrapper">   
                                <div className={"single-step " +(activeStep === 1 ?"active ":"")+ (errorStep1 ?"error":"")}
                                    onClick={()=>{this.switchStep(1)}}>
                                    <div className='step'>
                                        <i className="flaticon2-user"></i>
                                    </div>
                                    <p className="step-title">
                                        Данни за клиента
                                    </p>
                                </div> 
                                <div className='separator'></div>
                                <div className={"single-step " +(activeStep === 2 ?"active ":"") + (errorStep2 ? "error":"")} 
                                    onClick={()=>{this.switchStep(2)}}>
                                    <div className='step'>
                                        <i className="fas fa-users"></i>
                                    </div>
                                    <p className="step-title">
                                        Ползвател и Лизинг
                                    </p>
                                </div> 
                             
                                <div className='separator'></div>
                                <div className={"single-step " +(activeStep === 3 ?"active ":"") + (errorStep3 ? "error":"")} 
                                    onClick={()=>{this.switchStep(3)}}>
                                    <div className='step'>
                                        <i className="fas fa-car"></i>
                                    </div>
                                    <p className="step-title">
                                        Данни за автомобила
                                    </p>
                                </div> 
                                <div className='separator'></div>
                                <div className={"single-step " +(activeStep === 4 ?"active ":"")+ (errorStep4 ?"error":"")}
                                    onClick={()=>{this.switchStep(4)}}>
                                    <div className='step'>  
                                        <i className="fas fa-file-contract"></i> 
                                    </div>
                                    <p className="step-title">
                                        Данни за Поръчка
                                    </p>
                                </div>  
                                {editMode&&<><div className='separator'></div>
                                <div className={"single-step " +(activeStep === 5 ?"active ":"")+ (errorStep5 ?"error":"")}
                                    onClick={()=>{this.switchStep(5)}}>
                                    <div className='step'>  
                                    
                                        <i className="fas fa-file-signature"></i>
                                    </div>
                                    <p className="step-title">
                                        Договор и ППП
                                    </p>
                                </div>
                                </> } 
                            </Grid>  
                        </Grid> 
                        <br/><br/>
                        {activeStep === 1 &&<ClientInfo errors={errors}/>} 
                        {activeStep === 2 &&<UserAndLising errors={errors}/>} 
                        {activeStep === 3 &&<CarInfo errors={errors}/>}
                        {activeStep === 4 &&<OrderInfo errors={errors}/>} 
                        {(activeStep === 5 && editMode)&&<Contract/>} 
                        {roleId!==19&&activeStep === 4 &&<Grid container spacing={2} >   
                            <Grid item md={4} xs={12} >    
                                <button type="submit" disabled={disableSubmitProform || disableSubmit || disableErpBtn} className="btn btn-info full-width">
                                    {disableSubmit?<div className="lds-dual-ring"></div>:   <i className="flaticon2-add-square"></i>}
                                    {editMode ? 'Запази промените' : 'Създай'}
                                </button>    
                            </Grid> 
                            <Grid item md={4} xs={12}>  
                                <button type='button' disabled={disableSubmitProform || disableSubmit || disableErpBtn}
                                onClick={()=>this.createAndDownload()} 
                                className='btn btn-success full-width'> 
                                {disableSubmitProform?<div className="lds-dual-ring"></div>:<i className="fas fa-download"></i>}

                                    Създай и свали проформа
                                </button> 
                            </Grid> 
                            {editMode&&<Grid item md={4} xs={12}>  
                                <button type='button' disabled={disableSubmitProform || disableSubmit || disableErpBtn}
                                onClick={()=>this.sendToERP()} 
                                className='btn btn-danger full-width'> 
                                {disableErpBtn?<div className="lds-dual-ring"></div>:<i className="fas fa-vector-square"></i>} 
                                    Изпрати към ЕРП
                                </button> 
                            </Grid> }
               
                        </Grid>}
                        {(activeStep !== 5 &&  activeStep !== 4)&&<Grid container spacing={2} >   
                            <Grid item md={4} xs={12} >    
                                <button type="button" onClick={this.goToNextStep}className="btn btn-info full-width">
                                    Напред
                                </button>   
                            </Grid> 
                        </Grid>}
                    </form> 
                </Card> 
            </>
         );
    }
}
 

const mapStateToProps = (state, props) => {    
    return{
        singleOrder:state.orders.singleOrder,  
    }
}

export default connect(mapStateToProps, {fetchLeasingCompanies, setMessage, removeMessage,createClient, editClient, sendOrderIdToNav, fetchClientsAutoComplete, fetchSingleOffer,fetchSingleSaleCars, fetchSingleOrder, updateOrder, editOrder, createOrder,clearOrder})(EditOrder);   
