import React, { Component } from 'react';

import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';

import {connect} from 'react-redux'

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  

import {columns, options} from "../../../helpers/modifyOffer";   
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns";  

import {fetchModifiedOffers} from '../../../_actions/offersActions'
 
 
class ListOffers extends Component {
   
    state ={
        permissions: JSON.parse(localStorage.getItem('permissions')),
        roleId: JSON.parse(localStorage.getItem('roleId')), 
        options:{},
        columns:[], 
    } 

    componentDidMount (){ 
        this.setState({
            options: storeColumnsFilters('offersModifyTable', options),
            columns: getStoredColumnsFilters('offersModifyTable', columns)
        }) 
        this.props.fetchModifiedOffers()  
    } 

 

    onDownload = (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
    
    onRowClick=(rowData , rowMeta, e) => {  
        const {permissions= {}} = this.state; 
        if (permissions.offersUpdate) {
            this.props.history.push("/dashboard/modify-offer/"+rowData[0]);
        }
    }

    render() {  
        const {modifiedOffers} = this.props;  
        const {data, options, columns} = this.state;  
  
        return ( 
            <React.Fragment>  
                <Grid container spacing={3} className="mb-20 header-section">
                    <Grid container item xs={6} sm={4} alignItems="center"> 
                        {<Link className="btn btn-info" to="/dashboard/create-modified-offer"><i className="flaticon-add-circular-button"/>
                            Създай Модификация
                        </Link>}  
                    </Grid>   
                    
                </Grid>    
                <Card>  
                    <MUIDataTable
                        title={"Оферти:"}
                        data={modifiedOffers}
                        columns={columns} 
                        options={{...options, onRowClick: this.onRowClick, onDownload:this.onDownload}}  
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
const mapStateToProps = state => {   
    return({modifiedOffers:state.offers.modifiedOffers})
}
 
 export default withRouter(connect(mapStateToProps, { fetchModifiedOffers })(ListOffers));
