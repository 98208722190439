import React, { Component } from 'react';
import {connect} from 'react-redux'
import {returnCar,confirmReturnCar} from '../../_actions/driveListActions'
import {setMessage, removeMessage} from '../../_actions/msgActions'
import {withRouter} from 'react-router-dom';
import {Redirect} from 'react-router'; 
class InfoBox extends Component {
    state = {}

    componentDidMount(){
        // this.props.returnCar();    
    } 

    componentDidUpdate(previousProps, previousState){    
        if (previousProps.data !== this.props.data) {
            this.setState({show:this.props.data})
        }
        if(previousProps.car !== this.props.car){
            const {id, brand, model, runTimeStart, runTimeEnd} = this.props.car
            this.setState({
                id, brand, model, runTimeStart, runTimeEnd
            })
        } 
    }

    onClick = () =>{
        this.props.confirmReturnCar(this.state.id).then(data=>{ 
            this.setState({
                show:false
            })
            this.props.setMessage('Успешно върнат автомобил')
            this.props.removeMessage()
            this.props.history.push('/dashboard/drive-list')
        })
    }
    closeBox =()=>{
        this.props.handleCloseBox();
    }
    render() { 
        const {id, brand, model, runTimeStart, runTimeEnd, show} = this.state

        return (  
            
            <div className={"alert-box-wrapper  active "+( show ? 'show':'') }> 
                <div className="alert-box pt"> 
                    <div className="close-box" onClick={this.closeBox}>x</div>
                    <h3>Сигурни ли сте че искате да върнете автомобил със следните данни ?</h3>
                    <br/>
                    <ul className="text-left"> 
                        <li>Модел: <strong>{model}</strong></li>  
                        <li>Марка: <strong>{brand}</strong></li>   
                        <li>Км. При предоставяне: <strong>{runTimeStart}</strong></li>    
                        <li>Км. При приемане: <strong>{runTimeEnd}</strong></li>   
                    </ul>
                    <br/>
                    <button type="button" className="btn btn-info full-width" onClick={this.onClick}>
                        <i className="flaticon2-telegram-logo"></i>Върни
                    </button>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => { 
    return({car:state.driveList.returnCar})
 }
 
 export default withRouter(connect(mapStateToProps, { returnCar , confirmReturnCar,setMessage, removeMessage })(InfoBox)); 