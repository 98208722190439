import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

import MomentUtils from "@date-io/moment";
import moment from "moment";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';

import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select'; 

const chanelList = [
    {id:1, name:'Социални мрежи'},
    {id:2, name:'Google'},
    {id:3, name:'Радио'},
    {id:4, name:'Print'},
    {id:5, name:'Бордове'},
    {id:6, name:'От приятел'}
]

class ClientForm extends Component { 
    state={
        data:{
            id:'',
            name:'',
            phone:'',
            email:'',
            egn:'', 
            idCardNumber:'', 
            idCardDate:'',  
            idCardPublishedBy:'',
            drivingLicense:'' ,
            drivingLicenseDate:'', 
            city: "",
            address: "",
            contactName:"",
            contactId:'',
            contactCompany:'',
            model:'',
            chanel:[],
        }, 
        errors:{}, 
        open:false
    }
    // model,chanel:chanel.length>0 ?chanel:[]
    componentDidMount(){   
        this.getPassedData()
        if (this.props.errors) {
            this.setState({errors:this.props.errors })   
        } 
    }
  
    componentDidUpdate(previousProps, previousState){   
        if(previousProps.errors !== this.props.errors){   
            if (this.props.errors) {  
                
                this.setState({errors:this.props.errors })   
            }   
        } 
        if(previousProps.userData !== this.props.userData){  
            this.getPassedData()
        }  
    }
 

    getPassedData = () =>{
        const{ editMode, userData, type}=this.props;    
        if (userData) {    
            const {chanel=[]}=userData;
            if (type==1) {    
                this.setState({ 
                    data:{
                        ...userData, 
                        chanel:chanel.length>0 ?chanel :[],
                        type:type,
                        id:userData.id
                    }
                })
            }else{
                this.setState({ 
                    data:{
                        ...this.state.data, 
                        chanel:chanel.length>0 ?chanel :[],
                        type:type,
                        id:userData.id
                    }
                })
            } 
        } else{
            this.setState({ 
                data:{}
            })
        }
    }

    
  

    onChange = (event) =>{
        const {name,value} = event.target;
        this.setState({
            data:{
                ...this.state.data,
                [name]:value
            },
            errors:{
                ...this.state.errors,
                [name]:''
            }
        }, () => {
            this.props.handleData(this.state.data);
        })  
    }

    onChangeDate = (input, date, value) => {   
        this.setState({
            data:{
                ...this.state.data, 
                [input]:value 
            },
            errors:{
                ...this.state.errors,
                [input]:''
            }
        }, () => {
            this.props.handleData(this.state.data);
        })  
    };

    handleChangeChanels = (event)=>{
        this.setState({
            data:{
                ...this.state.data, 
                chanel:event.target.value
            }
        })
    }
    render() {  
       
        const {open, errors}= this.state;
        const {model,chanel, address='',city='', name='', phone='',egn='',email='',idCardNumber='',idCardDate='', drivingLicense='',idCardPublishedBy='',drivingLicenseDate='', contactCompany=''}= this.state.data;
        const {clients, editMode}=this.props;    
        return (  
            <React.Fragment>    
                <Grid container spacing={3} > 
                    <Grid item md={4} xs={12}> 
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-user"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            required
                            label="Име:"
                            name="name"
                            value={name}
                            onChange={this.onChange}
                            error ={errors.name?true:false}  
                            helperText={errors.name}  
                        />   
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-rotate"></i>  
                                    </InputAdornment>
                                ),
                                type:"password",
                                autoComplete: 'new-password'
                            }}label="ЕГН:"
                            required 
                            name="egn"   
                            value={egn}
                            error ={errors.egn?true:false}  
                            helperText={errors.egn}  
                            onChange={this.onChange} 
                        /> 
                         <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-home-1"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Град:"
                            required
                            name="city"  
                            value={city}
                            error ={errors.city?true:false}  
                            helperText={errors.city} 
                            onChange={this.onChange} 
                        />  
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-home-1"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Aдрес:"
                            required
                            name="address"  
                            value={address}
                            error ={errors.address?true:false}  
                            helperText={errors.address} 
                            onChange={this.onChange} 
                        />   
                         <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-phone"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Телефон:"
                            required
                            name="phone"   
                            value={phone}
                            error ={errors.phone?true:false} 
                            helperText={errors.phone}  
                            onChange={this.onChange} 
                        />  
                    </Grid>   
                    <Grid item md={4} xs={12}>     
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-envelope"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="E-mail:"
                            required
                            name="email"  
                            value={email}
                            error ={errors.email?true:false}  
                            helperText={errors.email}  
                            onChange={this.onChange} 
                        /> 
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-browser"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="ЛК/номер:"
                            required
                            name="idCardNumber" 
                            value={idCardNumber}
                            error ={errors.idCardNumber?true:false} 
                            helperText={errors.idCardNumber} 
                            onChange={this.onChange} 
                        />  
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-browser"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Издадена от:"
                            required
                            name="idCardPublishedBy" 
                            value={idCardPublishedBy}
                            error ={errors.idCardPublishedBy?true:false}  
                            helperText={errors.idCardPublishedBy} 
                            onChange={this.onChange} 
                        /> 
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                            <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                                autoOk={true}
                                required
                                showTodayButton={true} 
                                InputLabelProps={{shrink: true}} 
                                className="full-width date mb-20"  
                                inputVariant="outlined" 
                                margin="normal"
                                label="ЛК/дата на издаване:" 
                                format="DD.MM.YYYY"
                                value=''
                                inputValue={idCardDate} 
                                onChange={(e,d)=>this.onChangeDate('idCardDate', e ,d)}
                                // rifmFormatter={this.dateFormatter} 
                                error ={errors.idCardDate?true:false}  
                                helperText={errors.idCardDate} 
                            />
                        </MuiPickersUtilsProvider>
                        {/* <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-calendar-1"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="ЛК/дата на издаване"
                            name="idCardDate"  
                            value={idCardDate}
                            error ={errors.idCardDate?true:false}  
                            helperText={errors.idCardDate} 
                            onChange={this.onChange} 
                        />   */}
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}}
                            margin="normal"  
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="fas fa-car"></i> 
                                    </InputAdornment>
                                ),
                            }}
                            label="Модел"
                            name="model"
                            value={model}
                            error={errors.model?true:false} 
                            helperText={errors.model} 
                            onChange={this.onChange}  
                        /> 
                    </Grid>   
                    <Grid item md={4} xs={12}>  
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-browser"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Шофьорска книжка номер"
                            name="drivingLicense"  
                            value={drivingLicense}
                            error ={errors.drivingLicense?true:false}  
                            helperText={errors.drivingLicense} 
                            onChange={this.onChange} 
                        />   
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                            <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                                autoOk={true}
                                showTodayButton={true} 
                                InputLabelProps={{shrink: true}} 
                                className="full-width date mb-20"  
                                inputVariant="outlined" 
                                margin="normal"
                                label="Книжка/дата на издаване:" 
                                format="DD.MM.YYYY" 
                                value=''
                                inputValue={drivingLicenseDate} 
                                onChange={(e,d)=>this.onChangeDate('drivingLicenseDate', e ,d)}
                                // rifmFormatter={this.dateFormatter} 
                                error ={errors.drivingLicenseDate?true:false}  
                                helperText={errors.drivingLicenseDate} 
                            />
                        </MuiPickersUtilsProvider> 
                        <TextField 
                            inputProps={{'className':'data-hj-allow'}}           
                            className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="fas fa-city"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Представител на Компания:"
                            name="contactCompany" 
                            value={contactCompany}
                            error ={errors.contactCompany?true:false} 
                            helperText={errors.contactCompany}
                            onChange={this.onChange} 
                        /> 
                    </Grid>  
                </Grid>   
                <Grid container spacing={3} >  
                    <Grid item md={8} xs={12}> 
                        <h4>Канали:</h4>
                        <Select
                            className='full-width'
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            value={chanel}
                            onChange={this.handleChangeChanels}
                            onClose={() =>{this.props.handleData(this.state.data)}}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => {
                       
                                return(
                                    <div className="chips">
                                        {selected.length>0 && selected.map((item) =>{   
                                            return  ( 
                                                chanelList.length>0&&<Chip key={item} label={chanelList.find(x => x.id == item).name} className="chip"/>
                                    
                                            )})}
                                        </div>
                                    
                                )
                            } }
                            >
                            {chanelList.map((item) => {
                                return (
                                    <MenuItem key={item.id} value={item.id} className="chip-options">
                                        {item.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                </Grid>
            </React.Fragment>
         );
    }
}

 
export default ClientForm
 
 