import React, { Component } from 'react'; 
import {connect} from 'react-redux'     
import {setMessage} from '../../_actions/msgActions'

class Alert extends Component {
    state={
        msgText:this.props.msgText
    }

    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.msgText !== this.props.msgText){ 
            this.setState({
                msgText:this.props.msgText
            })
        }
    }
    render() {  
        const {msgText} = this.state
       
        return ( 
            <div className={"alert-box-wrapper "+(msgText ? 'show':'') }>
               <div className="alert-box">
                    {msgText}
                </div>
            </div> 
        );
    }
}
const mapStateToProps = (state) => {     
    return {msgText:state.message.msgText}
}
export default connect(mapStateToProps, { setMessage })(Alert); 