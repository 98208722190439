import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

// const PrivateRoute = ({component:Component, auth:{isAuthenticated, loading}, ...rest}) => {
const PrivateRoute = ({component:Component, auth, ...rest}) => {
    return (
        <Route {...rest} render={ 
            props => !
            auth ? (
                <Redirect to="/" />
            ):(
                <Component {...props}/>
            ) 
        }/>
    )    
}

 
const mapStateToProps = state => {
    return({auth:state.auth.isAuthenticated})  
}
export default connect(mapStateToProps)(PrivateRoute);