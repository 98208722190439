const initialState = {
    items:[],
    singleClient:{},
    clientsAutoComplete:[],
    relatedContactsClients:[],
    relatedClients:[],
    lizingCompanies:[],
    clientLog:[],
    offers:[]
    
}

export default function (state = initialState, action){  
    const {type, payload} = action;  
    switch(type){
        case 'FETCH_CLIENTS':  
            return{
                ...state,
                items:payload 
            } 
        case 'FETCH_CLIENTS_AUTOCOMPLETE':  
            return{
                ...state,
                clientsAutoComplete:payload 
            } 
        case 'FETCH_SINGLE_CLIENT':  
            return{
                ...state,
                singleClient:payload 
            } 
        case 'EMPTY_CLIENT':  
            return{
                ...state,
                singleClient:{}
            } 
        case 'FETCH_RELATED_CONTACT_CLIENTS':  
            return{
                ...state,
                relatedContactsClients:payload 
            } 
        case 'FETCH_CONNECTED_CLIENT':  
            return{
                ...state,
                relatedClients:payload 
            } 
        case 'FETCH_LEASING_COMPANIES':  
            return{
                ...state,
                lizingCompanies:payload 
            } 
        case 'FETCH_CLIENTS_LOG':  
            return{
                ...state,
                clientLog:payload 
            } 
        case 'FETCH_CLIENT_OFFERS':  
            return{
                ...state,
                offers:payload 
            } 
        case 'EDIT_CLIENT':  
            return{ ...state, 
                items:state.items.map(client => ( client.id === payload.id ? payload : client ))
            } 
        default:
            return state;
    }
}