import React, { Component } from 'react';
 
import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
 

import {connect} from 'react-redux'

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  

import {columns, options} from "../../../helpers/viewАgreements";   
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns"; 
import {fetchAgreements} from '../../../_actions/agreementsActions'

 

class AgreementsList extends Component {
    state={
        permissions: JSON.parse(localStorage.getItem('permissions')),
        data:[], 
        options:{},
        columns:[]
     }

    componentDidMount (){
        this.setState({
            options: storeColumnsFilters('agreementsTable', options),
            columns: getStoredColumnsFilters('agreementsTable', columns)
        }) 
        this.props.fetchAgreements() 
    } 

    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.agreements !== this.props.agreements){
            const query = new URLSearchParams(this.props.location.search);
            const userId = query.get('userId')
            const contactId = query.get('contactId')
            const clientId = query.get('clientId')
            if (userId) { 
                let filtered = this.props.agreements.filter(item=>{ 
                    return item.userId === userId
                })
                this.setState({data:filtered})
            }else if(contactId){
                let filtered = this.props.agreements.filter(item=>{ 
                    return item.contactId === contactId
                })
                this.setState({data:filtered})
            }else if(clientId){
                let filtered = this.props.agreements.filter(item=>{ 
                    return item.clientId === clientId
                })
                this.setState({data:filtered})
            }else{
                this.setState({data:this.props.agreements})
            }
        }
    }

    onDownload = (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
  
    render() {  
    
        const {data, permissions= {}} = this.state; 
        const {options, columns} = this.state; 
        return (  
            <React.Fragment>  
                <Grid container className="mb-20">
                    <Grid container item xs={6} sm={6} alignItems="center"> 
                        {/* {permissions.contractsStore&&<Link className="btn btn-info" to="/dashboard/create-agreement"><i className="flaticon-add-circular-button"/>
                            Създай договор
                        </Link>} */}
                    </Grid>   
                </Grid>    
                <Card>  
                    <MUIDataTable
                        title={"Договори:"}
                        data={data}
                        columns={columns} 
                        options={{...options, onDownload:this.onDownload}}    
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
 
const mapStateToProps = state => {   
   return({agreements:state.agreements.items})
}

export default withRouter(connect(mapStateToProps, { fetchAgreements })(AgreementsList));
// export default ClientsList;