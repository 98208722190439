import { apiUrl} from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions"; 


export const fetchClients = () => dispatch => { 
    return fetch(apiUrl+'clients', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_CLIENTS',
                payload:data
            })
        } 
        return response
    }); 
}
export const fetchClientsAutoComplete = (name, type) => dispatch => {  
    return fetch(apiUrl+'customers/autocomplete?q='+name+'&type='+type, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") { 
            dispatch({
                type:'FETCH_CLIENTS_AUTOCOMPLETE',
                payload:data
            })
        } 
        return response
    }); 
}

export const fetchSingleClient = (id) => dispatch => { 
    return fetch(apiUrl+'clients/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_SINGLE_CLIENT',
                payload:data
            })
        } 
        return response
    }); 
}

export const fetchConectedClients = (id) => dispatch => { 
    return fetch(apiUrl+'clients/connected-clients/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_CONNECTED_CLIENT',
                payload:data
            })
        } 
        return response
    }); 
}
export const postConectedClients = (id, data) => dispatch => { 
    return fetch(apiUrl+'clients/connected-clients/'+id, {
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(data)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status} = response;
        if (status === "success") { 
        } 
        return response
    }); 
}

export const fetchLizingCompanies = (id) => dispatch => { 
    return fetch(apiUrl+'clients/leasings/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_LEASING_COMPANIES',
                payload:data
            })
        } 
        return response
    }); 
}

export const editClient = (client) => dispatch => { 
    const {id}=client;
    return fetch(apiUrl+'clients/'+id, {
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(client)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status, message} = response;
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'EDIT_CLIENT',
                payload:client
            })
        } 
        return response
    }); 
}

export const createClient = (client) => dispatch => {     
    return fetch(apiUrl+'clients', {
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(client)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {   
        const {status, message} = response; 
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());  
        }
        return response
    }); 
}

export const fetchRelatedContactsClients = (id) => dispatch => {  
    return fetch(apiUrl+'clients/contacts/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") { 
            dispatch({
                type:'FETCH_RELATED_CONTACT_CLIENTS',
                payload:data
            })
        } 
        return response
    }); 
}

export const fetchClientsLogs = (id) => dispatch => {  
    return fetch(apiUrl+'clients/logs/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") { 
            dispatch({
                type:'FETCH_CLIENTS_LOG',
                payload:data
            })
        } 
        return response
    }); 
}
export const fetchClientOffers = (id) => dispatch => {  
    return fetch(apiUrl+'contact/offers/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") { 
            dispatch({
                type:'FETCH_CLIENT_OFFERS',
                payload:data
            })
        } 
        return response
    }); 
}

export const emptyClient = (id) => dispatch => {  
    dispatch({
        type:'EMPTY_CLIENT',
        payload:''
    })
}