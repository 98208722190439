import React, { Component } from 'react';
import {connect} from 'react-redux' 

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import MomentUtils from "@date-io/moment";
import moment from "moment";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
 

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";  

import Autocomplete from '@material-ui/lab/Autocomplete';

import {fetchSaleCarsAutoComplete} from '../../../_actions/carsSaleActions'
import {updateOrder} from '../../../_actions/ordersActions'


class BuyBack extends Component {  
    onChange = (event) =>{ 
        const {name,value} = event.target;  
        this.props.updateOrder('buyBack', {
            ...this.props.singleOrder.buyBack, 
            [name]:value
        })  
    }

    onChangeDate = (input, date, value) => {   
        this.props.updateOrder('buyBack', {
            ...this.props.singleOrder.buyBack, 
            [input]:value
        })   
    }
   

    render() { 
        const {singleOrder={}}= this.props;
        const {buyBack={}}= singleOrder; 
        const {vin, brand, hp, engineNumber, engineType, fuelType, number, model, externalColor,
            bodyType,transmission,commercialGrade, externalColorDescription,internalColor,registrationDate,registrationNumber,mileage,price}= buyBack;

        const {errors={}} = this.props; 
        return (   
            <Grid container spacing={3}> 
                <Grid item md={3} xs={12}> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}}  
                        required 
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-rocket"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Шаси №"
                        name="vin"
                        value={vin}
                        error ={errors.vin?true:false} 
                        helperText={errors.vin} 
                        onChange={this.onChange}  
                    />  
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}}  
                        required 
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-rocket"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Марка"
                        name="brand"
                        value={brand}
                        error ={errors.brand?true:false} 
                        helperText={errors.brand} 
                        onChange={this.onChange}  
                    />  
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-start-up"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Модел"
                        name="model"  
                        value={model}
                        error ={errors.model?true:false} 
                        helperText={errors.model} 
                        onChange={this.onChange} 
                    />   
                    <FormControl variant="outlined" error={errors.fuelType?true:false} margin="normal" className='mb-20 full-width select-styles'>
                        <InputLabel id="fuelType">
                            Гориво 
                        </InputLabel>
                        <Select  
                            MenuProps={{
                                anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right"
                                },
                                transformOrigin: {
                                vertical: "top",
                                horizontal: "right"
                                },
                                getContentAnchorEl: null
                            }} 
                            labelId="fuelType"
                            required   
                            inputProps={{
                                name: 'fuelType', 
                            }} 
                            
                            value={fuelType} 
                            onChange={this.onChange}
                            labelWidth={70}  >    
                            <MenuItem value='1'>Бензин</MenuItem>  
                            <MenuItem value='2'>Дизел</MenuItem>  
                            <MenuItem value='3'>Метан</MenuItem>  
                            <MenuItem value='4'>Газ</MenuItem>  
                            <MenuItem value='5'>Електрически</MenuItem>  
                        </Select>
                        {errors.fuelType&&<FormHelperText>{errors.fuelType}</FormHelperText>}
                    </FormControl> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-start-up"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Скоростна кутия"
                        name="transmission"  
                        value={transmission}
                        error ={errors.transmission?true:false} 
                        helperText={errors.transmission} 
                        onChange={this.onChange} 
                    />   
                </Grid>   
                <Grid item md={3} xs={12}> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Номер:"
                        name="number"   
                        value={number}
                        error ={errors.number?true:false} 
                        helperText={errors.number} 
                        onChange={this.onChange} 
                    />   
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Рег. Номер:"
                        name="registrationNumber"   
                        value={registrationNumber}
                        error ={errors.registrationNumber?true:false} 
                        helperText={errors.registrationNumber} 
                        onChange={this.onChange} 
                    />  
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                        <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                            autoOk={true}
                            showTodayButton={true} 
                            InputLabelProps={{shrink: true}} 
                            className="full-width date mb-20"  
                            inputVariant="outlined" 
                            margin="normal"
                            label='Дата на регистрация:'
                            format="DD.MM.YYYY" 
                            value=''
                            inputValue={registrationDate} 
                            onChange={(e,d)=>this.onChangeDate('registrationDate', e ,d)}
                            rifmFormatter={this.dateFormatter} 
                            error ={errors.registrationDate?true:false}  
                            helperText={errors.registrationDate} 
                        />
                    </MuiPickersUtilsProvider> 
                    <FormControl variant="outlined" error={errors.bodyType?true:false} margin="normal" className='mb-20 full-width select-styles'>
                        <InputLabel id='bodyType'>
                            bodyType
                        </InputLabel>
                        <Select  
                            MenuProps={{anchorOrigin: {vertical: "bottom",horizontal:"right"},transformOrigin: { vertical: "top",horizontal: "right"},getContentAnchorEl: null}} 
                            labelId='bodyType'
                            required   
                            inputProps={{ name: 'bodyType'}}  
                            value={bodyType} 
                            onChange={this.onChange}  
                            labelWidth={100}  >    
                            <MenuItem value='1'>Седан</MenuItem>   
                            <MenuItem value='2'> Хечбек</MenuItem>   
                            <MenuItem value='3'> Лифтбек</MenuItem>   
                            <MenuItem value='4'> Комби</MenuItem>   
                            <MenuItem value='5'> Купе</MenuItem>   
                            <MenuItem value='6'> Кабриолет</MenuItem>   
                            <MenuItem value='7'> Лимузина</MenuItem>   
                            <MenuItem value='8'> SUV</MenuItem>   
                            <MenuItem value='9'> Кросоувър(CUV)</MenuItem>   
                            <MenuItem value='10'> Ван</MenuItem>   
                            <MenuItem value='11'> Миниван</MenuItem>   
                            <MenuItem value='12'> Шаси Кабина</MenuItem>   
                            <MenuItem value='13'> Фургон</MenuItem>   
                        </Select>
                        {errors.bodyType&&<FormHelperText>{errors.bodyType}</FormHelperText>}
                    </FormControl>   
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Цена:"
                        name="price"   
                        value={price}
                        error ={errors.price?true:false} 
                        helperText={errors.price} 
                        onChange={this.onChange} 
                    />   
                </Grid>    
                <Grid item md={3} xs={12}>   
                     
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-dashboard"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Мощност (KW/к.с.) :"
                        name="hp"   
                        value={hp}
                        error ={errors.hp?true:false} 
                        helperText={errors.hp} 
                        onChange={this.onChange} 
                    />  
                    
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-rocket"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Двигател"
                        name="engineType"   
                        value={engineType}
                        error ={errors.engineType?true:false} 
                        helperText={errors.engineType} 
                        onChange={this.onChange} 
                    /> 
                    
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-list-3"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Двигател №"
                        name="engineNumber"   
                        value={engineNumber}
                        error ={errors.engineNumber?true:false} 
                        helperText={errors.engineNumber} 
                        onChange={this.onChange} 
                    /> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-list-3"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Навъртяни километри"
                        name="mileage"   
                        value={mileage}
                        error ={errors.mileage?true:false} 
                        helperText={errors.mileage} 
                        onChange={this.onChange} 
                    /> 
                </Grid> 
                <Grid item md={3} xs={12}>
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-start-up"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Comercial Grade"
                        name="commercialGrade"  
                        value={commercialGrade}
                        error ={errors.commercialGrade?true:false} 
                        helperText={errors.commercialGrade} 
                        onChange={this.onChange} 
                    />  
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                        <i className="flaticon-notepad"></i>  
                            </InputAdornment>
                            ),
                        }}
                        label="Цвят"
                        name="externalColor"   
                        value={externalColor}
                        error ={errors.externalColor?true:false} 
                        helperText={errors.externalColor} 
                        onChange={this.onChange} 
                    />   
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                        <i className="flaticon-notepad"></i>  
                            </InputAdornment>
                            ),
                        }}
                        label="Цвят Описание"
                        name="externalColorDescription"   
                        value={externalColorDescription}
                        error ={errors.externalColorDescription?true:false} 
                        helperText={errors.externalColorDescription} 
                        onChange={this.onChange} 
                    />  
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                        <i className="flaticon-notepad"></i>  
                            </InputAdornment>
                            ),
                        }}
                        label="Цвят вътре"
                        name="internalColor"   
                        value={internalColor}
                        error ={errors.internalColor?true:false} 
                        helperText={errors.internalColor} 
                        onChange={this.onChange} 
                    />   
                </Grid>   
            
            </Grid> 
            
         );
    }
}
const mapStateToProps = (state, props) => {     
    return{ 
        companies:state.companies.list, 
        singleOrder:state.orders.singleOrder
    }
}

export default connect(mapStateToProps, {fetchSaleCarsAutoComplete, updateOrder })(BuyBack);  
 
 