import React from 'react'; 
import {downloadDocument} from '../_actions/downloadDocument'


export const options = {
    sortOrder: {
        name: 'id',
        direction: 'desc'
    },
    confirmFilters: true,  
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => { 
        return (
            <div style={{ marginTop: '40px' }}>
                <button className="btn btn-info small" onClick={()=>applyNewFilters()}>Приложи филтрите</button>
            </div>
        );
    },
    print: false,
    download: true,
    downloadOptions: {
        filename: 'Аgreements.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
        } 
    },
    viewColumns: true,
    filter:true, 
    selectableRows: "none", 
    customSort: (data, colIndex, order) => {  
        return data.sort((a, b) => {
            // return (a.data[colIndex].length < b.data[colIndex].length ? -1: 1 ) * (order === 'desc' ? 1 : -1);
            a = a.data[colIndex] || '';
            b = b.data[colIndex] || '';
            if (order === 'asc') {
                return a.toString().localeCompare(b, undefined, { numeric: true });
            } else if (order === 'desc') {
                return b.toString().localeCompare(a, undefined, { numeric: true });
            }
        })
    },  
    textLabels: {
        body: {
            noMatch: "Няма намерени резултати",
            toolTip: "Сортирай",
            columnHeaderTooltip: column => `Сортирай по ${column.label}`
        },
        pagination: {
            next: "Следваща страница",
            previous: "Предишна страница",
            rowsPerPage: "Брой записи на страница:",
            displayRows: "от",
        },
        toolbar: {
            search: "Търси",
            downloadCsv: "Download CSV",
            print: "Принтирай",
            viewColumns: "Колони",
            filterTable: "Филтри",
        },
        filter: {
            all: "Всички",
            title: "Филтри",
            reset: "Откажи",
        },
        viewColumns: {
            title: "Покажи Колони",
            titleAria: "Покажи/Скрии Колони",
        },
        selectedRows: {
            text: "запис/и са селектирани за изтриване",
            delete: "Изтриване",
            deleteAria: "Изтриите избраните записи",
        },
    }
}; 

export const columns = [ 
    {
        label: "ID:",
        name: "id",
        options: {
            filter: false,
            sort: true, 
           }
    },
    // {
    //     label: "Номер на договора:",
    //     name: "agreementNumber",
    //     options: {
    //         filter: false,
    //         sort: false,
    //        }
    // },
    {
        label: "Дата:",
        name: "contractDate",
        options: {
            filter: false,
            sort: true,
           }
    },
  
    {
        label: "Статус:",
        name: "status",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                if (value===0) {
                    return 'Създаден' 
                }else if (value===1) {
                    return 'Очаква одобрение' 
                }else if (value===2) {
                    return 'Одобрен' 
                }else if (value===3) {
                    return 'Подписан' 
                }
            }
        }
    },
    {
        label: "Клиент:",
        name: "client",
        options: {
            filter: false,
            sort: true,
           }
    },
    {
        label: "Контакт:",
        name: "contactName",
        options: {
            filter: true,
            sort: true,
           }
    },
    // {
    //     label: "Изпрати към Nav:",
    //     name: "nav",
    //     options: {
    //         filter: false,
    //         sort: false,
    //         customBodyRender: (value, tableMeta, updateValue) => {  
    //             return <div className='small btn'>Изпрати към Nav</div> 
    //         }
    //     }
    // },   
    {
        label: "Договор:",
        name: "agreementPdf",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {  
                let id = tableMeta.rowData[0];
                return <div onClick={()=>downloadDocument('contract',id)}
                className='small btn'><i className='fas fa-file-signature'></i>Виж договор</div>
            }
        }
    },   
    {
        label: "ППП:",
        name: "acceptanceActId",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {  
                if (value) {
                    return <div onClick={()=>downloadDocument('acceptance-act',value)}
                    className='small btn'><i className='fas fa-file-signature'></i>Свали ППП</div>
                }else{
                    return ''
                }
              
            }
        }
    },   
    // {
    //     label: "Редактирай:",
    //     name: "edit",
    //     options: {
    //         filter: false,
    //         sort: false, 
    //         customBodyRender: (value, tableMeta, updateValue) => {  
    //             let id = tableMeta.rowData[0];
    //             const permissions = JSON.parse(localStorage.getItem('permissions'))
    //             if (permissions.contractsUpdate) {
    //                 return <Link to={'/dashboard/agreements/'+id} className='small btn btn-info'><i className='flaticon-edit mr-0'></i>Редактирай</Link>
    //             }else{
    //                 return 'нямате достъп'
    //             }
                
    //         }
    //     }
    // },   
     
];
