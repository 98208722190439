const initialState = {
    items:[], 
    singleOffer:{},
    priceAndAcessoaries:[],
    tempOffer:{},
    modifiedOffers:[],
    singleModifiedOffer:{},
}

export default function (state = initialState, action){  
    const {type, payload} = action;  
    switch(type){
        case 'FETCH_OFFERS':  
            return{
                ...state,
                items:payload 
            } 
     
        case 'CREATE_OFFER':
            return { 
                ...state,
                items: [...state.items, payload]
            } 
         
        case 'FETCH_SINGLE_OFFER':  
        return{
            ...state,
            singleOffer:payload 
        } 
        case 'FETCH_MODIFIED_OFFERS':  
        const modified = payload.map(item=>{ 
            return {brandName:item.brand ? item.brand.name :'',...item}
        }) 
        return{
            ...state,
            modifiedOffers:modified 
        } 
        case 'FETCH_SINGLE_MODIFIED_OFFER':  
        return{
            ...state,
            singleModifiedOffer: payload
        } 
        case 'FETCH_PRICE_AND_ACCESSOARIES':  
        return{
            ...state,
            priceAndAcessoaries:payload 
        } 

        case 'SET_TEMP_OFFER':  
        return{
            ...state,
            tempOffer:payload 
        } 
        default:
            return state;
    }
}