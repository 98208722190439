const initialState = {
    items:[],
    drives_dates:[],
    singleTestDrive:{}
}

export default function (state = initialState, action){  
    const {type, payload} = action;   
    switch(type){
        case 'FETCH_DRIVELIST':  
            return{
                ...state,
                items:payload 
            } 
        case 'FETCH_SINGLE_DRIVELIST':  
            return{
                ...state,
                singleTestDrive:payload 
            } 
        case 'CREATE_DRIVELIST':  
            return { 
                ...state,
                items: [...state.items, payload]
            } 
        case 'EDIT_DRIVELIST':  
            return{ ...state, 
                items:state.items.map(testDrive => ( testDrive.id === payload.id ? payload : testDrive ))
            } 
        case 'RETURN_TESTDRIVE':  
            return{
                ...state,
                returnCar:payload 
            } 
        case 'RETURN_TESTDRIVE_DATES':  
            return{
                ...state,
                drives_dates:payload 
            } 
        
        default:
            return state;
    }
}