import { apiUrl} from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions"; 


export const fetchCars = (params) => dispatch => { 
    return fetch(apiUrl+'cars'+params, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, data} = response; 
        if (status === "success") {
            dispatch({
                type:'FETCH_CARS',
                payload:data
            })
        } 
        return response
    });
}

export const fetchCarsFiltered = () => dispatch => { 
   
    return fetch(apiUrl+'cars/autocomplete', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_CARS',
                payload:data
            })
        } 
        return response
    });
}

export const fetchFixiCars = () => dispatch => { 
    
    return fetch(apiUrl+'fixi-cars/autocomplete', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, data} = response;

        if (status === "success") {
            dispatch({
                type:'FETCH_FIXICARS',
                payload:data
            })
        } 
        return response
    });
}

export const createCar = (car) => dispatch => {  
    
    return fetch(apiUrl+'cars', { 
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(car)
     }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
     })
    .then(response => {    
        const {status, data, message} = response; 
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'CREATE_CAR',
                payload:data
            })
        } 
        return response
    }); 
 
}

export const editCar = (car) => dispatch => {  
    
    const {id}=car;
    return fetch(apiUrl+'cars/'+id, {
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(car)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, message} = response;


        if (status === "success") { 
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'EDIT_CAR',
                payload:car
            })
        } 
        return response
    });   
}