import React, { Component } from 'react'; 

import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
 

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  
import {columns, options} from "../../../helpers/viewBrands";   
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns";
import {fetchBrands} from '../../../_actions/brandsActions'


class BrandsList extends Component { 
    state={
        permissions: JSON.parse(localStorage.getItem('permissions')),
        roleId: JSON.parse(localStorage.getItem('roleId')),
        options:{},
        columns:[]
     }

    componentDidMount (){
        this.setState({
            options: storeColumnsFilters('brandsTable', options),
            columns: getStoredColumnsFilters('brandsTable', columns)
        }) 
        this.props.fetchBrands() 
    } 

    onDownload = (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
   
    render() {  
        const {brands} = this.props;  
        const {options, columns, permissions= {}, roleId} = this.state; 
        return (  
            <React.Fragment>  
                <Grid container className="mb-20">
                    <Grid container item xs={12} alignItems="center"> 
                        {(roleId!==19&&permissions.brandsStore)&&<Link className="btn btn-info" to="/dashboard/create-brand"><i className="flaticon-add-circular-button"/>
                            Добави нов Бранд
                        </Link>}  
                    </Grid>   
                
                </Grid> 
                   
                <Card>
                    <MUIDataTable
                        title={"Марки Автомобили:"}
                        data={brands}
                        columns={columns} 
                        options={{...options, onDownload:this.onDownload}}  
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
 
const mapStateToProps = state => {   
   return({brands:state.brands.items})
}

export default withRouter(connect(mapStateToProps, { fetchBrands })(BrandsList));
// export default ViewUsers;