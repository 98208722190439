import React, { Component } from 'react';
 
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";

import { CircularProgress } from '@material-ui/core';

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";    
import CustomToolbar from "../../../helpers/CustomToolbar";

import CustomFilterFoOffers from "./CustomFilterFoOffers";  

import {columns, options} from "../../../helpers/viewCarsSaleForOffer";  
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns";  

import {fetchSaleCarsForOffer , importSalesCars} from '../../../_actions/carsSaleActions'


class CarsSalesList extends Component {
    state={ 
        brandId:10,
        activeTab:0,
        isLoading:false,
        options:{},
        columns:[],
        filtersStatus:false,
        appliedFilters:false,
        clearFilters:false,
    }
    componentDidMount (){
        this.setState({
            options: storeColumnsFilters('carsSaleTable', options),
            columns: getStoredColumnsFilters('carsSaleTable', columns),
            isLoading:true
        })
        this.props.fetchSaleCarsForOffer().then(data => {  
            this.setState({isLoading:false}) 
        }) 

    } 

    onDownload = (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
    
    onRowClick=(rowData , rowMeta, e) => {    
        const query = new URLSearchParams(this.props.location.search);
        const offer_Id = query.get('offer_Id')
        if(offer_Id){
            this.props.history.push("/dashboard/offers/"+offer_Id+"/?selected_car="+rowData[0]);
        }else{
            this.props.history.push("/dashboard/create-offer?selected_car="+rowData[0]);
        }
      
    }


    openFilters = (conditon) =>{
        this.setState({filtersStatus:conditon})
     }
     appliedFilters = (conditon) =>{
         if (conditon) {
             this.setState({appliedFilters:conditon})
         }else{
             this.setState({
                 appliedFilters:conditon,
                 clearFilters:false
             })
         }
  
     }
     clearFilters = ()=>{
         this.setState({clearFilters:true})
     }
 
   
    render() {  
        const {isLoading} = this.state;  
        const {carsSale} = this.props;      
        const {options, columns, filtersStatus, appliedFilters, clearFilters} = this.state;  
        const loadingComponent = (
            <div style={{position: 'absolute', zIndex: 110, top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.8)'}}>
            <CircularProgress size={24} />
        </div>
        );
        return (  
            <React.Fragment> 
                <CustomFilterFoOffers 
                    clearFilters={clearFilters}
                    appliedFilters={this.appliedFilters}
                    openFilters={this.openFilters} 
                    filtersStatus={filtersStatus}>
                </CustomFilterFoOffers>
                <Card style={{'position':'relative'}}>
                    {isLoading && loadingComponent}
                    <MUIDataTable className="col-width"
                     title={<>
                        <h6 className="dataTable-title">Изберете автомобил за оферта:</h6> 
                        {appliedFilters&&<button onClick={this.clearFilters} className="clearFilters-btn">
                            <span className="text">Изчисти всички филтри</span> 
                            <i className="fas fa-times-circle"></i>
                        </button>}
                        </>} 
                        data={carsSale}
                        columns={columns} 
                        options={{
                            ...options, 
                            onRowClick: this.onRowClick,
                            onDownload:this.onDownload,
                            customToolbar: () => {
                                return (
                                    <CustomToolbar openFilters={this.openFilters}/>
                                );
                            }
                        }}   
                    />
                </Card> 
            </React.Fragment> 
         );
    }
}
 
const mapStateToProps = state => {   
   return({
       carsSale:state.carsSale.saleCarsForOffer,
       allowImport:state.brands.allowImport, 
    })
}

export default withRouter(connect(mapStateToProps, { fetchSaleCarsForOffer,importSalesCars })(CarsSalesList));
// export default ViewUsers;