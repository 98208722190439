import React, { Component } from 'react';
import {connect} from 'react-redux'
import Grid from '@material-ui/core/Grid'; 
import TextField from '@material-ui/core/TextField'; 
 
import {updateSingleSaleCarsState} from '../../../_actions/carsSaleActions'
import {salesCarsNames} from '../../../helpers/formsPermissions'
 
class Prices extends Component {
    
    state={
        data:this.props.data ? this.props.data :{}, 
    }  

    onChange = (event, index) =>{ 
        const {name,value} = event.target;  
        this.setState({
            data:{
                ...this.state.data,
                [name]:value
            }
        })   
    }
   
    componentWillUnmount(){
        this.props.updateSingleSaleCarsState('price', this.state.data)
    }

    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.data !== this.props.data){
            this.setState({
                data:this.props.data
            })
        }

        if(previousProps.pushToState !== this.props.pushToState){
            if (this.props.pushToState) {
                this.props.updateSingleSaleCarsState('price', this.state.data)
            } 
        }
    } 


   

    render() {  
        const {data} = this.state;  
        const {formUserPermissions={}, errors={}}=this.props;
        const {price={}} = errors
        return (  
            
            <>    
                <Grid container spacing={3}> 
                    {Object.keys(data).map((key, i) => {  
                 
                        if (formUserPermissions['price.'+key]) {
                            return(
                                <Grid item md={3} xs={12} key={i} style={{'order':key==='bonusConditions'?Object.keys(data).length+1:i}}>  
                                    {}
                                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                        InputLabelProps={{shrink: true}}  
                                        margin="normal" 
                                        variant="outlined"   
                                        label={salesCarsNames['price.'+key]}
                                        name={key}
                                        multiline={key==='bonusConditions'?true:false}
                                        rows={key==='bonusConditions'?'5':'1'}
                                        value={data[key]}
                                        error ={price[key]?true:false} 
                                        helperText={price[key]} 
                                        onChange={(e)=>this.onChange(e, i)}  
                                    />
                                </Grid>   
                            ) 
                        } 
                    })} 
                </Grid>  
                 
            </>
         );
    }
}

const mapStateToProps = (state, props) => {  
    return{ 
        formUserPermissions:state.roles.formUserPermissions,  
    } 
}
export default connect(mapStateToProps, { updateSingleSaleCarsState})(Prices);
 
 

 