const initialState = {
    items:[]
}

export default function (state = initialState, action){
    const {type, payload} = action;  
    switch(type){
        case 'FETCH_HISTORY':  
            return{
                ...state,
                items:payload 
            }
        case 'FETCH_HISTORY_SINGLE':
            return{
                ...state,
                item:payload 
            }
        default:
            return state;
    }
}