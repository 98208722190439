import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Autocomplete from '@material-ui/lab/Autocomplete';

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";  


import {connect} from 'react-redux'
import {createUser} from '../../../_actions/userActions'
import {getCompaniesList} from '../../../_actions/companiesActions' 
import {fetchRoles} from '../../../_actions/rolesActions' 

class CreateUser extends Component {
    state={
        name:'',
        email:'',
        phone:'',
        company:'',
        city:'',
        password:'',
        roles:[],
        rolesIds:[],
        roleList:[],
        code:'',
        errors:[],
        systemRoleId: JSON.parse(localStorage.getItem('roleId'))
        
    }

    componentDidMount(){ 
        this.props.getCompaniesList();   
        this.props.fetchRoles().then(data => {
            if(data.status==="success"){ 
                this.setState({roleList:data.data})  
            }      
        })  
    } 

    onChange = (event) =>{
        const {name,value} = event.target;
        this.setState({
            [name]:value
        }) 
    }

    onChangeCompany = (event, value, reason) =>{    
        this.setState({
            company:value
        })  
    }

    onSubmit = (event) =>{
        event.preventDefault(); 
        this.props.createUser(this.state).then(data => {
            if(data.status==="success"){ 
                this.setState({
                    name:'',email:'',rolesIds:[],roles:[],code:'', phone:'',city:'',company:'',password:'',roleId:'',errors:[]
                })  
            } 
            else if (data.errors) {
                this.setState({errors:data.errors})   
            }   
        }) 
    }

    
    handleChangeMultiple = (event) => {   
        const selectedRoleName = event.target.value.map(item=> (
                {
                    id:item,
                    name:this.state.roleList.find(x => x.id === item).name
                }
            )
        ) 
        this.setState({
            rolesIds:event.target.value,
            roles:selectedRoleName
        }) 
    };
    render() { 
        const {roleList,code,rolesIds,name,email,phone,company,password,city,roleId,errors,systemRoleId}= this.state;
        const {companies} = this.props;
        return (  
            <React.Fragment>   
                <Card >
                    <Grid container className="section-title-wrapper padding"> 
                        <Grid container item xs={8} alignItems="center"> 
                            <h3 className="section-title">
                                <i className="flaticon2-gear"></i>Създаване на потребител:
                            </h3> 
                        </Grid>  
                        <Grid container item md={4} xs={4} alignItems="center" justify="flex-end">  
                            <Link  
                                className="btn btn-secondary"
                                to="/dashboard/users" 
                                ><i className="flaticon2-circular-arrow"/>
                                    <span>Назад</span> 
                            </Link>  
                        </Grid>  
                    </Grid> 
                    <Grid container className="padding">    
                        <form  className='form' noValidate onSubmit={this.onSubmit}>
                        <Grid container spacing={3}> 
                            <Grid item md={4} xs={12}> 
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                                    <i className="flaticon2-user"></i>  
                                        </InputAdornment>
                                        ),
                                    }}
                                    label="Име"
                                    name="name"
                                    value={name} 
                                    error={errors.name?true:false} 
                                    helperText={errors.name} 
                                    onChange={this.onChange}  
                                />    
                                
                                <Autocomplete
                                    freeSolo 
                                    name="company"  
                                    inputValue={company} 
                                    options={companies}  
                                    onInputChange={this.onChangeCompany}  
                                    getOptionLabel={option => option.name ? option.name:option} 
                                    renderInput={params => ( 
                                        <TextField 
                                        {...params}
                                        className="full-width mb-20 " 
                                        InputLabelProps={{shrink: true}} 
                                        margin="normal" 
                                        variant="outlined" 
                                        label="Компания"
                                        name="company"    
                                        error ={errors.company?true:false} 
                                        helperText={errors.company}  
                                    />  
                                    )}
                                /> 
                            </Grid>    
                            
                            <Grid item md={4} xs={12}> 
                            <FormControl variant="outlined" error={errors.roles?true:false}  margin="normal" className='mb-20 full-width select-styles'>
                                    <InputLabel id="role">
                                        Изберете роля
                                    </InputLabel> 
                                    <Select 
                                        multiple
                                        MenuProps={{
                                            anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "right"
                                            },
                                            transformOrigin: {
                                            vertical: "top",
                                            horizontal: "right"
                                            },
                                            getContentAnchorEl: null
                                        }}  
                                        required   
                                        value={rolesIds}
                                        renderValue={(selected) =>{
                                            let names = selected.map(item=>{ 
                                                return roleList.length>0&&roleList.find(x => x.id === item).name
                                            })
                                            return names.join(", ")
                                          
                                        }} 
                                        onChange={this.handleChangeMultiple}
                                        labelWidth={120}>   
                                        
                                        {roleList.map(role=>{
                                            return <MenuItem key={role.id} disabled ={systemRoleId !== 1 &&role.id==1} value={role.id}>{role.name}</MenuItem>
                                        })} 
                                    </Select>
                                    {errors.roles&&<FormHelperText>{errors.roles}</FormHelperText>}
                                </FormControl>
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-shield"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Парола"
                                    name="password"  
                                    value={password}
                                    error={errors.password?true:false}  
                                    helperText={errors.password} 
                                    onChange={this.onChange} 
                                />  
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                        InputLabelProps={{shrink: true}} 
                                        required 
                                        margin="normal" 
                                        variant="outlined"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="flaticon2-architecture-and-city"></i>  
                                                </InputAdornment>
                                            ),
                                        }}
                                    label="Град"
                                    name="city"  
                                    value={city} 
                                    error={errors.city?true:false} 
                                    helperText={errors.city} 
                                    onChange={this.onChange} 
                                />  
                            </Grid>  
                            <Grid item md={4} xs={12}>
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-phone"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Телефон"
                                    name="phone"   
                                    value={phone}
                                    error={errors.phone?true:false} 
                                    helperText={errors.phone} 
                                    onChange={this.onChange} 
                                />   
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                        required 
                                        margin="normal" 
                                        variant="outlined"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="flaticon2-envelope"></i>  
                                                </InputAdornment>
                                            ),
                                        }}
                                    label="E-mail"
                                    name="email"  
                                    value={email}
                                    error={errors.email?true:false}  
                                    helperText={errors.email} 
                                    onChange={this.onChange} 
                                />  
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                        required 
                                        margin="normal" 
                                        variant="outlined"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fas fa-terminal"></i> 
                                                </InputAdornment>
                                            ),
                                        }}
                                    label="Код на Търговец"
                                    name="code"  
                                    value={code}
                                    error={errors.code?true:false}  
                                    helperText={errors.code} 
                                    onChange={this.onChange} 
                                />  
                            </Grid>  
                        </Grid>    
                        <Grid container spacing={2}>   
                            <Grid container item md={4} xs={12} >    
                                {systemRoleId!==19&&<button type="submit" className="btn btn-info full-width">
                                    <i className="flaticon2-add-square"></i>Добави
                                </button>}   
                            </Grid> 
                        </Grid> 
                        </form>
                    </Grid>    
                </Card> 
            </React.Fragment>
         );
    }
}
const mapStateToProps = (state, props) => {    
    return{ 
        companies:state.companies.list
    }   
 }
// export default CreateUser; 
export default connect(mapStateToProps, {createUser, fetchRoles, getCompaniesList})(CreateUser);
 