const initialState = {
    items:[],
    singleAgreement:{},
    leasingCompanies:[],
    agreementInProgress:{}
}

export default function (state = initialState, action){  
    const {type, payload} = action; 
    switch(type){
        case 'FETCH_AGREEMENTS':  
            return{
                ...state,
                items:payload 
            } 
        case 'FETCH_SINGLE_AGREEMENT':  
            return{
                ...state,
                singleAgreement:payload 
            } 
        case 'FETCH_AGREEMENTS_LEASING_COMPANIES':  
            return{
                ...state,
                leasingCompanies:payload 
            } 
        case 'FETCH_AGREEMENTS_CARS':  
            return{
                ...state,
                leasingCompanies:payload 
            } 
        case 'CREATE_AGREEMENT':
            return { 
                ...state,
                items: [...state.items, payload]
            } 
        case 'EDIT_AGREEMENT': 
            return{ ...state, 
                items:state.items.map(aggr => ( aggr.id === payload.id ? payload : aggr ))
            } 
        case 'AGREEMENT_IN_PROGRESS':  
            const{obj, data}= payload
            if (obj==='none') {
                return{ 
                    ...state,
                    agreementInProgress:{
                        hasLeasing:false,
                        hasAdditionalClient:false,
                        ...state.agreementInProgress,
                        ...data,
                    } 
                } 
            }else{
                return{ 
                    ...state,
                    agreementInProgress:{
                        hasLeasing:false,
                        hasAdditionalClient:false, 
                        ...state.agreementInProgress,
                        [obj]:data 
                    }
                }
            }
        case 'CLEAR_AGREEMENT_IN_PROGRESS':  
            return{ 
                ...state,
                agreementInProgress:{}
            } 
        default:
            return state;
    }
}