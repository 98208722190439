const initialState = {
    items:[],
    singleBrand:{},
    brandsList:{},
    allowImport:[]
}

export default function (state = initialState, action){  
    const {type, payload} = action; 
    switch(type){
        case 'FETCH_BRANDS':  
            return{
                ...state,
                items:payload 
            } 
        case 'FETCH_BRANDS_LIST':  
            return{
                ...state,
                brandsList:payload 
            } 
        case 'FETCH_SINGLE_BRAND':   
            return{
                ...state,
                singleBrand:payload 
            } 
        case 'BRANDS_ALLOW_IMPORT':  
            return{
                ...state,
                allowImport:payload 
            } 
        case 'CREATE_BRAND':
            return { 
                ...state,
                items: [...state.items, payload]
            } 
        case 'EDIT_BRAND': 
            return{ ...state, 
                items:state.items.map(brand => ( brand.id === brand.id ? payload : brand ))
            }  
        default:
            return state;
    }
}