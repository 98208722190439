export const setMessage = (msg) => dispatch => {  
    dispatch({
        type:'SET_MSG',
        payload:msg
    })
  
}
export const removeMessage = () => dispatch => {  
    setTimeout(()=> {
        dispatch({
            type:'REMOVE_MSG',
            payload:''
        })
    }, 2000);
  
}

export const setErrorMessage = (id) => dispatch => {   
    if (id=='500') {
        dispatch({type:'SET_ERROR_MSG', payload:'Има технически проблем обърнете се към администратор !'})
        return
    }else if(id=='400'){
        dispatch({type:'SET_ERROR_MSG', payload:'Oпитвате се да достъпите нещо които не съществува !'})  
    }else if(id=='401'){
        dispatch({type:'SET_ERROR_MSG', payload:'Нямате право да достъпвате този ресурс !'})
        dispatch({type:'LOGOUT', payload:''})
    }else if(id=='413'){
        dispatch({type:'SET_ERROR_MSG', payload:'Моля качете по-малка снимка!'})
    }else if(id=='403'){ 
        dispatch({type:'LOGOUT',payload:''}) 
    }else if(id=='404'){
        // dispatch({type:'SET_ERROR_MSG',payload:'Not Found!'})
        return
    }  
}

export const removeErrorMessage = () => dispatch => {  
    setTimeout(()=> {
        dispatch({
            type:'REMOVE_ERROR_MSG',
            payload:''
        })
    }, 2000);
  
}
