import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import MomentUtils from "@date-io/moment";
import moment from "moment";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";

import {setContractDocument, createAgreement, fetchSingleAgreement} from '../../../_actions/agreementsActions'
import {getAcceptanceAct, downloadAcceptanceAct, uploadAcceptanceAct, createAcceptance} from '../../../_actions/acceptanceActions'
import {downloadDocument} from '../../../_actions/downloadDocument'

class Contract extends Component {
    state = { 
        contract:{
            orderId: this.props.match.params._id,
            contractDate:''
        },
        acceptance:{
            orderId: this.props.match.params._id,
            acceptanceDate:'',
            details: [
                {description:"Втори резервен ключ – 1бр."},
                {description:"Ремонтен комплект / пяна, компресор /"},
                {description:"Талони за регистрация на МПС – 2бр."},
                {description:"ГТП – 1бр."},
            ]
        },
        hasSignedContract:false,
        contractId:'',
        acceptanceActId:'',
        roleId: JSON.parse(localStorage.getItem('roleId'))
    }
      
    componentDidMount (){ 
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        this.setState({
            contractDate:dd+'.'+mm+'.'+yyyy
        }) 
        if (this.props.singleOrder) {
            this.checkForData()
        }

    }

    componentDidUpdate(previousProps, previousState){    
        if(previousProps.singleOrder !== this.props.singleOrder){   
            this.checkForData()
        }    
    }

    checkForData = ()=>{
        const {contractId,acceptanceActId}= this.props.singleOrder;
        let contractdate = ""
        if (Boolean(contractId)) {
            this.props.fetchSingleAgreement(contractId).then(data => { 
                if(data.status==="success"){ 
                    const{contractDate, hasSignedContract} = data.data
                    contractdate=contractDate
                    this.updateState(contractId, acceptanceActId , contractdate , hasSignedContract)
                }
            })
        }else{
            this.updateState(contractId, acceptanceActId , contractdate)
        }
    }

    updateState = (contractId,acceptanceActId , contractdate, hasSignedContract) =>{
        this.setState({
            contract:{
                ...this.state.contract,
                contractDate:contractdate
            },
            contractId:contractId,
            acceptanceActId:acceptanceActId,
            hasSignedContract:hasSignedContract
        },()=>{
            if (acceptanceActId) {
                this.props.getAcceptanceAct(acceptanceActId).then(data => { 
                    if(data.status==="success"){ 
                        this.setState({
                            acceptance:{
                                ...this.state.acceptance,
                                acceptanceDate:data.data.acceptanceDate,
                                details:data.data.details
                            } 
                        }) 
                    }
                })
            }
         
        }) 
    }
 
    addFile = (event, type) =>{
        const {contractId}= this.state;
        this.props.setContractDocument(contractId,event.target.files[0])
    }

    uploadPPP = (event, type) =>{
        const {orderId}= this.state.acceptance;
       
        this.props.uploadAcceptanceAct(orderId,event.target.files[0])
    }
 

    onChangeDate = (type,input, date, value) => {   
 
        this.setState({ 
            [type]:{
                ...this.state[type],
                [input]:value  
            } 
        })  
    }

    handleRemove = (i) => {
        const list = [...this.state.acceptance.details];
        list.splice(i, 1);
        this.setState({
            acceptance:{
                ...this.state.acceptance,
                details:list
            } 
        })
    }

    onChangeDinamic = (e, index) => { 
        const { name, value } = e.target;
        const list = [...this.state.acceptance.details];
        list[index][name] = value; 
        this.setState({
            acceptance:{
                ...this.state.acceptance,
                details:list
            } 
        })
    };

    handleAdd = ()=>{
        this.setState({ 
            acceptance:{
                ...this.state.acceptance,
                details:[
                    ...this.state.acceptance.details, 
                    {description:""}
                ] 
            } 
        })
    }

    createContract = () => { 
        this.props.createAgreement(this.state.contract).then(data => { 
            if(data.status==="success"){ 
                downloadDocument('contract',data.data.id)
            }    
        })
    }
    createPPP = () => { 
        this.props.createAcceptance(this.state.acceptance).then(data => { 
            if(data.status==="success"){ 
                downloadDocument('acceptance-act',data.data.id)
            }    
        })
        
    }

    downloadContract = () =>{
        const {contractId} = this.state; 
        downloadDocument('contract',contractId)
    }
    downloadPPP = () =>{
        const {acceptanceActId} = this.state; 
        downloadDocument('acceptance-act',acceptanceActId)
    }


    render() { 
        const {contractDate} = this.state.contract; 
        const {acceptanceDate, details} = this.state.acceptance; 
        const {contractId, acceptanceActId , roleId} = this.state;  
        return ( 
            <>
                <Grid container spacing={3} >  
                    <Grid item md={3} xs={12}>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                            <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                                autoOk={true}
                                showTodayButton={true} 
                                InputLabelProps={{shrink: true}} 
                                className="full-width date"  
                                inputVariant="outlined" 
                                margin="normal"
                                label='Дата на договора:' 
                                format="DD.MM.YYYY" 
                                value=''
                                disabled= {Boolean(contractId)}
                                inputValue={contractDate} 
                                onChange={(e,d)=>this.onChangeDate('contract','contractDate',e ,d)}
                                rifmFormatter={this.dateFormatter} 
                                error ={false}  
                                helperText=''
                            />
                        </MuiPickersUtilsProvider>
                        {roleId!==19&&<button type="button" style={{'marginTop':'18px'}}disabled={Boolean(contractId)} onClick={this.createContract} className="btn btn-info full-width">
                            Издай Договор
                        </button>}
                      
                    </Grid>
                    <Grid item md={3} xs={12}>  
                  
                        {roleId!==19&&Boolean(contractId)&&<div className='btn uploadDocumentWrap full-width'style={{ 
                            }}> 
                            <input onChange={(e)=> {this.addFile(e, 1); e.target.value=null }}   name="file" type="file" className="btn btn-warning full-width"/>   
                            <i className="fas fa-upload"></i>
                            Качи договор 
                        </div>}
                        
                        {Boolean(contractId)&&<div onClick={this.downloadContract} className="btn btn-info full-width">
                            <i className="fas fa-download"></i>Свали договор
                        </div>}
                    </Grid> 
                    <Grid item md={3} xs={12}> 
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                            <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                                autoOk={true}
                                showTodayButton={true} 
                                InputLabelProps={{shrink: true}} 
                                className="full-width date"  
                                inputVariant="outlined" 
                                margin="normal"
                                disabled= {Boolean(acceptanceActId)}
                                label='Дата на ППП:' 
                                format="DD.MM.YYYY" 
                                value=''
                                inputValue={acceptanceDate} 
                                onChange={(e,d)=>this.onChangeDate('acceptance','acceptanceDate',e ,d)}
                                rifmFormatter={this.dateFormatter} 
                                error ={false}  
                                helperText=''
                            />
                        </MuiPickersUtilsProvider>
                        {details.map((item, i) =>{ 
                            return(  
                                <div className="aditional-accessory" key={i}> 
                                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                                        InputLabelProps={{shrink: true}} 
                                        label="Артикул" 
                                        margin="normal" 
                                        disabled= {Boolean(acceptanceActId)}
                                        variant="outlined"    
                                        name="description"
                                        value={item.description}
                                        onChange={e=>this.onChangeDinamic(e, i)}  
                                    />  
                                    {!Boolean(acceptanceActId)&&
                                    <>
                                    <div className="btn-box">
                                        {details.length !== 1 &&
                                        <div className='delete' onClick={()=>this.handleRemove(i)}>
                                            <i className="flaticon2-delete"></i>  
                                        </div>}
                                        {details.length - 1 === i && 
                                        <div className='add' onClick={this.handleAdd}>
                                            +
                                        </div>}
                                    </div>
                                    </>}
                                </div>
                            )
                        })}   
                        {roleId!==19&&<button type="button" style={{'marginTop':'18px'}}disabled={Boolean(acceptanceActId)} onClick={this.createPPP} className="btn btn-info full-width">
                            Създай ППП
                        </button> }
                    </Grid>
                    <Grid item md={3} xs={12}> 
                        {Boolean(acceptanceActId)&&<div className='btn uploadDocumentWrap full-width'style={{ 
                            }}> 
                            <input onChange={(e)=> {this.uploadPPP(e, 1); e.target.value=null }}   name="file" type="file" className="btn btn-warning full-width"/>   
                            <i className="fas fa-upload"></i>
                            Качи ППП 
                        </div>} 
                        {acceptanceActId&&<div onClick={this.downloadPPP} className="btn btn-info full-width">
                            <i className="fas fa-download"></i>Свали ППП
                        </div>}
                    </Grid>
                </Grid>
            </>
         );
    }
}

const mapStateToProps = (state, props) => {   
    return{  
        singleOrder:state.orders.singleOrder,  
    }
}

export default withRouter(connect(mapStateToProps, {setContractDocument,fetchSingleAgreement,downloadAcceptanceAct, uploadAcceptanceAct, createAgreement, getAcceptanceAct,createAcceptance })(Contract));   
