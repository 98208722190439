import React, { Component } from 'react';

import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'; 
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'; 
import FormLabel from '@material-ui/core/FormLabel';

import Autocomplete from '@material-ui/lab/Autocomplete';

import {updateOrder, setOrderDocument, getOrderDocument, sendOrderIdToNav} from '../../../_actions/ordersActions'
import {getCompaniesList, fetchResponsibilityCenters,fetchCompanyObjects, fetchSingleCompany} from '../../../_actions/companiesActions'



import MomentUtils from "@date-io/moment";
import moment from "moment";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';

import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
 
  
class ОrderInfo extends Component {
    state = {  
        crcIdOptions:[],
        companyObjects:[],
        editMode:false,
        banks:[],
        bank:''
     }
     
     componentDidMount(){ 
        if (this.props.match.params._id) { 
            this.setState({editMode:true})    
            // this.props.getOrderDocument(this.props.match.params._id) 
        } 
        this.props.getCompaniesList();   
        if (this.props.singleOrder.companyId) {
            this.props.fetchSingleCompany(this.props.singleOrder.companyId).then(data => {
                if(data.status==="success"){
                    const {banks, eik} = data.data  
                    this.setState({banks}) 
                    this.props.updateOrder('none', {eik})
                }
            })
            this.props.fetchResponsibilityCenters(this.props.singleOrder.companyId).then(data => {
                if(data.status==="success"){
                    this.setState({crcIdOptions:data.data})
                }
            })
            this.props.fetchCompanyObjects(this.props.singleOrder.companyId).then(data => {
                if(data.status==="success"){
                    this.setState({companyObjects:data.data})
                }
            })
        }
    }     

     onChange = (event) =>{
        const {name,value} = event.target;  
        if (name=='depositBgn') {
            this.props.updateOrder('none', {
                [name]:value,
                depositEur: Number(value*0.511292).toFixed(2)
             
            }) 
        }else if (name=='tradeFeeBgn') {
            this.props.updateOrder('none', {
                [name]:value,
                tradeFeeEur: Number(value*0.511292).toFixed(2)
             
            }) 
        }else{
            this.props.updateOrder('none', {
                [name]:value
            })
        }
        
    } 

    onChangeCompanyId = (event, value, reason) =>{   
        if (value) {  
            const {id} = value
            this.props.updateOrder('none', {
                companyId:id
            })  
            this.props.fetchResponsibilityCenters(id).then(data => {
                if(data.status==="success"){
                    this.setState({crcIdOptions:data.data})
                }else{
                    this.setState({crcIdOptions:[]})
                }
            })
            this.props.fetchCompanyObjects(id).then(data => {
                if(data.status==="success"){
                    this.setState({companyObjects:data.data})
                }else{
                    this.setState({companyObjects:[]})
                }
            })
        }else{
            this.props.updateOrder('none', {
                companyId:''
            })   
            this.setState({crcIdOptions:[]})
        } 
    }
    onChangeCompany = (event, value, reason) =>{   
        if(event || value){ 
            this.props.updateOrder('none', {
                company:value
            }) 
        }
    }
    changeProformaRecipient = (event) =>{   
        const {value} = event.target;
        this.props.updateOrder('none', {
            proformaRecipient:value
        })  
    }

    onChangeDate = (input, date, value) => {   
        this.props.updateOrder('none', {
            [input]:value 
        })   
    }
    onChangeBank = (event)=>{
        const {banks}=this.state;
        const {value}=event.target;
        const selectedBank= banks.find(item => item.id === value) 
        const {iban,bic,id, currency}= selectedBank
        this.props.updateOrder('none', {
            iban,bic,companyBankId:id,currency
        })
    }
 
    addOrder = (event) =>{
        this.props.setOrderDocument(this.props.match.params._id, event.target.files[0]).then(data => {
            if(data.status==="success"){
                this.props.getOrderDocument(this.props.match.params._id)
            }
        })
    }
    
   
    render() { 
        const {errors={}, companies, singleOrder={}} = this.props;
        const {crcIdOptions,companyObjects, editMode, banks=[]} = this.state; 
        const {crcId='', companyObjectId='', navId='', company='',orderDate='',insuranceName='', deliveryTime='', deliveryTimeAfterCome='',priceBgn='', priceEur='', vatBgn='',vatEur='',totalPriceIncVatBgn='',totalPriceIncVatEur='',tradeFeeBgn='',tradeFeeEur='',status='', depositBgn='', depositEur='', hasSignedOrder='',bic='', iban='',companyBankId='', client={}, leasingCompany, hasLeasing} = singleOrder;   
        const {proformaRecipient} = singleOrder
        const {name:clientName}= client
        let selectedBank = {}
        banks ? selectedBank = banks.find(item => item.id === companyBankId) :selectedBank = {}
        const {currency}= selectedBank ||{}
        return (        
        <>
        <br/>
        <br/> 
            <Grid container spacing={3}>    
                <Grid item md={3} xs={12}> 
                    <Autocomplete
                        // freeSolo   
                        disabled
                        options={companies}  
                        inputValue={company}
                        onChange={this.onChangeCompanyId} 
                        onInputChange={this.onChangeCompany} 
                        getOptionLabel={option => option.name} 
                        renderInput={params => ( 
                            <TextField
                                {...params}
                                className="full-width mb-20 " 
                                InputLabelProps={{shrink: true}} 
                                margin="normal" 
                                variant="outlined" 
                                label="Компания"  
                                error={errors.companyId?true:false} 
                                helperText={errors.companyId}   
                            />  
                        )}
                    />   
                </Grid>
                <Grid item md={3} xs={12}> 
               {crcIdOptions.length>0&&<FormControl variant="outlined" error={errors.crcId?true:false} margin="normal" className='mb-20 full-width select-styles'>
                        <InputLabel id="crcId">
                            Център 
                        </InputLabel>
                        <Select  
                            MenuProps={{
                                anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right"
                                },
                                transformOrigin: {
                                vertical: "top",
                                horizontal: "right"
                                },
                                getContentAnchorEl: null
                            }} 
                            labelId="crcId"
                            required   
                            inputProps={{
                                name: 'crcId', 
                            }}  
                            value={crcId} 
                            onChange={this.onChange}
                            labelWidth={70}  >    
                            {crcIdOptions.map(option=>{ 
                                return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>  
                            })}
                        
                        </Select>
                        {errors.crcId&&<FormHelperText>{errors.crcId}</FormHelperText>}
                    </FormControl>}   
                </Grid>
                <Grid item md={3} xs={12}> 
               {companyObjects.length>0&&<FormControl variant="outlined" error={errors.crcId?true:false} margin="normal" className='mb-20 full-width select-styles'>
                        <InputLabel id="crcId">
                            Oбект 
                        </InputLabel>
                        <Select  
                            MenuProps={{
                                anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right"
                                },
                                transformOrigin: {
                                vertical: "top",
                                horizontal: "right"
                                },
                                getContentAnchorEl: null
                            }} 
                            labelId="companyObjectId"
                            required   
                            inputProps={{
                                name: 'companyObjectId', 
                            }}  
                            value={companyObjectId} 
                            onChange={this.onChange}
                            labelWidth={60}  >    
                            {companyObjects.map(option=>{ 
                                return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>  
                            })}
                        
                        </Select>
                        {errors.companyObjectId&&<FormHelperText>{errors.companyObjectId}</FormHelperText>}
                    </FormControl>}   
                </Grid>
            </Grid>
            <Grid container spacing={3}>    
                <Grid item md={3} xs={12}> 
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                        <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                            autoOk={true}
                            showTodayButton={true} 
                            InputLabelProps={{shrink: true}} 
                            className="full-width date mb-20"  
                            inputVariant="outlined" 
                            margin="normal"
                            label='Дата на договор' 
                            format="DD.MM.YYYY" 
                            value=''
                            inputValue={orderDate} 
                            onChange={(e,d)=>this.onChangeDate('orderDate', e ,d)}
                            rifmFormatter={this.dateFormatter} 
                            error ={errors.orderDate?true:false}  
                            helperText={errors.orderDate} 
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                
                <Grid item md={3} xs={12}>  
                    <FormControl variant="outlined" error={errors.status?true:false} margin="normal" className='full-width select-styles'>
                        <InputLabel id="status">
                            Статус 
                        </InputLabel>
                        <Select  
                            MenuProps={{
                                anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right"
                                },
                                transformOrigin: {
                                vertical: "top",
                                horizontal: "right"
                                },
                                getContentAnchorEl: null
                            }} 
                            labelId="status"
                            required   
                            inputProps={{
                                name: 'status', 
                            }} 
                            disabled
                            value={status} 
                            onChange={this.onChange}
                            labelWidth={70}>    
                            <MenuItem value={1}>Създаден</MenuItem>  
                            <MenuItem value={2}>Очаква одобрение</MenuItem>  
                            <MenuItem value={3}>Одобрен</MenuItem>  
                            <MenuItem value={4}>Подписан</MenuItem>  
                            <MenuItem value={5}>Авансово Плащане</MenuItem>  
                            <MenuItem value={6}>Платил</MenuItem>  
                        </Select>
                        {errors.status&&<FormHelperText>{errors.status}</FormHelperText>}
                    </FormControl> 
                </Grid>
                <Grid item md={3} xs={12}>  
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled={true}
                        margin="normal" 
                        variant="outlined"    
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="НАВ №:"
                        name="navId" 
                        value={navId} 
                    />   
                </Grid>
                <Grid item md={3} xs={12}>  
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled={true}
                        margin="normal" 
                        variant="outlined"    
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                     <i className="fas fa-terminal"></i> 
                                </InputAdornment>
                            ),
                        }}
                        label="Код на Търговец:"
                        name="code" 
                        value={localStorage.getItem('code')} 
                    />   
                </Grid>
            </Grid>
            <br/><br/>
            <Grid container spacing={3}>    
                <Grid item md={6} xs={12}> 
                    <Alert severity="info">Цена нето на автомобил с допълнително оборудване и търговска отстъпка.</Alert> 
                    <Grid container spacing={3}> 
                        <Grid item md={6} xs={12}> 
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                                InputLabelProps={{shrink: true}} 
                                required  
                                disabled={true}
                                margin="normal" 
                                variant="outlined"    
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="flaticon-notepad"></i>  
                                        </InputAdornment>
                                    ),
                                }}
                                label="Цена нето на автомобил в лв.:"
                                name="priceBgn" 
                                value={priceBgn}
                                error ={errors.priceBgn?true:false} 
                                helperText={errors.priceBgn} 
                                onChange={this.onChange}  
                            />   
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                                InputLabelProps={{shrink: true}} 
                                required 
                                disabled={true}
                                margin="normal" 
                                variant="outlined"    
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="flaticon-notepad"></i>  
                                        </InputAdornment>
                                    ),
                                }}
                                label="Цена нето на автомобил в евро:"
                                name="priceEur" 
                                value={priceEur}
                                error ={errors.priceEur?true:false} 
                                helperText={errors.priceEur} 
                                onChange={this.onChange}  
                            />    
                                
                        </Grid> 
                        <Grid item md={6} xs={12}> 
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                                InputLabelProps={{shrink: true}} 
                                required 
                                disabled={true}
                                margin="normal" 
                                variant="outlined"    
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="flaticon-notepad"></i>  
                                        </InputAdornment>
                                    ),
                                }}
                                label="ДДС 20% в лв.:"
                                name="vatBgn" 
                                value={vatBgn}
                                error ={errors.vatBgn?true:false} 
                                helperText={errors.vatBgn} 
                                onChange={this.onChange}  
                            />   
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                                InputLabelProps={{shrink: true}} 
                                required 
                                disabled={true}
                                margin="normal" 
                                variant="outlined"    
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="flaticon-notepad"></i>  
                                        </InputAdornment>
                                    ),
                                }}
                                label="ДДС 20% в евро:"
                                name="vatEur" 
                                value={vatEur}
                                error ={errors.vatEur?true:false} 
                                helperText={errors.vatEur} 
                                onChange={this.onChange}  
                            />   
                        </Grid> 
                    </Grid> 
                </Grid> 
                <Grid item md={6} xs={12}> 
                    <Alert severity="info">Крайна цена на автомобил с вкл. ДДС, търговска отстъпка и продуктова такса.</Alert>    
                    <Grid container spacing={3}>     
                        <Grid item md={6} xs={12}> 
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                                InputLabelProps={{shrink: true}} 
                                required 
                                disabled={true}
                                margin="normal" 
                                variant="outlined"    
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="flaticon-notepad"></i>  
                                        </InputAdornment>
                                    ),
                                }}
                                label="Крайна цена с вкл. ДДС в лв.:"
                                name="totalPriceIncVatBgn" 
                                value={totalPriceIncVatBgn}
                                error ={errors.totalPriceIncVatBgn?true:false} 
                                helperText={errors.totalPriceIncVatBgn} 
                                onChange={this.onChange}  
                            />   
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                                InputLabelProps={{shrink: true}} 
                                required 
                                disabled={true}
                                margin="normal" 
                                variant="outlined"    
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="flaticon-notepad"></i>  
                                        </InputAdornment>
                                    ),
                                }}
                                label="Крайна цена с вкл. ДДС в евро:"
                                name="totalPriceIncVatEur" 
                                value={totalPriceIncVatEur}
                                error ={errors.totalPriceIncVatEur?true:false} 
                                helperText={errors.totalPriceIncVatEur} 
                                onChange={this.onChange}  
                            />   
                        </Grid>
                        <Grid item md={6} xs={12}> 
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                            InputLabelProps={{shrink: true}} 
                            required 
                        
                            margin="normal" 
                            variant="outlined"    
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-notepad"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Продуктова такса в лв.:"
                            name="tradeFeeBgn" 
                            value={tradeFeeBgn}
                            error ={errors.tradeFeeBgn?true:false} 
                            helperText={errors.tradeFeeBgn} 
                            onChange={this.onChange}  
                        />   
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            disabled={true}
                            margin="normal" 
                            variant="outlined"    
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-notepad"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Продуктова такса в евро:"
                            name="tradeFeeEur" 
                            value={tradeFeeEur}
                            error ={errors.tradeFeeEur?true:false} 
                            helperText={errors.tradeFeeEur} 
                            onChange={this.onChange}  
                        />   
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br/><br/>
            <Grid container spacing={3}>    
                <Grid item md={6} xs={12}> 
                    <Alert severity="info">Застраховка.</Alert> 
                </Grid>  
            </Grid> 
            <Grid container spacing={3}>    
                <Grid item md={4} xs={12}> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"    
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Застраховател/ параметри на застраховките:"
                        name="insuranceName" 
                        value={insuranceName}
                        error ={errors.insuranceName?true:false} 
                        helperText={errors.insuranceName} 
                        onChange={this.onChange}  
                    />   
                   
                </Grid>
                <Grid item md={4} xs={12}> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"    
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Срок на доставка след внасяне на задатъка:"
                        name="deliveryTime" 
                        value={deliveryTime}
                        error ={errors.deliveryTime?true:false} 
                        helperText={errors.deliveryTime} 
                        onChange={this.onChange}  
                    />   
                </Grid>
                <Grid item md={4} xs={12}> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                        InputLabelProps={{shrink: true}} 
                        required  
                        margin="normal" 
                        variant="outlined"    
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Срок на предаване след доставка на автомобила:"
                        name="deliveryTimeAfterCome" 
                        value={deliveryTimeAfterCome}
                        error ={errors.deliveryTimeAfterCome?true:false} 
                        helperText={errors.deliveryTimeAfterCome} 
                        onChange={this.onChange}  
                    />    
                </Grid>
            </Grid>
        
            <Grid container spacing={3}>    
                <Grid item md={6} xs={12}> 
                    <Alert severity="info">Банка и банкова сметка.</Alert> 
                </Grid>  
            </Grid> 
            <Grid container spacing={3}>    
                <Grid item md={3} xs={12}> 
                    {banks.length>0&&<FormControl variant="outlined" error={errors.companyBankId?true:false} margin="normal" className='mb-20 full-width select-styles'>
                        <InputLabel id="crcId">
                            Банка:
                        </InputLabel>
                        <Select  
                            MenuProps={{
                                anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right"
                                },
                                transformOrigin: {
                                vertical: "top",
                                horizontal: "right"
                                },
                                getContentAnchorEl: null
                            }} 
                            labelId="companyBankId"
                            required   
                            inputProps={{
                                name: 'companyBankId', 
                            }}  
                            value={companyBankId} 
                            onChange={this.onChangeBank}
                            labelWidth={60}  >    
                            {banks.map(option=>{ 
                                return <MenuItem key={option.id} value={option.id}>{option.bank}</MenuItem>  
                            })}
                        
                        </Select>
                        {errors.companyBankId&&<FormHelperText>{errors.companyBankId}</FormHelperText>}
                    </FormControl>}   
                </Grid>
                <Grid item md={3} xs={12}> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled={true}
                        margin="normal" 
                        variant="outlined"    
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Валута:"
                        name="currency" 
                        value={currency}
                        error ={errors.currency?true:false} 
                        helperText={errors.currency} 
                        onChange={this.onChange}  
                    />   
                </Grid>
                <Grid item md={3} xs={12}> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled={true}
                        margin="normal" 
                        variant="outlined"    
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="IBAN:"
                        name="iban" 
                        value={iban}
                        error ={errors.iban?true:false} 
                        helperText={errors.iban} 
                        onChange={this.onChange}  
                    />   
                </Grid>
                <Grid item md={3} xs={12}> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled={true}
                        margin="normal" 
                        variant="outlined"    
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="BIC:"
                        name="bic" 
                        value={bic}
                        error ={errors.bic?true:false} 
                        helperText={errors.bic} 
                        onChange={this.onChange}  
                    />    
                </Grid>
            </Grid>
        
            <Grid container spacing={3}>    
                <Grid item md={3} xs={12}> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        name="depositBgn" 
                        margin="normal" 
                        variant="outlined" 
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Авансово Плащане в лв.:"
                        value={depositBgn}
                        error ={errors.depositBgn?true:false} 
                        helperText={errors.depositBgn} 
                        onChange={this.onChange}  
                    />   
                </Grid>
                <Grid item md={3} xs={12}> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        name="depositEur" 
                        margin="normal" 
                        variant="outlined"  
                        disabled={true}  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Авансово Плащане в евро:"
                        value={depositEur}
                        error ={errors.depositEur?true:false} 
                        helperText={errors.depositEur} 
                        onChange={this.onChange}  
                    />   
                </Grid>
               
                <Grid item md={3} xs={12}> 
                    {/* <div className='btn btn-focus full-width'style={{
                            'position':'relative'
                        }}> 
                        <input onChange = {this.addOrder} name="file" type="file" className="btn btn-success full-width" style={{
                            'opacity':0,'margin':0,'position':'absolute' ,'top': '0','left': '0','width': '100%','height': '100%'
                        }}/>
                        <i className="far fa-file-alt"></i>
                        Качи поръчка
                    </div> */}
              
                    {/* {hasSignedOrder&&<div
                    onClick={()=>downloadDocument('order',this.props.match.params._id)} 
                    className='btn btn-warning full-width'> 
                        <i className="fas fa-download"></i> 
                        Свали Поръчка
                    </div>} */}
                 </Grid> 
                 
               
            </Grid>
            {hasLeasing&&<><Grid container spacing={3}>    
                <Grid item md={6} xs={12}> 
                    <Alert severity="info">Получател на проформа.</Alert> 
                </Grid>  
            </Grid> 
            <Grid container spacing={3}>    
                <Grid item md={12} xs={12}> 
                    <FormControl component="fieldset" error={errors.proformaRecipient} style={{'marginTop':'16px'}}>
                            
                        <RadioGroup style={{'textTransform':'capitalize'}}row name="proformaRecipient" defaultValue='1' value={proformaRecipient} onChange={this.changeProformaRecipient}>
                            <FormControlLabel value="person" control={<Radio color="primary"/>} label={"Клиент: " + clientName} /> 
                            <FormControlLabel value="leasing" control={<Radio color="primary"/>} label={"Лизингова Компания: " + leasingCompany} />
                        </RadioGroup>  
                        <FormHelperText>{errors.proformaRecipient}</FormHelperText>  
                    </FormControl> 
                </Grid>
            </Grid></>}
            <br/><br/>
        </>  
        );
    }
}
const mapStateToProps = (state, props) => {   
    return{
        singleOrder:state.orders.singleOrder,
        companies:state.companies.list
    }
}
export default withRouter(connect(mapStateToProps, {fetchSingleCompany, fetchCompanyObjects, fetchResponsibilityCenters, updateOrder, getCompaniesList, setOrderDocument, getOrderDocument,sendOrderIdToNav })(ОrderInfo));  

