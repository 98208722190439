import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";    
import {salesCars, salesCarsNames} from '../../../../helpers/formsPermissions'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'; 

import {editFormPermitions, fetchFormFilelds, fetchFormFileldsForRole} from '../../../../_actions/rolesActions'

class EditFormsPermition extends Component {
    state = { 
        name:"",
        id:'', 
        permissions:{},
        permissionsNames:{},
        editMode:false,  
        errors:[],
        isLoading:true,
        systemRoleId: JSON.parse(localStorage.getItem('roleId')), 
    }

    componentDidMount(){   
        this.setState({
            permissions:salesCars,
            permissionsNames:salesCarsNames
        },()=>{
            this.callPermissions();
        })  
 
    }

    callPermissions = () =>{
        const formId = this.props.match.params._id;
        const roleId = this.props.match.params._roleId;
        this.props.fetchFormFilelds(formId).then(data => { 
            if(data.status==="success"){ 
          
                const perms = data.data.fields.reduce(
                    (obj, item) => Object.assign(obj, {[item.name]:{ value: false, id:item.id }}), 
                {}); 
                this.setState({
                    permissions:perms,
                    name:data.data.name
                },()=>{
                    this.callRolePermissions(formId,roleId )
                })   
            }
        }) 
    }

    callRolePermissions = (formId,roleId)=>{
        if (formId&&roleId) {
            this.setState({editMode:true}) 
            this.props.fetchFormFileldsForRole(formId,roleId).then(data => {
                if(data.status==="success"){
                    const defPerms = this.state.permissions; 
                    const perms = data.data.reduce(
                        (obj, item) => Object.assign(obj, {[item.name]:{ value: true, id:item.id }}), 
                    {}); 
                    this.setState({ 
                        id:data.data.id, 
                        permissions:{
                            ...defPerms,
                            ...perms
                        }
                    })  
                } 
                this.setState({isLoading:false}) 
            })
        } 
    }
    onChangePermitions = (event)=>{
        const {name,checked} = event.target;  
        this.setState({ 
            permissions:{
                ...this.state.permissions,
                [name]:{
                    ...this.state.permissions[name],
                    value:checked
                }
            }
        }) 
    }
    
    onChange =(event)=>{
        const {name, value} = event.target;
        this.setState({  
            [name]:value    
        })
    }
 

    onSubmit = (event) =>{
        event.preventDefault();
        const {permissions} = this.state;

        let moderated = Object.keys(permissions).map(i => { 
            return {name:i, id:permissions[i].id, value:permissions[i].value }
         })
         moderated = moderated.filter(item => item.value == true) 
         moderated = moderated.map(item =>{
             return {name:item.name, id:item.id}
         })

        const formId = this.props.match.params._id;
        const roleId = this.props.match.params._roleId;
        const obj= {
            roleId,
            fields:moderated 
        }
      
        this.props.editFormPermitions(obj,formId).then(data => {  
            if(data.status==="success"){
                this.setState({errors:[]}) 
                this.props.history.push('/dashboard/formsPermissions')
            } 
            else if (data.errors) {
                this.setState({errors:data.errors})   
            }   
        }) 
        
    }
    render() { 

        const {editMode, isLoading }=this.state; 
        const {permissions, name, permissionsNames, systemRoleId} = this.state;  
    
        const pageTitle = !editMode ? 'Създаване': 'Редактиране';

        const loadingComponent = (
            <div style={{position: 'absolute', zIndex: 110, top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', background: 'rgba(255,255,255,0.8)'}}>
                <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        );
        return ( 
            <>
             <Card style={{'position':'relative'}}>
                {isLoading && loadingComponent}
                <Grid container className="section-title-wrapper padding"> 
                    <Grid container item md={4} xs={12} alignItems="center"> 
                        <h3 className="section-title">
                            <i className="flaticon2-gear"></i> {pageTitle} на права за {name}
                        </h3>
                    </Grid>   
                    <Grid container item xs={4}>    
                    </Grid>
                    <Grid container item md={4} xs={12}alignItems="center" justify="flex-end">  
                        <Link  
                            className="btn btn-secondary"
                            to="/dashboard/formsPermissions" 
                            ><i className="flaticon2-circular-arrow"/>
                            Назад
                        </Link>   
                    </Grid>
                </Grid>  
               <form  className='form' noValidate onSubmit={this.onSubmit} className="padding">
                    {Object.keys(permissions).length>0&&<Grid container spacing={2} >   
                        <Grid container item md={3} xs={12}>
                            <h3>Vehicle:</h3><br/> <br/>
                        </Grid>
                    </Grid>}  
                    <Grid container spacing={2} >   
                        {Object.keys(permissions).map((key, index) => { 
                            return (
                                key.split('.')[0]==='vehicle' &&<Grid container item md={3} xs={12} key={index} className="checkbox-label">   
                                    <FormControlLabel control={ 
                                        <Checkbox name={key} 
                                        checked={permissions[key].value}
                                        onChange={this.onChangePermitions} 
                                        color="primary"/>
                                    }
                                    label={permissionsNames[key]}
                                    />  
                                </Grid>
                            )
                        })}
                     </Grid> 
                     <br/><br/>
                    {Object.keys(permissions).length>0&&<Grid container spacing={2} >   
                        <Grid container item md={3} xs={12}>
                            <h3>Technical:</h3><br/> <br/>
                        </Grid>
                    </Grid>}  
                    <Grid container spacing={2} >   
                        {Object.keys(permissions).map((key, index) => { 
                            return (
                                key.split('.')[0]==='technical' &&<Grid container item md={3} xs={12} key={index} className="checkbox-label">   
                                    <FormControlLabel control={ 
                                        <Checkbox name={key} 
                                        checked={permissions[key].value}
                                        onChange={this.onChangePermitions} 
                                        color="primary"/>
                                    }
                                    label={permissionsNames[key]}
                                    />  
                                </Grid>
                            )
                        })}
                     </Grid> 
                     <br/><br/>
                     {Object.keys(permissions).length>0&&<Grid container spacing={2} >   
                        <Grid container item md={3} xs={12}>
                            <h3>Customer:</h3><br/> <br/><br/>
                        </Grid>
                    </Grid>}
                    <Grid container spacing={2} >   
                        {Object.keys(permissions).map((key, index) => { 
                            return (
                                key.split('.')[0]==='customer' &&<Grid container item md={3} xs={12} key={index} className="checkbox-label">   
                                    <FormControlLabel control={ 
                                        <Checkbox name={key} 
                                        checked={permissions[key].value}
                                        onChange={this.onChangePermitions} 
                                        color="primary"/>
                                    }
                                    label={permissionsNames[key]}
                                    />  
                                </Grid>
                            )
                        })}
                    </Grid>
                    <br/><br/>
                    {Object.keys(permissions).length>0&&<Grid container spacing={2} >   
                        <Grid container item md={3} xs={12}>
                            <h3>Logistic:</h3><br/> <br/><br/>
                        </Grid>
                    </Grid>} 
                    <Grid container spacing={2} >   
                        {Object.keys(permissions).map((key, index) => { 
                            return (
                                key.split('.')[0]==='logistic' &&<Grid container item md={3} xs={12} key={index} className="checkbox-label">   
                                    <FormControlLabel control={ 
                                        <Checkbox name={key} 
                                        checked={permissions[key].value}
                                        onChange={this.onChangePermitions} 
                                        color="primary"/>
                                    }
                                    label={permissionsNames[key]}
                                    />  
                                </Grid>
                            )
                        })}
                    </Grid>
                    <br/><br/>
                    {Object.keys(permissions).length>0&&<Grid container spacing={2} >   
                        <Grid container item md={3} xs={12}>
                            <h3>Administration:</h3><br/> <br/><br/>
                        </Grid>
                    </Grid>}  
                    <Grid container spacing={2} >   
                        {Object.keys(permissions).map((key, index) => { 
                            return (
                                key.split('.')[0]==='administration' &&<Grid container item md={3} xs={12} key={index} className="checkbox-label">   
                                    <FormControlLabel control={ 
                                        <Checkbox name={key} 
                                        checked={permissions[key].value}
                                        onChange={this.onChangePermitions} 
                                        color="primary"/>
                                    }
                                    label={permissionsNames[key]}
                                    />  
                                </Grid>
                            )
                        })}
                    </Grid>
                    <br/><br/>
                    {Object.keys(permissions).length>0&&<Grid container spacing={2} >   
                        <Grid container item md={3} xs={12}>
                            <h3>Price:</h3><br/> <br/><br/>
                        </Grid>
                    </Grid>}
                    <Grid container spacing={2} >   
                        {Object.keys(permissions).map((key, index) => { 
                            return (
                                key.split('.')[0]==='price' &&<Grid container item md={3} xs={12} key={index} className="checkbox-label">   
                                    <FormControlLabel control={ 
                                        <Checkbox name={key} 
                                        checked={permissions[key].value}
                                        onChange={this.onChangePermitions} 
                                        color="primary"/>
                                    }
                                    label={permissionsNames[key]}
                                    />  
                                </Grid>
                            )
                        })}
                    </Grid>
                    <br/><br/> 
                    <Grid container spacing={2} >   
                        <Grid container item md={4} xs={12} >   
                            {!systemRoleId!==19&&<button type="submit" className="btn btn-info full-width">
                                <i className="flaticon2-add-square"></i>{editMode ? 'Запази' : 'Създай'}
                            </button>} 
                        </Grid>  
                    </Grid> 
                </form>
                   
            </Card> 
          
            </>
        );
    }
}

 
export default connect('', {editFormPermitions, fetchFormFilelds, fetchFormFileldsForRole  })(EditFormsPermition);
