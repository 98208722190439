import React, { Component } from 'react';
import {connect} from 'react-redux'

import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {setFilteredSaleCars} from '../../../_actions/carsSaleActions' 
import {fileds, filedsMobile, filedsMobileFilters} from '../../../helpers/viewCarsSale' 
import {allFIeldsForSaleCars, filtersForSaleCarsMobile} from '../../../helpers/allFIeldsForSaleCars' 

class CustomFilter extends Component {
    state = { 
        filters:window.outerWidth>767 ? allFIeldsForSaleCars : filtersForSaleCarsMobile,   
        fileds:window.outerWidth>767 ? fileds : filedsMobileFilters,   
        carsSale:[],
        filteredObjects:[],
        filtersStatus:false,
        searchValue:"",
        mobileView:false
    }

    componentDidMount (){ 
        if (window.outerWidth>767) {
            this.setState({mobileView:false})
        }else{
            this.setState({mobileView:true}) 
        }
    }  
  
    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.carsSale !== this.props.carsSale){
            const {carsSale}=this.props;
            this.setState({
                carsSale:carsSale,
                filteredObjects:carsSale
            }) 
        }
        if(previousProps.filtersStatus !== this.props.filtersStatus){ 
            this.setState({
                filtersStatus:this.props.filtersStatus, 
            }) 
        }
        if(previousProps.clearFilters !== this.props.clearFilters){ 
            if (this.props.clearFilters) {
                this.clearFilters()
            }  
        }
    } 


    onChange = (event)=>{ 
        const {name, value}= event.target;   
        const {filteredObjects} = this.state;  
        const filtered = filteredObjects.filter(item=> {  
                return item[name]==value 
            }
        ) 

        this.setState({ 
            filteredObjects:filtered,  
            filters:{
                ...this.state.filters,
                [name]:value
            } 
        }) 
    }

    openFilters = () =>{
        this.props.openFilters(false)
    }
   

    clearFilters = () =>{ 
        this.props.setFilteredSaleCars(this.props.carsSaleIntact)
        this.props.appliedFilters(false) 
        if (window.outerWidth>767 ) {
            this.setState({ 
                filteredObjects:this.props.carsSaleIntact, 
                filters:{...allFIeldsForSaleCars}
            }) 
        }else{
            this.setState({ 
                filteredObjects:this.props.carsSaleIntact, 
                filters:{...filtersForSaleCarsMobile}
            }) 
        } 
    }

    appyFilters = () =>{
        const {carsSale, filters} =this.state;  
      
        let filtersForUsage = {}
        Object.keys(filters).forEach(function(key) {
            if (filters[key] !== "") {
                filtersForUsage[key] = filters[key];
            }
        }); 
        const filteredCars = carsSale.filter(function(item) { 
            for (let key in filtersForUsage) { 
              if (item[key] === undefined || item[key] != filtersForUsage[key])
                return false;
            }
            return true;
        });   
        this.openFilters(); 
        this.props.setFilteredSaleCars(filteredCars)
        this.props.appliedFilters(true)
    }

    searchChange = (event)=>{
        const {name,value}=event.target 
        this.setState({
            searchValue:value, 
        }) 
    }    

    onCustomSearch = () => {
        const {searchValue} = this.state
        this.props.onCustomSearch(searchValue)
    }

    render() { 
        const {filteredObjects ,filtersStatus, fileds, searchValue, mobileView} = this.state; 
        const {...allFIeldsForSaleCars} = this.state.filters;  
 
        const filtersNames = fileds.map(item =>{
            return ({name:item.name, label:item.label, value:this.state.filters[item.name]})
        }) 
        
        return ( 
            <>
            <div className={"custom-filters "+(filtersStatus ? 'open':'')}> 
                <p className="filter-title">Филтри</p>
                <button className='btn-label-brand' onClick={this.clearFilters}>
                    <span className="MuiButton-label">Изчисти филтрите</span>
                </button>

                <button onClick={this.openFilters} className="MuiButtonBase-root MuiIconButton-root close-btn "type="button">
                    <span className="MuiIconButton-label">
                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                        </svg>
                    </span>
                </button>
            <Grid container spacing={3}>
                {
                    filtersNames.map((item, index)=>{ 
                        if (item.name!=='id') {
                            return( 
                                <Grid container item md={2} xs={6} alignItems="center" key={index}>  
                                    <FormControl variant="standard" margin="normal" className='full-width select-styles'>
                                        <InputLabel id={item.name}>{item.label}</InputLabel>
                                        <Select  
                                            MenuProps={{
                                                anchorOrigin: { vertical: "bottom", horizontal: "right"},
                                                transformOrigin: {vertical: "top",horizontal: "right"},
                                                getContentAnchorEl: null
                                            }} 
                                            labelId={item.name} 
                                            required   
                                            inputProps={{
                                                name: item.name, 
                                            }}  
                                            value={item.value} 
                                            onChange={this.onChange} 
                                            >   
                                            {filteredObjects
                                                .filter((v,i,a)=>a.findIndex(t=>(t[item.name]  === v[item.name]))===i)
                                                .map((it,i)=>{
                                                    if (it[item.name]!==undefined) {
                                                        return (it[item.name].length>0&&<MenuItem key={i} value={it[item.name]}>{it[item.name]}</MenuItem>)
                                                    }
                                        
                                                })
                                            } 
                                        </Select> 
                                    </FormControl>  
                                     
                                </Grid> 
                            )
                        } 
                    })
                } 
               
     
            </Grid>
            <Grid container spacing={3}>
                <Grid container item xs={12} alignItems="flex-start">  
                    <button className="btn btn-info" onClick={this.appyFilters}>Приложи филтрите</button> 
                </Grid>
            </Grid>
                {mobileView&&
                    <div className="custom-search">
                        <input
                            placeholder='Търси'
                            type="text"
                            name='searchValue'
                            value={searchValue}
                            onChange={this.searchChange}
                        />
                        <button className="btn btn-info" onClick={this.onCustomSearch}> 
                            <i className="fas fa-search"></i>
                        </button> 
                    </div> 
                } 
            </div>
            </>

         );
    }
}

const mapStateToProps = state => {    
    return({
        carsSale:state.carsSale.items, 
        carsSaleIntact:state.carsSale.intact, 
     })
}
export default connect(mapStateToProps, {setFilteredSaleCars})(CustomFilter);