import React, { Component } from 'react';
import ReactDOM from "react-dom"; 
import {Link} from 'react-router-dom';
import { withRouter } from "react-router-dom";

import {connect} from 'react-redux'

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  
import Grid from '@material-ui/core/Grid';

import {columns} from "../../../helpers/viewClients";  
import {options} from "../../../helpers/viewClients";  
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns";  
   
import {fetchClients} from '../../../_actions/clientsActions' 

 

class ClientsList extends Component {
     state={
        permissions: JSON.parse(localStorage.getItem('permissions')),
        roleId: JSON.parse(localStorage.getItem('roleId')),
        options:{},
        columns:[]
     }

    componentDidMount (){
        this.setState({
            options: storeColumnsFilters('clientsTable', options),
            columns: getStoredColumnsFilters('clientsTable', columns)
        }) 

        this.props.fetchClients()  
    } 

    onDownload = (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
    
    // onRowClick=(rowData , rowMeta, e) => {  
    //     this.props.history.push("/dashboard/clients/"+rowData[0]);
    // }
  
    render() {  
 
        const {permissions= {}} = this.state; 
        const {options, columns, roleId} = this.state;  
        const {clients}=this.props;
 
        return (  
            <React.Fragment>
                <Grid container className="mb-20">
                    <Grid container item xs={12} sm={6} alignItems="center"> 
                        {(roleId!==19&&permissions.clientsStore)&&<Link className="btn btn-info" to="/dashboard/create-client"><i className="flaticon-add-circular-button"/>
                            Добави нов Клиент
                        </Link> } 
                    </Grid>   
                </Grid>   
                <Card>  
                    <MUIDataTable
                        title={"Клиенти:"}
                        data={clients}
                        columns={columns} 
                        options={{...options, onDownload:this.onDownload}}   
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
 
const mapStateToProps = state => {   
   return({clients:state.clients.items})
}

export default withRouter(connect(mapStateToProps, { fetchClients })(ClientsList));
// export default ClientsList;