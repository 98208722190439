import React, { Component } from 'react';
 
import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';

import {connect} from 'react-redux'

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  

import {columns} from "../../../helpers/viewCоntacts";  
import {options} from "../../../helpers/viewCоntacts";  
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns";  

import {fetchContacts} from '../../../_actions/contactsActions'

 

class ContactsList extends Component {
    state={
        permissions: JSON.parse(localStorage.getItem('permissions')),
        roleId: JSON.parse(localStorage.getItem('roleId')),
        options:{},
        columns:[]
     }

    componentWillMount (){
        this.setState({
            options: storeColumnsFilters('contactsTable', options),
            columns: getStoredColumnsFilters('contactsTable', columns)
        }) 
        this.props.fetchContacts() 
    } 

    onDownload = (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
    
    // onRowClick=(rowData , rowMeta, e) => {  
    //     this.props.history.push("/dashboard/contacts/"+rowData[0]);
    // }
  
    render() {  
        const {contacts} = this.props; 
        const {options, columns, permissions= {}, roleId} = this.state;  
        
        return (  
            <React.Fragment>  
                <Grid container className="mb-20">
                    <Grid container item xs={12} alignItems="center"> 
                        {(roleId!==19&&permissions.contactsStore)&&<Link className="btn btn-info" to="/dashboard/create-contact"><i className="flaticon-add-circular-button"/>
                            Добави нов Koнтакт
                        </Link>}
                    </Grid>   
                </Grid>    
                <Card>  
                    <MUIDataTable
                        title={"Koнтакти:"}
                        data={contacts}
                        columns={columns} 
                        options={{...options, onDownload:this.onDownload}}  
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
 
const mapStateToProps = state => {   
   return({contacts:state.contacts.items})
}

export default withRouter(connect(mapStateToProps, { fetchContacts })(ContactsList));
// export default ClientsList;