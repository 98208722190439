import { apiUrl} from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions"; 


export const fetchContacts = () => dispatch => { 
    return fetch(apiUrl+'contacts', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
      }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") { 
            dispatch({
                type:'FETCH_CONTACTS',
                payload:data
            })
        } 
        return response
    });  
}


export const fetchSingleContact = (id) => dispatch => { 
    return fetch(apiUrl+'contacts/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_SINGLE_CONTACT',
                payload:data
            })
        } 
        return response
    }); 
}
 
export const createContact = (contact) => dispatch => {  
    
    return fetch(apiUrl+'contacts', { 
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(contact)
     }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
     })
    .then(response => {    
        const {status, data, message} = response; 
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'CREATE_CONTACT',
                payload:data
            })
        } 
        return response
    }); 
 
}

export const editContact = (contact) => dispatch => {   
    const {id}=contact;
    return fetch(apiUrl+'contacts/'+id, {
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(contact)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, message} = response; 
        if (status === "success") { 
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'EDIT_CONTACT',
                payload:contact
            })
        } 
        return response
    });   
}

export const fetchContactAutoComplete = (name) => dispatch => {  
    return fetch(apiUrl+'contacts/autocomplete?q='+name, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") { 
            dispatch({
                type:'FETCH_CONTACTS_AUTOCOMPLETE',
                payload:data
            })
        } 
        return response
    }); 
}
export const fetchRelatedContactsCompanies = (id) => dispatch => {  
    return fetch(apiUrl+'contacts/clients/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") { 
            dispatch({
                type:'FETCH_RELATED_CONTACTS_COMPANIES',
                payload:data
            })
        } 
        return response
    }); 
}