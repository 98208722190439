import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete'; 
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import CircularProgress from '@material-ui/core/CircularProgress'; 

import ClientForm from '../clients/forms/Client_Form'
import CompanyForm from '../clients/forms/Company_Form' 

import {fetchClientsAutoComplete} from '../../../_actions/clientsActions'
import {updateOrder} from '../../../_actions/ordersActions'

import {fetchContacts} from '../../../_actions/contactsActions'

const filterOptions = createFilterOptions({
    limit: 50, 
});

class ClientInfo extends Component {
    constructor() {
        super();
        this.tempBranchId = '';
        this.tempBranchName = ''
    }

    state = {    
        type:'',
        editMode:false,  
        tempBranchId:'',
        tempBranchName:'',
        open:false,
        loadingText:"Моля въведете минимум 3 символа!"
    }

 
     componentDidMount(){ 
        this.props.fetchContacts()
        if (this.props.match.params._id) { 
            this.setState({editMode:true})  
        }
        if (this.props.singleOrder.client) {
            this.setState({ 
                type:this.props.singleOrder.client.type
            })
        } 
    } 

    componentDidUpdate(previousProps, previousState){     
        if(previousProps.singleOrder !== this.props.singleOrder){  
            if (this.props.singleOrder.client) {
                this.setState({
                    type:this.props.singleOrder.client.type
                })  
            }
         
        }    
    }

    handleData=(data)=>{   
        this.props.updateOrder('client', {...data, singleClientId:this.props.singleOrder.client.singleClientId})  

        const {navId=""}=data
        if (this.state.type===1) {
            if (navId.length===0 ) {
                this.props.updateOrder('none', {clientId:data.id}) 
                this.props.updateOrder('client', {...data, singleClientId:data.id})  
            }else{
                this.props.updateOrder('none', {clientId:''}) 
            }  
        }else if (this.state.type===2) {
            this.props.updateOrder('none', {clientId:data.id}) 
        } 
    }

   
  
    
    setType =(num)=>{ 
        const {singleClientId='', companyClientId='', navId=""}= this.props.singleOrder.client
        this.setState({
            type:num
        },()=>{
            this.props.updateOrder('client', {
                ...this.props.singleOrder.client,
                type:num
            })
            if (num===1 ) {
                if (navId.length===0 ) {
                    this.props.updateOrder('none', {clientId:singleClientId}) 
                }else{
                    this.props.updateOrder('none', {clientId:""}) 
                }
            }
            if (num===2) {
                this.props.updateOrder('none', {clientId:companyClientId}) 
            }
        })
    }

    onChangeContactId = (event, value, reason) => {
        if (value) {  
            const {id,name}=value 
            this.props.updateOrder('none', {contactId:id, contactName:name} )    
      
        }
    }

    onChangeContact = (event, value, reason) =>{   
        if(event || value){ 
            this.props.updateOrder('none', {contactName:value})    
            this.setState()
        }
        
    }
 
    changeBranchId = (event, value, reason) => { 
        if (value) { 
            this.props.updateOrder('none', {branchId:value.id, branchName:value.name})
            this.tempBranchId=value.id;
            this.tempBranchName=value.name;

        }  
    }
 
    changeBranchName = (event, value, reason) => {
        if (value || event ) {  
            this.props.updateOrder('none', {branchName:value})     
            this.tempBranchName=value;
            this.tempBranchId=0;
           
        }
    }
    onCloseBranch  = (event, reason) =>{
        if (!this.tempBranchId) {
            this.props.updateOrder('client', 
                { 
                    ...this.props.singleOrder.client,
                    branches:[...this.props.singleOrder.client.branches, 
                        {name:this.tempBranchName}
                    ]
                }
            ) 
        } 
    }

    changeClientData = (event, value, reason) => {
        if (value) {   
            this.handleData(value);
        }
    }

    changeCLientName = (event, value, reason) => { 
        if (value.length>3) { 
            this.setState({loadingText:'Зареждане списък...'})
            this.props.fetchClientsAutoComplete(value, this.state.type) 
        }  
    }

    render() {  
        const {editMode,type, open, loadingText}=this.state;  
        const {errors, clients, singleOrder}=this.props;    
        const {contactName, client={} , branchName} = singleOrder;   
        const {branches=[]} = client; 
        const {contacts} = this.props;   
        const loading = open && clients.length === 0;
      
        return ( 
            <>
                {!editMode&&<Grid container spacing={3} > 
                    <Grid item md={4} xs={12}> 
                    <Autocomplete 
                        open={open}
                        onOpen={() => {
                            this.setState({open:true});
                        }}
                        onClose={() => {
                            this.setState({open:false});
                        }}
                        loadingText={loadingText}
                        loading={loading}
                        options={clients}   
                        onChange={this.changeClientData} 
                        onInputChange={this.changeCLientName} 
                        getOptionLabel={option => {
                            return option.name ?  
                                option.name+" - "+(option.type===1 ? "ФЗ":"ЮЛ") :
                                option.companyName+" - "+(+option.type===1 ? "ФЗ":"ЮЛ")
                        }}
                        renderInput={params => (
                            <TextField {...params}
                            className="full-width mb-20"
                            InputLabelProps={{shrink: true}} 
                            margin="normal"
                            variant="outlined"
                            label="Търси клиент:" 
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                            }}
                            />
                        )} 
                    />
                    </Grid> 
                </Grid>}
                <div className="header-section-inner mb-20"> 
                    <div className={"btn mr-10 "+(type===1 ? 'btn-focus':'' )}
                        onClick={()=>this.setType(1)}>
                        <i className="flaticon2-user"></i>  Физическо лице
                    </div>
                    <div className={"btn "+(type===2 ? 'btn-focus':'' )}
                        onClick={()=>this.setType(2)}>
                        <i className="far fa-building"></i> Юридическо лице
                    </div> 
                </div>
              
                {type===1&&<ClientForm  
                    handleData ={this.handleData}
                    getClientAutocomplete={this.getClientAutocomplete} 
                    clients={clients}
                    type={type}
                    errors={errors.client}
                    editMode={editMode}
                    userData={singleOrder.client} 
                />
                }
                {type===2&&<CompanyForm
                    handleData ={this.handleData}
                    getClientAutocomplete={this.getClientAutocomplete} 
                    type={type}
                    clients={clients}
                    errors={errors.client}
                    editMode={editMode.client}
                    userData={singleOrder.client}
                />
                }   
                 
                <Grid container spacing={3} > 
                    <Grid item md={4} xs={12}> 
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            disabled
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-user"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Контакт:"
                            name="contactName"
                            value={contactName}
                            onChange={this.onChange}
                            error ={errors.contactName?true:false}  
                            helperText={errors.contactName}  
                        />   
                        {/* <Autocomplete  
                            options={contacts}  
                            inputValue={contactName}
                            onChange={this.onChangeContactId} 
                            onInputChange={this.onChangeContact} 
                            getOptionLabel={option => option.name} 
                            renderInput={params => ( 
                                <TextField
                                    {...params}
                                    className="full-width mb-20 " 
                                    InputLabelProps={{shrink: true}} 
                                    margin="normal" 
                                    variant="outlined" 
                                    label="Контакт"  
                                  
                                />  
                            )} 
                        />  */} 
                        
                    </Grid> 
                </Grid>
               {(singleOrder.client && (type===2 && branches.length>0))&&<Grid container spacing={3} >  
                 
                    <Grid item md={4} xs={12}>  
                        <Autocomplete
                            filterOptions={filterOptions}
                            freeSolo 
                            inputValue={branchName} 
                            options={branches}  
                            onChange={this.changeBranchId}  
                            onInputChange={this.changeBranchName} 
                            onClose={this.onCloseBranch} 
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                                <TextField {...params}
                                className="full-width mb-20"
                                InputLabelProps={{shrink: true}} 
                                margin="normal"
                                variant="outlined"
                                label="Клон:"  
                                />
                            )} 
                        />
                    </Grid>     
                </Grid>}
            </>
         );
    }
}
const mapStateToProps = (state, props) => {   
    return{ 
        clients:state.clients.clientsAutoComplete,
        contacts:state.contacts.items,
        singleOrder:state.orders.singleOrder,  
    }
}


export default withRouter(connect(mapStateToProps, { fetchClientsAutoComplete,fetchContacts, updateOrder})(ClientInfo))