import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux'

import Autocomplete from '@material-ui/lab/Autocomplete'; 
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import ClientForm from './forms/Client_Form'
import CompanyForm from './forms/Company_Form' 

const filterOptions = createFilterOptions({
    limit: 50, 
});

class FormHolder extends Component {
    state = { 
        type:1,
        open:false, 
        contactCompany:'',
        loadingText:"Моля въведете минимум 3 символа!"
     }

     componentDidMount(){  
        this.getPassedData() 
    }

     componentDidUpdate(previousProps, previousState){  
        if(previousProps.userData !== this.props.userData){ 
            this.getPassedData()
        } 
    }
    
    getPassedData = ()=>{ 
        const {userData={}}=this.props;   
        const {type='', contactCompany=''}=userData;   
 
        if (contactCompany.length>3) {
            this.setState({
                type:2, open:true, contactCompany:contactCompany
            }); 
            this.changeCLientName('',contactCompany,'')
        }else if (type) { 
            this.setState({type:userData.type})   
        }
      
    }
    
    handleData=(data)=>{  
        this.props.handleData(data) 
    }
 
    changeClientData = (event, value, reason) => {
        if (value) {   
            this.props.handleData(value);
        }
    }

    changeCLientName = (event, value, reason) => { 

        if (value || event) {  
            this.setState({contactCompany:value})
        } 

        if (value.length>3) {  
            this.setState({loadingText:'Зареждане списък...'})
            this.props.getClientAutocomplete(value, this.state.type) 
        }  
         
    }


    
  

    render() {  
        const {type, open, loadingText, contactCompany}=this.state;   
        const {editMode, userData,errors, clients}=this.props;   
     
        const loading = open && clients.length === 0;
   
       
        return ( 
            <>
                {!editMode&&<Grid container spacing={3} > 
                    <Grid item md={4} xs={12}> 
                    <Autocomplete 
                        filterOptions={filterOptions}
                        open={open}
                        onOpen={() => {
                            this.setState({open:true});
                        }}
                        onClose={() => {
                            this.setState({open:false});
                        }}
                        loadingText={loadingText}
                        loading={loading}
                        options={clients}   
                        onChange={this.changeClientData} 
                        onInputChange={this.changeCLientName} 
                        inputValue={contactCompany}
                        getOptionLabel={option => {
                            return option.name ?  
                                option.name+" - "+(option.type===1 ? "ФЗ":"ЮЛ") :
                                option.companyName+" - "+(+option.type===1 ? "ФЗ":"ЮЛ")
                        }}
                        renderInput={params => (
                            <TextField {...params}
                            className="full-width mb-20"
                            InputLabelProps={{shrink: true}} 
                            margin="normal"
                            variant="outlined"
                            label="Търси клиент:" 
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                            }}
                            />
                        )} 
                    />
                    </Grid> 
                </Grid>}
                <div className="header-section-inner mb-20"> 
                    <div className={"btn mr-10 "+(type===1 ? 'btn-focus':'' )}
                    onClick={()=>{this.setState({type:1}) ; this.props.handleData({type:1})}}>
                        <i className="flaticon2-user"></i>  Физическо лице
                    </div>
                    <div className={"btn "+(type===2 ? 'btn-focus':'' )}
                        onClick={()=>{this.setState({type:2}) ; this.props.handleData({type:2})}}>
                        <i className="far fa-building"></i> Юридическо лице
                    </div> 
                </div>
                {type===1&&<ClientForm  
                    handleData ={this.handleData}
                    getClientAutocomplete={this.getClientAutocomplete} 
                    clients={clients}
                    type={type}
                    errors={errors}
                    editMode={editMode}
                    userData={userData} 
                />
                }
                {type===2&&<CompanyForm
                    handleData ={this.handleData}
                    getClientAutocomplete={this.getClientAutocomplete} 
                    type={type}
                    clients={clients}
                    errors={errors}
                    editMode={editMode}
                    userData={userData}
                />
                }  
               
             <br/><br/><br/> 
            </>
         );
    }
}

export default connect('', { })(FormHolder);   