import React, { Component } from 'react';
import {connect} from 'react-redux'

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'; 
import InputAdornment from '@material-ui/core/InputAdornment'; 
import NumberFormat from 'react-number-format';

import MuiAlert from '@material-ui/lab/Alert';



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
  


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString 
      />
    );
} 



class Prices extends Component {
    state = { 
        data:{
            bottomPrice:'',
            personalBonusForTrader:'',
            pricePartner:'',
            priceB2b:'',
            priceB2c:'',
            priceAfterDiscount:0,
            listPrice:'',
            bonusConditions:'',
            note:'',  
        },  
        userPermisions: JSON.parse(localStorage.getItem('permissions')),
        role:localStorage.getItem('roleId')
    } 

    componentDidMount(){   
        this.getPassedData()     
    }

    componentWillUnmount(){
        this.props.handleData('price', this.state.data)  
    }

    componentDidUpdate(previousProps, previousState){    
        if(previousProps.offer !== this.props.offer){   
            this.getPassedData()
        }        
    }  

    getPassedData = ()=>{ 
    
        const {offer}=this.props;   
        if ( offer.price ) {  

            const{ priceAfterDiscount=0,note}= offer.price;  
        
            this.setState({
                data:{
                    ...this.state.data,
                    priceAfterDiscount,note,
                },  
            },() => {   
                const {priceAndAcessoaries}=this.props;  
                if (priceAndAcessoaries.price) {   
                    const{listPrice,priceB2c,bonusConditions, bottomPrice,personalBonusForTrader, pricePartner, priceB2b}= priceAndAcessoaries.price
                    this.setState({
                        data:{
                            ...this.state.data,
                            listPrice,bonusConditions, bottomPrice,personalBonusForTrader, pricePartner,priceB2c, priceB2b
                        } 
                    })    
                }  
            })  
        }   
    }
 
     onChange = (event) =>{
        const {name,value} = event.target;  
        if (name==="priceAfterDiscount" ) { 
            this.setState ({
                data:{
                    ...this.state.data,
                    [name]:value<0 ? value*-1 :value
                } 
            },()=>{
                this.props.handleData('price', this.state.data)  
            }) 
        }else{
            this.setState ({
                data:{
                    ...this.state.data,
                    [name]:value
                } 
            },()=>{
                this.props.handleData('price', this.state.data)  
            })
        }
        
    }

    onChangeStatus = (id, index)=>{ 
        const list = [...this.props.offer.resolutions];
        if (id===1) {
            list[index]['resolutionAllowPrice'] = this.state.data.priceAfterDiscount; 
        }else{
            list[index]['resolutionAllowPrice'] = 0; 
        } 
        list[index]['resolutionStatus'] = id;  
        this.props.handleData('resolutions', list)  
    }

    onChangeResolution = (e, index)=>{
        const { name, value } = e.target;
        const list = [...this.props.offer.resolutions];
        list[index][name] = value; 
       
        this.props.handleData('resolutions', list)  
    }
    
    render() {  
        const {editMode, errors={}, offer,formUserPermissions={}}=this.props;  
 
        const {userPermisions} = this.state;
        const {priceB2c=0, note, listPrice, bottomPrice,personalBonusForTrader, pricePartner, priceAfterDiscount, priceB2b,bonusConditions } = this.state.data; 
        const {resolutions=[]}=offer 
        return (
        <>
        <br/>
        <br/>
           <Grid container spacing={3}>    
                <Grid item md={3} xs={12}>    
                {formUserPermissions['price.listPrice']&&<Alert severity="info">Лист цена: <strong className="block">
                    <NumberFormat value={listPrice} displayType={'text'} thousandSeparator={true} /></strong>
                    </Alert> }
               
                </Grid>
                    <Grid item md={3} xs={12}> 
                    {formUserPermissions['price.bottomPrice']&&<Alert severity="error">Минимална цена: <strong className="block"><NumberFormat value={bottomPrice} displayType={'text'} thousandSeparator={true} /></strong></Alert>}
                    </Grid>
                    <Grid item md={3} xs={12}> 
                        <Alert severity="success">Цена към дилър: <strong className="block"><NumberFormat value={pricePartner} displayType={'text'} thousandSeparator={true} /></strong></Alert> 
                    </Grid>
                    
                    <Grid item md={3} xs={12}> 
                    {formUserPermissions['price.priceB2b']&&<Alert severity="warning">Цена към дилър с собствен склад: <strong className="block"><NumberFormat value={priceB2b} displayType={'text'} thousandSeparator={true} /></strong></Alert>} 
                    </Grid>
            </Grid>
            
            <Grid container spacing={3}>    
                {formUserPermissions['price.priceB2c']&&<Grid item md={3} xs={12}> 
                       <Alert severity="info">Kлиентска Цена: <strong className="block">
                       <NumberFormat value={priceB2c} displayType={'text'} thousandSeparator={true} /></strong></Alert> 
                </Grid> }
                <Grid item md={3} xs={12}> 
                    <TextField inputProps={{'className':'data-hj-allow'}} style={{'marginTop':'0'}}className="full-width small" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        name="priceAfterDiscount"
                        number="true"
                        style={{'marginTop':'18px'}}
                        margin="normal" 
                        variant="outlined"    
                        label="Цена след отстъпка лв.:"
                        value={ priceAfterDiscount }
                        onChange={this.onChange}  
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        error ={errors.priceAfterDiscount?true:false}  
                        helperText={errors.priceAfterDiscount}  
                    />  
                    <p style={{'fontWeight': 'bold','color': '#f44336'}}>.</p>
                </Grid>
                <Grid item md={3} xs={12}> 
                    <Alert variant="outlined" severity="info">
                        Сума на отстъпката:<strong className="block">{parseInt(listPrice-priceAfterDiscount)<0 ? "Няма отсъпка" : parseInt(listPrice-priceAfterDiscount).toLocaleString()+" лв." }</strong>
                    </Alert>
                </Grid>
                <Grid item md={3} xs={12}> 
                    <TextField inputProps={{'className':'data-hj-allow'}} style={{'marginTop':'0'}}className="full-width small" 
                        InputLabelProps={{shrink: true}}  
                        name="note"
                        number="true"
                        margin="normal" 
                        style={{'marginTop':'18px'}}
                        variant="outlined"    
                        label="Забележка:"
                        value={ note }
                        onChange={this.onChange}   
                    />  
                </Grid>
            </Grid>
            
        
            <Grid container spacing={3}> 
                <Grid item md={3} xs={12}>   
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        disabled
                        InputLabelProps={{shrink: true}}  
                        margin="normal" 
                        variant="outlined"   
                        multiline
                        rows='5'
                        style={{'marginTop':'0'}}
                        label="Бонусни Условия"
                        name="bonusConditions"
                        value={bonusConditions}
                        error ={errors.bonusConditions?true:false} 
                        helperText={errors.bonusConditions} 
                        onChange={this.onChange}  
                    />
                </Grid>  
                <Grid item md={3} xs={12}> 
                    <Alert variant="outlined" severity="error">
                        Персонален бонус:<strong className="block">{personalBonusForTrader} лв.</strong>
                    </Alert>
                 
                </Grid>
            </Grid>
            {(editMode&&resolutions.length>0)&&<>
                <Grid container spacing={3}>   
                    {resolutions.map((item,i)=>{
                        return( 
                        <Grid item md={6} xs={12} key={i}> 
                            <Grid container item xs={12} alignItems="center" className="padding section-title-wrapper"> 
                                <h3 className="section-title">
                                    Резолюция на 
                                    {item.permissionName=='adminAllowOffer'?" Админ":""}
                                    {item.permissionName=='managerAllowOffer'?" Мениджър":""}
                                    {item.permissionName=='salesManagerAllowOffer'?" Мениджър Продажби":""}
                                    {item.permissionName=='dealerManagerAllowOffer'?" Дилър Mениджър":""}:
                                </h3> 
                            </Grid>  
                            <Grid container spacing={3} className='mb-20'> 
                        
                                <Grid container item md={6} xs={12} justify='center'>  
                                    <button type="button"
                                        disabled={!Boolean(userPermisions[item.permissionName])}
                                        className={"btn full-width "+(item.resolutionStatus===1? 'btn-success':'')} 
                                        onClick={()=>this.onChangeStatus(1, i)}>
                                            одобрен
                                    </button>
                                </Grid>  
                                <Grid container item md={6} xs={12} justify='center'>
                                    <button type="button"
                                        disabled={!Boolean(userPermisions[item.permissionName])}
                                        className={"btn full-width "+(item.resolutionStatus===0? 'btn-danger':'')} 
                                        onClick={()=>this.onChangeStatus(0, i)}>
                                            отказан
                                    </button>
                                </Grid>  
                            </Grid> 
                            
                            {item.resolutionStatus===1&&<div> 
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-list"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled={!Boolean(userPermisions[item.permissionName])}
                                    label="Цена след отстъпка в лева:"
                                    name="resolutionAllowPrice"
                                    value={item.resolutionAllowPrice}  
                                    onChange={(e)=>this.onChangeResolution(e,i)}  
                                />  
                            </div>} 
                            {(item.resolutionStatus===1||item.resolutionStatus===0)&&<TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    rows='5'
                                    multiline
                                    margin="normal" 
                                    variant="outlined"  
                                    label="Kоментар:"
                                    name="resolutionNote"
                                    value={item.resolutionNote}  
                                    disabled={!Boolean(userPermisions[item.permissionName])}
                                    onChange={(e)=>this.onChangeResolution(e,i)}  
                                /> } 
                        </Grid>  
                        )
                    })
                    }
                </Grid>  
            </>}
            <br/><br/>
        </>  
        );
    }
}
const mapStateToProps = (state, props) => {  
    return{ 
        formUserPermissions:state.roles.formUserPermissions,  
    } 
}
export default connect(mapStateToProps, { })(Prices);   
 