import React, { Component } from 'react';

import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';

import {connect} from 'react-redux'

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  
 
import TextField from '@material-ui/core/TextField'; 

import {options} from "../../../helpers/viewOffers";  
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns";  

import {fetchOffers, setMultipleResolutions} from '../../../_actions/offersActions'

const permNames={
    adminAllowOffer:'Админ',
    managerAllowOffer:'Мениджър',
    salesManagerAllowOffer:'Мениджър Продажби',
    dealerManagerAllowOffer:'Дилър Mениджър'
}
class OffersForApproval extends Component { 
    state ={
        role:localStorage.getItem('roleId'),
        filterterdOffers:[], 
        options:{},
        columns:[],
        changedRows:[],
        errors:[],
        userPermisions: JSON.parse(localStorage.getItem('permissions')),
        rolesArray:[]
    }
    componentDidMount (){
        const roles = JSON.parse(localStorage.getItem('permissions'))
        let arr =[]
        Object.keys(roles).map(item=>{
            if (permNames[item]) {
                arr.push(item) 
            } 
        })
        this.setState({rolesArray:arr}) 

        this.setState({
            options: storeColumnsFilters('offersTable', options),
        }) 

        this.fetchFilteredOffers()
    } 
    fetchFilteredOffers = ()=>{
        this.props.fetchOffers().then(data => { 
            if(data.status==="success"){
                this.setState({changedRows:[]},
                ()=>{ 
                    this.filterOffers(data.data)
                }) 
            } 
        }) 
    }
    filterOffers = (offers) => { 
        let filterterdOffers=[];
        const {role}=this.state;  
        filterterdOffers = offers.filter(obj => {
            return obj.resolutionsStatus == 2
        })  
        this.setState({filterterdOffers})
    }

    arr = [];
    arrIndex= {};
    addOrUpdate = (object)=> {
        var index = this.arrIndex[object.id];
        if(index === undefined) {
            index = this.arr.length;
        }
        this.arrIndex[object.id] = index;
        this.arr[index] = object; 
        
        this.setState({
            changedRows:this.arr
        })
        
    } 

    searchCorrectObject = (id) =>{
        const {changedRows}=this.state;   
        return changedRows.find(el => el.id == id) 
    }

    setResolution = (id, allow, tableMeta, permissionId, permissionName) =>{      
        const {resolutionAllowPrice=0}=this.searchCorrectObject(id) ||{}
        let data={}
        data={
            ...this.searchCorrectObject(id),
            'id':id,  
            permissionId,
            permissionName,
            'resolutionStatus':allow,  
        }
        if (allow==1) {
            data={
                ...data,
                'resolutionAllowPrice':Boolean(resolutionAllowPrice)?resolutionAllowPrice: tableMeta.rowData[7], 
            }
        }else if(allow==0){
            data={
                ...data,
                'resolutionAllowPrice':0, 
            }
        }
        
        this.addOrUpdate(data) 
    } 

    onChange=(event,id, permissionId, permissionName)=>{
        const {name,value} = event.target; 
        let data={
            ...this.searchCorrectObject(id),
            'id':id, 
            permissionId,
            permissionName,
            [name]:value,  
        }
        this.addOrUpdate(data) 
    }

    // onRowClick=(rowData , rowMeta, e) => {  
    //     this.props.history.push("/dashboard/offers/"+rowData[0]);
    // }

    setResolutionContent =(tableMeta, permId, name)=>{  
        let id = tableMeta.rowData[0];
        const {errors}=this.state;    
        return(
            
            <div className="offer-allow-btn"> 
                <div style={{'display':'flex'}} >
                    <div className={"btn small mr-10 "+ (this.searchCorrectObject(id)!==undefined?(this.searchCorrectObject(id).resolutionStatus==1 ? 'btn-success':''):'')}  
                        onClick={()=>this.setResolution(id, 1, tableMeta, permId, name)}>
                        одобрявам
                    </div>
                    <div className={"btn small "+ (this.searchCorrectObject(id)!==undefined?(this.searchCorrectObject(id).resolutionStatus==0 ? 'btn-danger':''):'')}  
                        onClick={()=>this.setResolution(id, 0, tableMeta, permId, name)}>
                        неодобрявам
                    </div> 
                </div>
                {this.searchCorrectObject(id)!==undefined&&<div> 
                    { this.searchCorrectObject(id).resolutionStatus==1&&
                     <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small"  
                        required  
                        margin="dense" 
                        variant="outlined"   
                        name="resolutionAllowPrice"  
                        value={this.searchCorrectObject(id).resolutionAllowPrice} 
                        onChange={(event)=>this.onChange(event, id, permId, name)}  
                    /> 
                    }
                </div>}
               
            </div>
        )
    }

    setComments =(tableMeta, permId, name)=>{
        let id = tableMeta.rowData[0];
        const {errors}=this.state;  
        
        return(
            <div style={{'width':'200px'}}> 
                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small-text-area"  
                    required  
                    margin="dense" 
                    variant="outlined"   
                    name="resolutionNote" 
                    rows='3'
                    multiline
                    // value={this.searchCorrectObject(id).commentCeo} 
                
                    onChange={(event)=>this.onChange(event, id, permId, name)}   
                /> 
            </div> 
        )
    }

    searchCorrectObjectOffer = (id) =>{ 
        const {offers} = this.props;
        if(offers){ 
            return offers.find(el => el.id == id)
        } 
    }

    setColums =()=> {  
        const {offers} = this.props
      
        const columns =  [ 
            {
                label: "ID:",
                name: "id",
                options: {
                    filter: false,
                    sort: true, 
                    // sortDirection: 'desc'
                   }
            },  
            {
                label: "Име на клиент:",
                name: "contact",
                options: {
                    filter: false,
                    sort: true,
                   }
            },
            {
                label: "Марка:",
                name: "brand",
                options: {
                    filter: false,
                    sort: true,
                   }
            },  
            {
                label: "Модел:",
                name: "model",
                options: {
                    filter: false,
                    sort: true,
                   }
            },  
            {
                label: "Дата:",
                name: "createdDate",
                options: {
                    filter: false,
                    sort: true,
                }
            },     
            {
                label: "Име на търговец:",
                name: "user",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                label: "Бележка на търговеца:",
                name: "note",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                label: "Цена:",
                name: "priceAfterDiscount",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                label: "Действия:",
                name: "resolutions",
                options: {
                    filter: true,
                    sort: false, 
                    customBodyRender: (value, tableMeta, updateValue) => { 
                        const {userPermisions} = this.state;  
                        let counter=0;  
 

                        return value.map((item, i)=>{ 
                            let permission = userPermisions[item.permissionName]  
                            let name = item.permissionName
                            let permId = item.permissionId
                            let status = item.resolutionStatus 
                            if (Boolean(permission)) {    
                                
                                if (status=='2') {  
                                    counter++  
                                    if (counter<2) {  
                                        return <div key={i}>{ <>
                                        <p className="info-text"><b>Моята резолюция:</b></p> 
                                        { this.setResolutionContent(tableMeta, permId, name )}
                                        </>}</div>    
                                    } 
                                }else if (status=='1') { 
                                    return <div key={i}>{<p className="info-text">одобрена oт <b>{permNames[name]}</b></p>}</div>
                                }else if (status=='0') {
                                    return <div key={i}>{<p className="info-text">отказана oт <b>{permNames[name]}</b></p> }</div>
                                } 
                        
                            }else{ 
                                return <div key={i}>{<p className="info-text">очаква одобрение oт <b>{permNames[name]}</b></p>}</div>
                            } 

                         
                        }) 
                    }
                }
            }, 
            {
                label: "Коментар:",
                name: "resolutions",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => { 
                        const {numberOfRoles, userPermisions} = this.state;  
                        let counter=0;
                        return value.map((item, i)=>{ 
                            let permission = userPermisions[item.permissionName]  
                            let name = item.permissionName
                            let permId = item.permissionId
                            let status = item.resolutionStatus
                        
                            if (Boolean(permission)) {  
                                if (status=='2') {  
                                    counter++  
                                    if (counter<2) {  
                                        return <div key={i}>{ <>
                                        <p className="info-text"><b>Моят коментар:</b></p> 
                                        { this.setComments(tableMeta, permId, name ) }
                                        </>}</div> 
                                    }  
                                }else { 
                                    return <div key={i}className="info-text">
                                    <p>коментар на: <b>{permNames[name]}:</b></p>
                                    <i>{item.resolutionNote ? '"'+ item.resolutionNote+'"':'няма коментар'}</i>
                                    </div>
                                }
                        
                            }else{ 
                                return <div key={i} className="info-text">
                                <p>коментар на: <b>{permNames[name]}:</b></p>
                                <i>{item.resolutionNote ? '"'+ item.resolutionNote+'"':'"няма коментар"'}</i>
                                </div>
                            } 
                        }) 
                    },
                   
                }
            },
            {
                label: "",
                name: "",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {  
                        let id = tableMeta.rowData[0];
                        return <Link to={'/dashboard/offers/'+id} className='small btn btn-info'><i className='flaticon-edit mr-0'></i></Link>
                        
                    }
                }
            }
        ]

        const adminColums = [ 
            {
                label: "Цена по ценова листа:",
                name: "listPrice",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                label: "Промоционална Цена:",
                name: "promotionPrice",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                label: "Доставна цена с ДДС:",
                name: "deliveryPriceWithVat",
                options: {
                    filter: true,
                    sort: true,
                }
            },
        ]
        const  insertAt = (array, index, ...elementsArray) => {
            if (this.state.role=="1") {
                array.splice(index, 0, ...elementsArray);
            } 
        }
        insertAt(columns, 7, ...adminColums)
        return columns
    }

    submit = ()=>{
        const {changedRows, filterterdOffers} = this.state
        const {userPermisions}=this.state; 

        let fromState=[]  
        let data = changedRows.map(item=>{ 
            fromState = filterterdOffers.filter(el => el.id==item.id) 
            fromState = fromState[0].resolutions.filter(f=>{
                return f.permissionId!==item.permissionId
            })  
            fromState = fromState.map(b=>{ 
                if (userPermisions[b.permissionName]) {
                    return {
                        permissionId:b.permissionId,
                        permissionName: b.permissionName,
                        resolutionStatus: item.resolutionStatus,
                        resolutionNote: b.resolutionNote,
                        resolutionAllowPrice:item.resolutionAllowPrice
                    }
                } 
            })
  
            return (
                {id:item.id,
                    resolutions:[
                        {
                            permissionId:item.permissionId,
                            permissionName: item.permissionName,
                            resolutionStatus: item.resolutionStatus,
                            resolutionNote: item.resolutionNote,
                            resolutionAllowPrice:item.resolutionAllowPrice
                        },
                        ...fromState
                    ]
                }
           )
        })
        
        data = {ids:[...data]}   
        this.props.setMultipleResolutions(data).then(data => { 
            if(data.status==="success"){
                window.location.reload();
            }
        }) 
    }



    render() {    

        
       

        const {options, columns, changedRows} = this.state;    
        return ( 
            <React.Fragment>    
                <Grid container className="mb-20">
                   
                    <Grid container item md={4} xs={12} alignItems="center" >  
                        <Link  
                            className="btn btn-secondary"
                            to="/dashboard/offers" 
                            ><i className="flaticon2-circular-arrow"/>
                            Назад
                        </Link>   
                    </Grid>
                    <Grid container item md={8} xs={12} justify="flex-end">    
                        <button onClick={this.submit} className="btn btn-focus" href="/dashboard/offersForApproval">
                            <i className="fas fa-clipboard-check"></i>запиши</button>
                    </Grid> 
                </Grid>  
                <Card>  
                    <MUIDataTable
                        title={"Оферти за одобрение:"}
                        data={this.state.filterterdOffers}
                        columns={getStoredColumnsFilters('offersTable', this.setColums())} 
                        options={{...options, onRowClick: this.onRowClick}}  
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
const mapStateToProps = state => {   
    return({offers:state.offers.items})
}
 
 export default withRouter(connect(mapStateToProps, { fetchOffers, setMultipleResolutions })(OffersForApproval));
