import { apiUrl} from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions"; 

export const getAcceptanceAct = (id) => dispatch => { 
    return fetch(apiUrl+'acceptance-act/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {       
        return response
    }); 
}

export const createAcceptance = (contract) => dispatch => {  
    
    return fetch(apiUrl+'acceptance-act', { 
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(contract)
     }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
     })
    .then(response => {    
        const {status, data, message} = response; 
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage()); 
        } 
        return response
    }); 
 
}

export const uploadAcceptanceAct = (id,val) => dispatch => {    
    let formData = new FormData(); 
    formData.append('file', val );  

    return fetch(apiUrl+'acceptance-act/upload-order/'+id, {
        method: 'POST', 
        headers:{  
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: formData
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        const {status, data, message} = response; 
        if (status === "success") { 
            dispatch(setMessage(message));
            dispatch(removeMessage()); 
        } 
        return response
    });   
}

export const downloadAcceptanceAct = (id) => dispatch => {     

    return fetch(apiUrl+'download-document/acceptance-act/'+id, {
        method: 'GET', 
        headers:{  
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        }, 
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        return response
    });   
}