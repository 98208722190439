export const permissions = 
    {"users-show":{"value":false,"id":1},
    "users-store":{"value":false,"id":2},
    "users-update":{"value":false,"id":3},
    "users-delete":{"value":false,"id":4},
    "cars-show":{"value":false,"id":5},
    "cars-store":{"value":false,"id":6},
    "cars-update":{"value":false,"id":7},
    "cars-delete":{"value":false,"id":8},
    "clients-show":{"value":false,"id":9},
    "clients-store":{"value":false,"id":10},
    "clients-update":{"value":false,"id":11},
    "clients-delete":{"value":false,"id":12},
    "test-drives-show":{"value":false,"id":13},
    "test-drives-store":{"value":false,"id":14},
    "test-drives-update":{"value":false,"id":15},
    "test-drives-delete":{"value":false,"id":16},
    "log-activity-show":{"value":false,"id":17},
    "contacts-show":{"value":false,"id":18},
    "contacts-store":{"value":false,"id":19},
    "contacts-update":{"value":false,"id":20},
    "contacts-delete":{"value":false,"id":21},
    "companies-show":{"value":false,"id":22},
    "companies-store":{"value":false,"id":23},
    "companies-update":{"value":false,"id":24},
    "companies-delete":{"value":false,"id":25},
    "brands-show":{"value":false,"id":26},
    "brands-store":{"value":false,"id":27},
    "brands-update":{"value":false,"id":28},
    "brands-delete":{"value":false,"id":29},
    "models-show":{"value":false,"id":30},
    "models-store":{"value":false,"id":31},
    "models-update":{"value":false,"id":32},
    "models-delete":{"value":false,"id":33},
    "sales-cars-show":{"value":false,"id":34},
    "sales-cars-store":{"value":false,"id":35},
    "sales-cars-update":{"value":false,"id":36},
    "sales-cars-delete":{"value":false,"id":37},
    "sales-cars-sync":{"value":false,"id":38},
    "sales-cars-import":{"value":false,"id":39},
    "sales-cars-nisan-import":{"value":false,"id":40},
    "sales-cars-update-status":{"value":false,"id":41},
    "offers-show":{"value":false,"id":42},
    "offers-store":{"value":false,"id":43},
    "offers-update":{"value":false,"id":44},
    "offers-delete":{"value":false,"id":45},
    "contracts-show":{"value":false,"id":46},
    "contracts-store":{"value":false,"id":47},
    "contracts-update":{"value":false,"id":48},
    "contracts-delete":{"value":false,"id":49},
    "roles-show":{"value":false,"id":51},
    "roles-store":{"value":false,"id":52},
    "roles-update":{"value":false,"id":53},
    "roles-delete":{"value":false,"id":54},
    "permissions-show":{"value":false,"id":55},
    "sales-cars-documents-upload":{"value":false,"id":56},
    "sales-cars-documents-show":{"value":false,"id":57},
    "orders-show":{"value":false,"id":58},
    "orders-store":{"value":false,"id":59},
    "orders-update":{"value":false,"id":60},
    "orders-delete":{"value":false,"id":61},
    "acceptance-act-show":{"value":false,"id":62},
    "acceptance-act-store":{"value":false,"id":63},
    "acceptance-act-update":{"value":false,"id":64},
    "acceptance-act-delete":{"value":false,"id":65}, 
    "sales-manager-allow-offer":{"value":false, 'id': 67},
    "manager-allow-offer":{"value":false, 'id': 68},
    "dealer-manager-allow-offer":{"value":false, 'id': 69},
    "admin-allow-offer":{"value":false, 'id': 70}
}

