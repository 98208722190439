import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {connect} from 'react-redux'

import TextField from '@material-ui/core/TextField'; 
import offerImg from '../../../styles/offer-img.jpg';

 

class Acessories extends Component {
    state = { 
        accessories:{
            brand:[  {'name':'','price':'',isGift:false} ] , 
            model:[  {'name':'','price':'',isGift:false} ] 
        }, 
        equipments:[], 
        image: '',
        secondImage: '',
        accessoryImage:'',
        carImages:[],
        lightTruck:false,
     }
  
    componentWillUnmount(){ 
       const filteredBrands = this.state.accessories.brand.filter(item=>{
            return item.name!='' 
       })
       const filteredModel = this.state.accessories.model.filter(item=>{
            return item.name!='' 
       })  
     
        this.props.handleData('none', {
            lightTruck:this.state.lightTruck,
            image: this.state.image,
            secondImage: this.state.secondImage,
            equipments: this.state.equipments,
            accessories:{brand:filteredBrands,model:filteredModel}
        }) 
    }
    componentDidUpdate(previousProps, previousState){   

        // if(previousProps.priceAndAcessoaries !== this.props.priceAndAcessoaries){ 
        //     alert('da')
        //     const {accessories} = this.props.priceAndAcessoaries
        //     const{editMode}=this.props;  
        //     let brand=[
        //         ...accessories.brand,
        //         ...this.state.accessories.brand, 
        //     ]
        //     let model=[
        //         ...accessories.model,
        //         ...this.state.accessories.model, 
        //     ] 
        //     this.setState({
        //         accessories:{ 
        //             brand,
        //             model 
        //         },  
        //         carImages:this.props.priceAndAcessoaries.carImages,
        //         lightTruck:this.props.priceAndAcessoaries.lightTruck,
        //         accessoryImage:this.props.priceAndAcessoaries.accessoryImage,
 
        //     })  
        // }   
        if(previousProps.offer !== this.props.offer){  
            this.getPassedData()
        }   
    }  

    getPassedData = () =>{ 
        const{editMode, offer}=this.props;   
        if (offer.accessories) {    
            let brand = [];
            let model = [];
            if (offer.accessories.brand.length>0) {
                brand=[...offer.accessories.brand]
            }else{
                brand = [{'name':'','price':'',isGift:false}]
            } 
            if (offer.accessories.model.length>0) {
                model=[...offer.accessories.model]
            }else{
                model = [{'name':'','price':'',isGift:false}]
            }
            let image=1;
            if (offer.image) {
                image=offer.image
            }  
            this.setState({  
                accessories:{
                    brand:brand, 
                    model:model
                }, 
                lightTruck:offer.lightTruck, 
                equipments:offer.equipments, 
                accessoryImage:offer.accessoryImage,
                carImages:offer.carImages, 
                image,
                secondImage:offer.secondImage,
                brandId:offer.car.brandId
            })  
        }  
        // if (editMode) {
        //     if (offer.secondImage) {
        //         if (offer.secondImage.length>0) {
        //             this.setState({
        //                 lightTruck:true
        //             })
        //         }
        //     } 
        // }
       
    }

    handleAddBrand = ()=>{
        this.setState({ 
            accessories:{
                ...this.state.accessories, 
                brand:[
                    ...this.state.accessories.brand, 
                    {'name':'','price':'',isGift:false}
                ] 
            } 
        })
    }

    handleRemoveBrand = (i) => {
        const list = [...this.state.accessories.brand];
        list.splice(i, 1);
        this.setState({
            accessories:{
                ...this.state.accessories, 
                brand:list
            }  
        })
    }

    onChangeDinamicBrand = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.accessories.brand];
        list[index][name] = value; 
        this.setState({
            accessories:{
                ...this.state.accessories, 
                brand:list
            }  
        })
    };

    handleAddModel = ()=>{
        this.setState({ 
            accessories:{
                ...this.state.accessories, 
                model:[
                    ...this.state.accessories.model, 
                    {'name':'','price':'', isGift:false}
                ] 
            } 
        })
    }

    handleRemoveModel = (i) => {
        const list = [...this.state.accessories.model];
        list.splice(i, 1);
        this.setState({
            accessories:{
                ...this.state.accessories, 
                model:list
            }  
        })
    }

    onChangeDinamicModel = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.accessories.model];
        list[index][name] = value; 
        this.setState({
            accessories:{
                ...this.state.accessories, 
                model:list
            }  
        })
    };

    onChangeIsGift = (e, index, type) => {
        const { name, value, checked } = e.target;
        const list = [...this.state.accessories[type]];
        list[index][name] = checked; 
        this.setState({
            accessories:{
                ...this.state.accessories, 
                [type]:list
            }  
        })
    };

    onChangeEquipment = (e, index) => {
        const { name, value, checked } = e.target;
        const list = [...this.state.equipments];
        list[index][name] = checked; 
        this.setState({
            equipments:list  
        })
    };

    nextStep = ()=>{
        const{carId}=this.props.offer.car;
        
        this.props.nextStep(carId,4) 

        const filteredBrands = this.state.accessories.brand.filter(item=>{
            return item.name!='' 
        })
        const filteredModel = this.state.accessories.model.filter(item=>{
            return item.name!='' 
        }) 
        this.props.handleData('accessories', {brand:filteredBrands,model:filteredModel})  
    }
    
    changeImage = (name, value) =>{
        this.setState({
            [name]:value 
        })
    }

    handleCheckbox = (event) =>{
        const{name, checked } = event.target;   
        this.setState({
            [name]:checked 
        })  
        if (!checked) {
            this.setState({
                secondImage:'' 
            }, () => {
                this.props.handleData('none', {secondImage:'' });
            })  
        }
    }

    render() { 
        const {brand , model} =this.state.accessories;
        const {accessoryImage, carImages=[], image, secondImage,equipments, lightTruck, brandId} =this.state 
        const typesNames=['','Произв. опция','Собствена опция','Продукт','Аксесоар']
        const optionTypesNames=['Опция','Цвят','Тапицерия','Табло','Задължит. опция', 'Собствена опция']
 
        return ( 
            <>
            <Grid container spacing={3}>   
                <Grid item md={6} xs={12}> 
                    <h3 className="section-title">
                        Аксесоари за марката:
                    </h3>
                    <br/> 
                    {brand.map((item, i) =>{
                        return(  
                        <div className="aditional-accessory" key={i}>
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                                InputLabelProps={{shrink: true}}  
                                name="name"
                                margin="normal" 
                                variant="outlined"    
                                label="Име на аксесоара:"
                                value={item.name}
                                onChange={e=>this.onChangeDinamicBrand(e, i)}  
                            />  
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small number" 
                                InputLabelProps={{shrink: true}} 
                                label="Цена лв."
                                type="number"
                                margin="normal" 
                                variant="outlined"    
                                name="price"
                                value={item.price}
                                onChange={e=>this.onChangeDinamicBrand(e, i)}  
                            />  
                            <FormControlLabel style={{'margin':'10px 0 0 5px'}} control={ 
                                <Checkbox name='isGift'  
                                checked={item.isGift}
                                onChange={e=>this.onChangeIsGift(e, i, 'brand')}  
                                color="primary"/>}
                                label='Подарък'
                            />
                            <div className="btn-box" style={{'marginLeft':'20px'}}>
                                {brand.length !== 1 &&
                                <div className='delete' onClick={()=>this.handleRemoveBrand(i)}>
                                    <i className="flaticon2-delete"></i>  
                                </div>}
                                {brand.length - 1 === i && 
                                <div className='add' onClick={this.handleAddBrand}>
                                    +
                                </div>}
                            </div> 
                        </div>
                        )
                    })}
                    <br/> <br/> 
                    <h3 className="section-title">
                        Аксесоари за модела:
                    </h3>
                    <br/> 
                    {model.map((item, i) =>{
                        return(  
                        <div className="aditional-accessory" key={i}>
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small" 
                                InputLabelProps={{shrink: true}} 
                                required 
                                name="name"
                                margin="normal" 
                                variant="outlined"    
                                label="Име на аксесоара:"
                                value={item.name}
                                onChange={e=>this.onChangeDinamicModel(e, i)}  
                            />  
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width small number" 
                                InputLabelProps={{shrink: true}} 
                                label="Цена лв."
                                type="number"
                                margin="normal" 
                                variant="outlined"    
                                name="price"
                                value={item.price}
                                onChange={e=>this.onChangeDinamicModel(e, i)}  
                            />  
                            <FormControlLabel style={{'margin':'10px 0 0 5px'}} control={ 
                                <Checkbox name='isGift'  
                                    checked={item.isGift}
                                    onChange={e=>this.onChangeIsGift(e, i, 'model')}  
                                    color="primary"/>
                                }
                                label='Подарък'
                            />
                            <div className="btn-box" style={{'marginLeft':'20px'}}>
                                {model.length !== 1 &&
                                <div className='delete' onClick={()=>this.handleRemoveModel(i)}>
                                    <i className="flaticon2-delete"></i>  
                                </div>}
                                {model.length - 1 === i && 
                                <div className='add' onClick={this.handleAddModel}>
                                    +
                                </div>}
                            </div> 
                        </div>
                        )
                    })}
                   
                    {Boolean(brandId===1)&&<><br/> <br/> 
                        <h3 className="section-title">
                            Оборудване:
                        </h3>
                        <br/>
                    <div className="equipment-wrapper">
                        <table className='custom-table equipment'>
                            <thead>
                                <tr>
                                    <th>Имe:</th>
                                    <th>Tип:</th>
                                    <th>Option Type:</th>
                                    <th>Цена:</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>  
                                {equipments.map((item,i)=>{  
                                
                                    return(
                                        <tr key={i}>
                                            <td style={{'textAlign':'left'}}>{item.name}</td>
                                            <td>{typesNames[Number(item.type)-1]}</td>
                                            <td>{optionTypesNames[Number(item.optionType)-1]}</td> 
                                            <td>{item.price}</td>
                                            <td>
                                                <FormControlLabel style={{'margin':'10px 0 0 5px'}} control={ 
                                                    <Checkbox name='checked'  
                                                        disabled={Boolean(item.disabled) || typesNames[Number(item.type)-1]=='Собствена опция'}
                                                        checked={Boolean(item.checked) || typesNames[Number(item.type)-1]=='Собствена опция'}
                                                        onChange={e=>this.onChangeEquipment(e, i)}  
                                                        color="primary"/>
                                                    } 
                                                />          
                                            </td>
                                        </tr>
                                    )
                                })}    
                                {equipments.length===0&&<tr>
                                    <td colspan="5">Няма Оборудване</td>
                                </tr>}
                        </tbody> 
                        </table>
                   </div>
                    </>}
                </Grid>   
                <Grid item md={1} xs={12}> 
                </Grid>   
                <br/><br/>
                <Grid item md={5} xs={12}>   
                    <h3 className="section-title">
                        Снимка на модела:
                    </h3>
                    <br/>
                    <div className="image-section"> 
                        {carImages.map( img => {
                            return (
                                <div onClick = {()=>this.changeImage('image', img.id)}
                                className="image-wrapper one-third poiner" key={img.id}> 
                                    {image==img.id&&<div className="picked">
                                        <i className="far fa-check-circle"></i>
                                        <p>Избрана Снимка</p> 
                                    </div>}
                                    <img className='full-width' src={img.path} alt=""/>
                                </div> 
                            )
                        })}  
                    </div> 
                    {carImages.length<1&&<h3>Няма снимка за показване</h3>} 
                
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={Boolean(lightTruck)}
                            disabled={true}
                            onChange={this.handleCheckbox}
                            name="lightTruck"
                            color="primary"
                        />
                        }
                        label="Превозното средство е лекотоварен автомобил"
                    />  
                    <br/><br/>
                    {Boolean(lightTruck)&&<><h3 className="section-title">
                        Техн. спецификация:
                    </h3>
                    <br/>
                    <div className="image-section"> 
                        {carImages.map( img => {
                            return (
                                <div onClick = {()=>this.changeImage('secondImage', img.id)}className="image-wrapper one-third poiner" key={img.id}> 
                                    {secondImage==img.id&&<div className="picked">
                                        <i className="far fa-check-circle"></i>
                                        <p>Избрана Снимка</p> 
                                    </div>}
                                    <img className='full-width' src={img.path} alt=""/>
                                </div> 
                            )
                        })}  
                    </div> 
                    {carImages.length==0&&<h3>Няма снимка за показване</h3>} 
                    </>}
                    <br/> <br/> 
                    <h3 className="section-title">
                        Снимка на аксесоарите:
                    </h3>
                    <br/> 
                    <img className='full-width' src={accessoryImage} alt=""/>
                    {!accessoryImage&&<h3>Няма снимка за показване</h3>} 
                </Grid>    
            </Grid>   
            <br/><br/>
            <Grid container spacing={2} >   
                <Grid item md={4} xs={12} >     
                    <div className="btn btn-info full-width" onClick={()=>this.nextStep()}>
                        <i className="flaticon2-add-square"></i>Напред
                    </div> 
                </Grid> 
            </Grid> 
            </>
         );
    }
}
 
 

export default connect('', { })(Acessories);  
 