import React, { Component } from 'react';
import {connect} from 'react-redux' 

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import FormControlLabel from '@material-ui/core/FormControlLabel';
 

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";  

import Autocomplete from '@material-ui/lab/Autocomplete';

import {fetchSingleSaleCars} from '../../../_actions/carsSaleActions'
import {updateOrder} from '../../../_actions/ordersActions'


class EditCar extends Component {
    
    state={
        
        data:{ 
            id:'',
            brand:'',
            model:'', 
            externalColor:'',
            fuelType:'',
            version:'',
            seats:'',
            engineType:'',
            engineNumber:'',
            volume:'',
            enginePower:'',
            inventoryNumber:''
        },   
    }

    componentDidMount(){  
        if (this.props.singleOrder.carVin) {
            this.props.fetchSingleSaleCars(this.props.singleOrder.carId);  
        } 
    }

   
    onChange = (event) =>{
        const {name,value} = event.target;   
        this.props.updateOrder('none', {
            [name]:value,
        }) 
    } 

    render() { 
        const {saleCars, singleOrder} = this.props;  
        const {id, brand, enginePower,engineNumber, engineType, fuelType, seats, volume, model ,version, externalColor, inventoryNumber, prefix}= singleOrder;
 
        const {errors={}} = this.props;
      
        const {carVin}= singleOrder;  
        return (   
            <Grid container spacing={3}> 
                <Grid item md={4} xs={12}>
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        disabled
                        required 
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-rocket"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Шаси №"
                        name="brand"
                        value={carVin}
                        error ={errors.carVin?true:false} 
                        helperText={errors.carVin} 
                        onChange={this.onChange}  
                    />  
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        disabled
                        required 
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-rocket"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Марка"
                        name="brand"
                        value={brand}
                        error ={errors.brand?true:false} 
                        helperText={errors.brand} 
                        onChange={this.onChange}  
                    />  
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-start-up"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Модел"
                        name="model"  
                        value={model}
                        error ={errors.model?true:false} 
                        helperText={errors.model} 
                        onChange={this.onChange} 
                    />  
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-start-up"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Версия"
                        name="version"  
                        value={version}
                        error ={errors.version?true:false} 
                        helperText={errors.version} 
                        onChange={this.onChange} 
                    />  
                        
                    {brand==="Nissan"&& <FormControl variant="outlined" error={errors.prefix?true:false} margin="normal" className='mb-20 full-width select-styles'>
                        <InputLabel id="prefix">
                            Префикс
                        </InputLabel>
                        <Select  
                            MenuProps={{
                                anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right"
                                },
                                transformOrigin: {
                                vertical: "top",
                                horizontal: "right"
                                },
                                getContentAnchorEl: null
                            }} 
                            labelId="prefix"
                            required   
                            inputProps={{
                                name: 'prefix', 
                            }}  
                            value={prefix} 
                            onChange={this.onChange}
                            labelWidth={70}  >    
                            <MenuItem value='HR13'>HR13</MenuItem>  
                            <MenuItem value='R9NA401'>R9NA401</MenuItem>  
                            <MenuItem value='K9KU873'>K9KU873</MenuItem>  
                            <MenuItem value='R9NA401'>R9NA401</MenuItem>  
                            <MenuItem value='HRA0'>HRA0</MenuItem>  
                            <MenuItem value='H4DB450'>H4DB450</MenuItem>  
                            <MenuItem value='YS23F282'>YS23F282</MenuItem>  
                            <MenuItem value='YS23E280'>YS23E280</MenuItem>  
                            <MenuItem value='EM57'>EM57</MenuItem>  
                            <MenuItem value='VR38'>VR38</MenuItem>  
                        </Select>
                        {errors.prefix&&<FormHelperText>{errors.prefix}</FormHelperText>}
                    </FormControl>   
                    }
                </Grid>   
                <Grid item md={4} xs={12}>
                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-group"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Брой места"
                        name="seats"   
                        value={seats}
                        error ={errors.seats?true:false} 
                        helperText={errors.seats} 
                        onChange={this.onChange} 
                    />  
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                        <i className="flaticon-notepad"></i>  
                            </InputAdornment>
                            ),
                        }}
                        label="Цвят"
                        name="externalColor"   
                        value={externalColor}
                        error ={errors.externalColor?true:false} 
                        helperText={errors.externalColor} 
                        onChange={this.onChange} 
                    />  
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-rocket"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Двигател"
                        name="engineType"   
                        value={engineType}
                        error ={errors.engineType?true:false} 
                        helperText={errors.engineType} 
                        onChange={this.onChange} 
                    /> 
                    
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-list-3"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Двигател №"
                        name="engineNumber"   
                        value={engineNumber}
                        error ={errors.engineNumber?true:false} 
                        helperText={errors.engineNumber} 
                        onChange={this.onChange} 
                    /> 
                    
                </Grid>   
                <Grid item md={4} xs={12}> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        // disabled
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="ИНВ. №:"
                        name="inventoryNumber"   
                        value={inventoryNumber}
                        error ={errors.inventoryNumber?true:false} 
                        helperText={errors.inventoryNumber} 
                        onChange={this.onChange} 
                    />      
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Гориво"
                        name="fuelType"   
                        value={fuelType}
                        error ={errors.fuelType?true:false} 
                        helperText={errors.fuelType} 
                        onChange={this.onChange} 
                    /> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-notepad"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Обем (Л.)"
                        name="volume"   
                        value={volume}
                        error ={errors.volume?true:false} 
                        helperText={errors.volume} 
                        onChange={this.onChange} 
                    /> 
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        disabled
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon-dashboard"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Мощност (KW/к.с.) :"
                        name="enginePower"   
                        value={enginePower}
                        error ={errors.enginePower?true:false} 
                        helperText={errors.enginePower} 
                        onChange={this.onChange} 
                    />  
                </Grid> 
            </Grid> 
            
         );
    }
}
const mapStateToProps = (state, props) => {     
    return{
        singleSaleCar:state.carsSale.singleSaleCar,
        companies:state.companies.list, 
        singleOrder:state.orders.singleOrder
    }
}

export default connect(mapStateToProps, {fetchSingleSaleCars, updateOrder })(EditCar);  
 
 