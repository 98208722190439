import { apiUrl } from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions";

// import {testData} from '../helpers/testJson';
import { saleCarsObjNames, saleCarsObjNamesForOffer } from "../helpers/formsPermissions";
export const setFilteredSaleCars = (data) => (dispatch) => {
    dispatch({
        type: "FETCH_SALE_CARS",
        payload: data,
    });
};
export const setFilteredSaleCarsForOffer = (data) => (dispatch) => {
    dispatch({
        type: "FETCH_SALE_CARS_FOR_OFFER",
        payload: data,
    });
};

export const fetchSaleCars = (type) => (dispatch) => {
    let apiURL = "";
    if (type) {
        apiURL = apiUrl + "sales-cars?type=" + type;
    } else {
        apiURL = apiUrl + "sales-cars";
    }
    return fetch(apiURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, data } = response;
            if (status === "success") {
                const carsSaleExtract = data.map((i) => {
                    return saleCarsObjNames(i);
                });
                const compare = (a, b) => {
                    if (a.status === 4) {
                        return -1;
                    }
                    if (a.status !== 4) {
                        return 1;
                    }
                    return 0;
                };
                const sorted = carsSaleExtract.sort(compare);
                dispatch({
                    type: "FETCH_SALE_CARS",
                    payload: sorted,
                });
                dispatch({
                    type: "FETCH_SALE_CARS_ORIGINAL",
                    payload: sorted,
                });
            } else {
                dispatch({
                    type: "FETCH_SALE_CARS",
                    payload: [],
                });
            }
            return response;
        });
};

export const fetchSaleCarsByStatus = (type) => (dispatch) => {
    let apiURL = "";
    if (type) {
        apiURL = apiUrl + "sales-cars?status=" + type;
    } else {
        apiURL = apiUrl + "sales-cars";
    }
    return fetch(apiURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, data } = response;
            if (status === "success") {
                const carsSaleExtract = data.map((i) => {
                    return saleCarsObjNames(i);
                });
                const compare = (a, b) => {
                    if (a.status === 4) {
                        return -1;
                    }
                    if (a.status !== 4) {
                        return 1;
                    }
                    return 0;
                };
                const sorted = carsSaleExtract.sort(compare);
                dispatch({
                    type: "FETCH_SALE_CARS",
                    payload: sorted,
                });
                dispatch({
                    type: "FETCH_SALE_CARS_ORIGINAL",
                    payload: sorted,
                });
            } else {
                dispatch({
                    type: "FETCH_SALE_CARS",
                    payload: [],
                });
            }
            return response;
        });
};

export const fetchSingleSaleCars = (id) => (dispatch) => {
    return fetch(apiUrl + "sales-cars/" + id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, data } = response;
            if (status === "success") {
                dispatch({
                    type: "FETCH_SINGLE_SALE_CARS",
                    payload: data,
                });
            }
            return response;
        });
};
export const fetchSingleSaleCarsDocuments = (id) => (dispatch) => {
    return fetch(apiUrl + "sales-cars/documents/" + id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, data } = response;
            if (status === "success") {
                dispatch({
                    type: "FETCH_SINGLE_SALE_CARS_DOCUMENTS",
                    payload: data,
                });
            }
            return response;
        });
};

export const editSingleSaleCars = (data) => (dispatch) => {
    const { id } = data.vehicle;
    return fetch(apiUrl + "sales-cars/" + id, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(data),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            return response;
        });
};
export const updateSingleSaleCarsState = (obj, data) => (dispatch) => {
    dispatch({
        type: "EDIT_SINGLE_SALE_CARS",
        payload: { obj, data },
    });
};

export const fetchSaleCarsAutoComplete = (data) => (dispatch) => {
    return fetch(apiUrl + "sales-cars/autocomplete?q=" + data, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, data } = response;
            if (status === "success") {
                dispatch({
                    type: "FETCH_SALE_CARS_AUTOCOMPLETE",
                    payload: data,
                });
            }
            return response;
        });
};

export const fetchSaleCarsForOffer = () => (dispatch) => {
    return fetch(apiUrl + "sales-cars/autocomplete", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, data } = response;
            if (status === "success") {
                const carsSaleExtract = data.map((i) => {
                    return saleCarsObjNamesForOffer(i);
                });
                const compare = (a, b) => {
                    if (a.status === 4) {
                        return -1;
                    }
                    if (a.status !== 4) {
                        return 1;
                    }
                    return 0;
                };
                const sorted = carsSaleExtract.sort(compare);
                dispatch({
                    type: "FETCH_SALE_CARS_FOR_OFFER",
                    payload: sorted,
                });
                dispatch({
                    type: "FETCH_SALE_CARS_FOR_OFFER_ORIGINAL",
                    payload: sorted,
                });
            }
            return response;
        });
};

export const setStatusSaleCar = (id, data) => (dispatch) => {
    return fetch(apiUrl + "sales-cars/update-status/" + id, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify({ status: data }),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, data, message, errors } = response;
            if (status === "success") {
                dispatch(setMessage(message));
                dispatch(removeMessage());
                dispatch({
                    type: "FETCH_SALE_CARS_AUTOCOMPLETE",
                    payload: data,
                });
            } else {
                dispatch(setMessage(errors.status));
                dispatch(removeMessage());
            }

            return response;
        });
};
export const bookCar = (id, data) => (dispatch) => {
    return fetch(apiUrl + "sales-cars/book/" + id, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(data),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, message, errors } = response;
            if (status === "success") {
                dispatch(setMessage(message));
                dispatch(removeMessage());
            } else {
                dispatch(setMessage(errors.status));
                dispatch(removeMessage());
            }
            return response;
        });
};

export const importSalesCars = (id, val) => (dispatch) => {
    let formData = new FormData();
    formData.append("file", val);
    formData.append("companyId", id);

    return fetch(apiUrl + "sales-cars/import", {
        method: "POST",
        headers: {
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: formData,
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, message } = response;
            if (status === "success") {
                dispatch(setMessage(message));
                dispatch(removeMessage());
            }
            return response;
        });
};
export const sendFileNav = (val) => (dispatch) => {
    let formData = new FormData();
    formData.append("file", val);

    return fetch(apiUrl + "sales-cars/import-from-nav", {
        method: "POST",
        headers: {
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: formData,
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, message } = response;
            if (status === "success") {
                dispatch(setMessage(message));
                dispatch(removeMessage());
            }
            return response;
        });
};

export const uploadDocuments = (type, files, carId) => (dispatch) => {
    let formData = new FormData();
    for (const file of files) {
        formData.append("files[]", file);
    }

    // // formData.append('file', val );
    formData.append("type", type);

    return fetch(apiUrl + "files/sales-cars/" + carId, {
        method: "POST",
        headers: {
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: formData,
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, message } = response;
            if (status === "success") {
                dispatch(setMessage(message));
                dispatch(removeMessage());
            }
            return response;
        });
};

export const importSalesCarsNisssan = (type, val) => (dispatch) => {
    let formData = new FormData();
    formData.append("file", val);
    formData.append("type", type);

    return fetch(apiUrl + "sales-cars-nisan/import", {
        method: "POST",
        headers: {
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: formData,
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            if (result.status !== "500") {
                return result.json();
            }
        })
        .then((response) => {
            const { status, message } = response;
            if (status === "success") {
                dispatch(setMessage(message));
                dispatch(removeMessage());
            }
            return response;
        });
};
export const suncCSV = () => (dispatch) => {
    return fetch(apiUrl + "sales-cars/sync?s=1", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, message } = response;
            if (status === "success") {
                dispatch(setMessage(message));
                dispatch(removeMessage());
            }
            return response;
        });
};
